import React, { useEffect, useState } from "react";
import Select from "react-select";

const EtablishmentComponent = (props) => {
  const { name, setEtablishment, label, data, width } = props;
  const [establishmentData, setEstablishmentData] = useState([]);

  useEffect(() => {
    let establishmentList = [];
    data.map((establishment) =>
      establishmentList.push({
        value: establishment.id,
        label: establishment.name,
      })
    );
    setEstablishmentData(establishmentList);
    // eslint-disable-next-line
  }, []);

  const handleValueChange = (selected) => {
    setEtablishment(selected.value);
  };

  return (
    <div style={{ width: width ? width + "px" : "" }}>
      <p>
        {label}
        <Select
          placeholder={"Choix de l'établissement"}
          name={name}
          onChange={handleValueChange}
          options={establishmentData}
        />
      </p>
    </div>
  );
};

export default EtablishmentComponent;
