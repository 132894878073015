import React from "react";
import PatientDetailsTable from "../../components/PatientDetailsTableComponent";
import ResultNetworkImage from "../../components/ResultNetworkImage";
import ResultTestDetailsComponent from "../../components/ResultTestDetailsComponent";
import CassetteDetailsComponent from "../../components/CassetteDetailsComponent";
import LayoutPageComponent from "../../components/LayoutPageComponent";
import qrCodeImage from "../../assets/barcode.png";
import QRCode from "qrcode";
import { pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import PatientQrPDF from "../../pages/patients/patient_qr_pdf";

class PatientWithResult extends React.Component {
  constructor(props) {
    super(props);

    this.patientString = this.props.history.location["state"]
      ? this.props.history.location["state"]
      : null;

    this.patient =
      this.patientString !== null ? JSON.parse(this.patientString) : "";

    try {
      this.patient.tube.cassette["tube"] = this.patient.tube;
      this.patient.tube.cassette.result["cassette"] =
        this.patient.tube.cassette;
    } catch (e) {
      console.log(e);
    }

    this.state = {
      isFetching: false,
      patient: this.patient,
    };
  }

  handleDownloadPdf = async (pdfDocument, pdfDocumentName) => {
    const doc = pdfDocument;
    const asPdf = pdf();
    asPdf.updateContainer(doc);
    const blob = await asPdf.toBlob();
    saveAs(blob, `${pdfDocumentName}.pdf`);
  };

  downloadQR = () => {
    const canvas = document.getElementById(this.state.patient.id);
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = `${this.state.patient.id}.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  render() {
    var qrData = [{ data: this.state.patient.id, mode: "byte" }];

    return (
      <LayoutPageComponent>
        <div
          style={{
            display: "flex",
            // justifyContent: "space-between",
            marginTop: "2rem",
          }}
        >
          <p className="title">ID Patient : {this.state.patient.id}</p>

          <img
            src={qrCodeImage}
            alt="QR code"
            style={{ width: "6rem", marginLeft: "2rem", cursor: "pointer" }}
            onClick={() => {
              const qrUrl = QRCode.toDataURL(
                qrData,

                {
                  type: "png",
                }
              );

              return this.handleDownloadPdf(
                <PatientQrPDF patient={this.state.patient} qr={qrUrl} />,
                `${this.state.patient.lastName}_${this.state.patient.id}`,
                qrUrl
              );
            }}
          />
        </div>

        <p>{this.state.msgPatient}</p>

        <PatientDetailsTable data={this.state.patient} />

        {this.state.patient?.tube?.cassette && (
          <div>
            <p className="table-title">Cassette :</p>
            <CassetteDetailsComponent
              data={this.state.patient?.tube?.cassette}
            />
          </div>
        )}
        {!this.state.patient?.tube?.cassette && (
          <div>
            <p>Pas de cassette associée</p>
          </div>
        )}

        {this.state.patient?.tube?.cassette?.result && (
          <div>
            <p className="table-title">Résultat :</p>
            <ResultTestDetailsComponent
              data={this.state.patient?.tube?.cassette?.result}
            />

            <p className="table-title">Photo :</p>
            <ResultNetworkImage
              resultId={this.state.patient?.tube?.cassette?.result?.id}
            />
          </div>
        )}

        {!this.state.patient?.tube?.cassette?.result && (
          <div>
            <p>Pas de résultat associé</p>
          </div>
        )}
      </LayoutPageComponent>
    );
  }
}

export default PatientWithResult;
