import React, { useState } from "react";
import EmailComponent from "../../components/EmailComponent";
import * as establishmentApi from "../../api/Etablishment";
import LayoutPageComponent from "../../components/LayoutPageComponent";

const UpdateSignatory = (props) => {
  const signatoryString = props.history.location["state"]
    ? props.history.location["state"]
    : null;
  const signatoryObject = signatoryString ? JSON.parse(signatoryString) : null;
  const signatory = signatoryObject?.signatory;
  const establishmentId = signatoryObject?.establishmentId;

  const [selectedFile, setSelectedFile] = useState();
  const [isSelected, setIsSelected] = useState(false);
  const [title, setTitle] = useState(signatory?.title || "");
  const [firstname, setFirstname] = useState(signatory?.firstName || "");
  const [lastname, setLastname] = useState(signatory?.lastName || "");
  const [email, setEmail] = useState(signatory?.email || "");
  const [isValid, setIsValid] = useState(true); // for email
  const [message, setMessage] = useState("");
  const [signatoryStatus, setSignatoryStatus] = useState(signatory.isActive);

  const changeHandler = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size > 1024000) alert("File exeed limit : 1MB");
      else {
        setSelectedFile(event.target.files[0]);
        setIsSelected(true);
      }
    }
  };

  // eslint-disable-next-line
  const reset = () => {
    setSelectedFile(null);
    setIsSelected(false);
    setTitle("");
    setFirstname("");
    setLastname("");
    setEmail("");
    setIsValid(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!isValid) return;

    const formData = new FormData();

    if (selectedFile) formData.append("signature", selectedFile);
    formData.append("lastName", lastname);
    formData.append("firstName", firstname);
    formData.append("title", title);
    formData.append("establishment", establishmentId);
    formData.append("email", email);
    formData.append("isActive", signatoryStatus);

    establishmentApi
      .updateSignatory(signatory?.id, formData)
      .then((res) => {
        res.json();
      })
      .then((json) => {
        setMessage(`Signatory ${firstname} ${lastname} updated`);
      })
      .catch((err) => console.error("error:" + err));
  };

  return (
    <LayoutPageComponent>
      <p className="title">
        <b>Modifier un signataire</b>
      </p>
      <p style={{ paddingLeft: "30px" }}>
        <i>{message}</i>
      </p>
      <form className="form" onSubmit={handleSubmit}>
        <div>
          <p>Titre</p>
          <input
            type="text"
            name="title"
            value={title}
            placeholder="Mme, Dr, M."
            className="input-large"
            onChange={(e) => setTitle(e.target.value)}
          />
        </div>
        <div>
          <p>Prénom</p>
          <input
            type="text"
            name="firstName"
            value={firstname}
            className="input-large"
            onChange={(e) => setFirstname(e.target.value)}
          />
        </div>
        <div>
          <p>Nom</p>
          <input
            type="text"
            name="lastName"
            value={lastname}
            className="input-large"
            onChange={(e) => setLastname(e.target.value)}
          />
        </div>
        <EmailComponent
          name="email"
          value={setEmail}
          email={email}
          className="input-large"
          label="Email"
          isValid={setIsValid}
        />
        <div>
          <p>File</p>
          <input type="file" name="file" onChange={changeHandler} />
          {isSelected ? (
            <div>
              <p>
                Filename: {selectedFile?.name}, Size : {selectedFile?.size}{" "}
                bytes
              </p>
            </div>
          ) : (
            <p>Select a file to show details</p>
          )}
        </div>
        <div>
          <button
            type="submit"
            className="btnMain"
            style={{ cursor: "pointer" }}
          >
            Modifier
          </button>
          <button
            className="btnMain"
            style={{ marginLeft: "2rem", cursor: "pointer" }}
            onClick={() => setSignatoryStatus(!signatoryStatus)}
          >
            {signatoryStatus === false ? "Activate" : "Deactivate"}
          </button>
        </div>
      </form>
    </LayoutPageComponent>
  );
};

export default UpdateSignatory;
