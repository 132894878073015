//This Hook represent the user creation page
import React, { useState, useEffect } from "react";
import * as userApi from "../../api/User";
import * as companyApi from "../../api/Company";
import EmailComponent from "../../components/EmailComponent";
import CompanyComponent from "../../components/CompanyComponent";
import EtablishmentComponent from "../../components/EtablishmentComponent";
import RoleComponent from "../../components/RoleComponent";
import ReaderComponent from "../../components/ReaderComponent";
import "../../style/style.scss";
import LayoutPageComponent from "../../components/LayoutPageComponent";

const CreateUser = (props) => {
  const [isValid, setIsValid] = useState(false);
  const [title, setTitle] = useState("");
  const [email, setEmail] = useState("");
  const [company, setCompany] = useState("");
  const [etablishment, setEtablishment] = useState("");
  const [role, setRole] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [password, setPassword] = useState("");
  const [reader, setReader] = useState("");
  const [msg, setMsg] = useState("");
  const [isFetching, setIsFetching] = useState(true);
  const [companies, setCompanies] = useState();
  const [etablishments, setEtablishments] = useState();
  const [isOperator, setIsOperator] = useState(false);
  useEffect(() => {
    async function getCompanies() {
      setIsFetching(true);
      const response = await companyApi.getCompanies();
      if (response.error) {
        setMsg("Impossible de charger le formulaire. Réessayez svp !");
        setIsFetching(false);
      } else {
        setCompanies(response.data);
        setIsFetching(false);
      }
    }

    getCompanies();
  }, []);

  //Create user when form is submitted
  const handleSubmit = async (e) => {
    setMsg("");
    e.preventDefault();
    if (
      isValid &&
      company.toString().trim() !== "" &&
      email.trim() !== "" &&
      role.trim() !== "" &&
      firstname.trim() !== "" &&
      lastname.trim() !== "" &&
      title.trim() !== "" &&
      password.trim() !== "" &&
      reader.trim() !== "" &&
      etablishment.toString().trim() !== ""
    ) {
      const user = {
        title: title,
        email: email,
        company: company,
        establishment: etablishment,
        role: role,
        reader: reader,
        lastName: lastname,
        firstName: firstname,
        password: password,
        isOperator: isOperator,
      };
      const response = await userApi.createUser(user);
      setMsg(
        response.error
          ? "La création de cet utilisateur a échouée."
          : "La création de cet utilisateur s'est éffectuée avec succès."
      );
    } else {
      setMsg("Remplissez correctement les champs svp.");
    }
  };

  return (
    <LayoutPageComponent>
      <p className="title">Création d’un utilisateur</p>
      <form className="form" onSubmit={handleSubmit}>
        <div>
          <p>Titre *</p>
          <input
            type="text"
            name="title"
            placeholder="Mme, Dr, M."
            className="input-large"
            onChange={(e) => setTitle(e.target.value)}
          />
        </div>
        {!isFetching && companies && (
          <>
            <EmailComponent
              name="email"
              value={setEmail}
              className="input-large"
              label="Email *"
              isValid={setIsValid}
            />
            <RoleComponent
              name="role"
              className="input-large"
              label="Rôles *"
              setRole={setRole}
            />
            <div>
              <p>Prénom *</p>
              <input
                type="text"
                name="firstName"
                className="input-large"
                onChange={(e) => setFirstname(e.target.value)}
              />
            </div>
            <div>
              <p>Nom *</p>
              <input
                type="text"
                name="lastName"
                className="input-large"
                onChange={(e) => setLastname(e.target.value)}
              />
            </div>
            <div>
              <p>Mot de passe *</p>
              <input
                type="password"
                name="password"
                className="input-large"
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>

            <CompanyComponent
              name="company"
              className="input-large"
              label="Société *"
              setCompany={setCompany}
              data={companies}
              childDependency={setEtablishments}
              width={356}
            />
            {company !== "" && etablishments && (
              <EtablishmentComponent
                name="etablishment"
                className="input-large"
                label="Établissement *"
                setEtablishment={setEtablishment}
                data={etablishments}
                companySelected={company}
                width={356}
              />
            )}
            <ReaderComponent
              name="reader"
              className="input-large"
              label="Lecteur *"
              setReader={setReader}
            />
            <div>
              <input
                type="checkbox"
                name="isOperator"
                className="input-small"
                id="isOperator"
                onChange={(e) => setIsOperator(e.target.checked)}
              />
              <label for="isOperator" style={{ margin: "10px" }}>
                Operator
              </label>
            </div>
            <div>
              <button type="submit" className="btnMain">
                Valider
              </button>
            </div>
          </>
        )}
        <p>{msg}</p>
      </form>
    </LayoutPageComponent>
  );
};

export default CreateUser;
