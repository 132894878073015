const RoleComponent = (props) => {

    const { name, setRole, className, label } = props;

    const handleValueChange = (e) => {
        setRole(e.target.value);
    }

    const roles = [
        {
            value: "p",
            label: "Préleveur"
        },
        {
            value: "t",
            label: "Testeur"
        },
        {
            value: "p_t",
            label: "Préleveur testeur"
        },
        {
            value: "c",
            label: "Contrôleur"
        }
    ];

    return(
        <div>
            <p>{label}</p>
            <select name={name} className={className} onChange={handleValueChange} >
                {
                    [
                        <option value="">Choix du rôle</option>,
                        roles.map(role => {
                            return(
                                <option value={role.value}>{role.label}</option>
                            );
                        })
                    ]
                }   
            </select>
        </div>
    );
}

export default RoleComponent;