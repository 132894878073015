//This Hook represent the user creation page
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import * as etablishmentApi from "../../api/Etablishment";
import * as companyApi from "../../api/Company";
import EtablishmentTypeComponent from "../../components/EtablishmentTypeComponent";
import ReturnResultComponent from "../../components/ReturnResultComponent";
import CompanyComponent from "../../components/CompanyComponent";
import AddressComponent from "../../components/AddressComponent";
import { redirectToEtablishmentList } from "../../role_management/page_manager";
import "../../style/style.scss";
import LayoutPageComponent from "../../components/LayoutPageComponent";

const CreateEtablishment = (props) => {
  const history = useHistory();

  redirectToEtablishmentList(history);

  const [name, setName] = useState("");
  const [etablishmentType, setEtablishmentType] = useState("");
  const [address, setAddress] = useState({});
  const [finess, setFiness] = useState("");
  const [administrativeNumber, setAdministrativeNumber] = useState("");
  const [returnResult, setReturnResult] = useState("");
  const [company, setCompany] = useState("");
  const [msg, setMsg] = useState("");
  const [isFetching, setIsFetching] = useState(true);
  const [companies, setCompanies] = useState();

  useEffect(() => {
    setIsFetching(true);

    async function getCompanies() {
      const response = await companyApi.getCompanies();
      if (response.error) {
        setMsg("Impossible de charger le formulaire. Réessayez svp !");
      } else {
        setCompanies(response.data);
        setIsFetching(false);
      }
    }

    getCompanies();
  }, []);

  const handleAreIdenticalChange = (e) => {
    if (e.target.checked) {
      const selectedCompany = companies.find(
        // eslint-disable-next-line
        (company) => company.id == e.target.value
      );
      setName(selectedCompany.name);
      setAddress({
        street: selectedCompany.address.street,
        zip: selectedCompany.address.zip,
        city: selectedCompany.address.city,
        country: selectedCompany.address.country,
      });
      setAdministrativeNumber(selectedCompany.administrativeNumber);
    }
  };

  //Create user when form is submitted
  const handleSubmit = async (e) => {
    setMsg("");
    e.preventDefault();
    if (
      name.trim() !== "" &&
      etablishmentType.trim() !== "" &&
      address.street.trim() !== "" &&
      address.zip.trim() !== "" &&
      address.city.trim() !== "" &&
      address.country !== "" &&
      finess !== "" &&
      administrativeNumber !== "" &&
      company !== "" &&
      returnResult !== ""
    ) {
      const etablishment = {
        name: name,
        type: etablishmentType,
        address: address,
        finess: finess,
        administrativeNumber: administrativeNumber,
        resultReturn: returnResult,
        company: company,
      };
      const response = await etablishmentApi.createEtablishment(etablishment);
      setMsg(
        response.error
          ? response.data["message"]
          : "La création de cette société s'est éffectuée avec succès."
      );
    } else {
      setMsg("Remplissez correctement les champs svp.");
    }
  };

  return (
    <LayoutPageComponent>
      <p className="title">Création d’un établissement</p>
      <form className="form" onSubmit={handleSubmit}>
        {!isFetching && companies && (
          <>
            <CompanyComponent
              name="company"
              className="input-large"
              label="Société *"
              setCompany={setCompany}
              data={companies}
              width={356}
            />
            {company !== "" && (
              <label>
                <input
                  type={"checkbox"}
                  value={company}
                  onChange={handleAreIdenticalChange}
                />
                Établissement identique à la société ?
              </label>
            )}
            <div>
              <p>Nom de l'établissement *</p>
              <input
                type="text"
                name="name"
                className="input-large"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <EtablishmentTypeComponent
              name="type"
              className="input-large"
              label="Type d'établissement *"
              setEtablishmentType={setEtablishmentType}
            />
            <AddressComponent setAddress={setAddress} address={address} />
            <div>
              <p>N° SIREN, DUNS, ACS *</p>
              <input
                type="text"
                name="administrativeNumber"
                value={administrativeNumber}
                className="input-large"
                onChange={(e) => setAdministrativeNumber(e.target.value)}
              />
            </div>
            <div>
              <p>Finess *</p>
              <input
                type="text"
                name="finess"
                className="input-large"
                onChange={(e) => setFiness(e.target.value)}
              />
            </div>
            <ReturnResultComponent
              name="returnResult"
              className="input-large"
              label="Retour de résultat *"
              setReturnResult={setReturnResult}
            />
            <div>
              <button type="submit" className="btnMain">
                Valider
              </button>
            </div>
          </>
        )}
        <p>{msg}</p>
      </form>
    </LayoutPageComponent>
  );
};

export default CreateEtablishment;
