import React, { Fragment } from "react";
import { useHistory } from "react-router-dom";
import { validToken } from "../utils/JwtUtils";

const AuthWrapper = ({ children }) => {
  let history = useHistory();
  const token = localStorage.getItem("token");

  if (!token || !validToken(token)) {
    history.push({
      pathname: "/",
    });
  }

  return <Fragment>{children}</Fragment>;
};

export default AuthWrapper;
