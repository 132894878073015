import React from "react";
import * as entityApi from "../../api/Etablishment";
import EntityListTable from "../../components/EntityListTableComponent";
import CustomLink from "../../components/CustomLink";
import "../../style/style.scss";
import LayoutPageComponent from "../../components/LayoutPageComponent";

class ListEntity extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      msg: "",
      isFetching: false,
      data: [],
    };
  }

  async componentDidMount() {
    this.setState({ isFetching: true, msg: "Patientez svp..." });
    const response = await entityApi.getEtablishments();

    if (response.error) {
      this.setState({ isFetching: false, msg: "Aucune entité trouvée." });
    } else {
      this.setState({ data: response.data, isFetching: false, msg: "" });
    }
  }

  render() {
    //Get user role
    const isAdmin = JSON.parse(localStorage.getItem("user")).key;

    return (
      <LayoutPageComponent>
        <p className="title">Liste des établissements</p>
        {isAdmin && (
          <CustomLink className="btnMain" tag="div" to="/create-etablishment">
            <p className="btnText">Créer un établissement</p>
          </CustomLink>
        )}
        <p>{this.state.msg}</p>
        {!this.state.isFetching && <EntityListTable data={this.state.data} />}
      </LayoutPageComponent>
    );
  }
}

export default ListEntity;
