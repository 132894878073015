import React, { useState } from "react";
import * as priceApi from "../../api/Price";
import { useHistory } from "react-router-dom";
import Select from "react-select";

import LayoutPageComponent from "../../components/LayoutPageComponent";

const AddOnlinePayment = (props) => {
  var testTypesData = [
    { value: "1", label: "COVID-19 Antigenic Test" },
    { value: "2", label: "COVID-19 PCR Test" },
    { value: "3", label: "COVID-19 PCR Premium" },
  ];
  const history = useHistory();
  const [amount, setAmount] = useState("");
  const [serviceCharge, setServiceCharge] = useState("");
  const [total, setTotal] = useState("");
  // eslint-disable-next-line
  const [OnlinePriceData, setOnlinePriceData] = useState(
    props?.location?.state?.props?.data1
  );
  const [message, setMessage] = useState("");
  const [selectedTestOption, setSelectedTestOption] = useState(null);

  const testExists = (testID) => {
    return OnlinePriceData.some(function (el) {
      // eslint-disable-next-line
      return el.testTypeId == testID;
    });
  };

  if (testExists(1)) {
    testTypesData = [
      { value: "2", label: "COVID-19 PCR Test" },
      { value: "3", label: "COVID-19 PCR Premium" },
    ];
  }
  if (testExists(2)) {
    testTypesData = [
      { value: "1", label: "COVID-19 Antigenic Test" },
      { value: "3", label: "COVID-19 PCR Premium" },
    ];
  }
  if (testExists(3)) {
    testTypesData = [
      { value: "1", label: "COVID-19 Antigenic Test" },
      { value: "2", label: "COVID-19 PCR Test" },
    ];
  }
  if (testExists(1) && testExists(2)) {
    testTypesData = [{ value: "3", label: "COVID-19 PCR Premium" }];
  }
  if (testExists(1) && testExists(3)) {
    testTypesData = [{ value: "2", label: "COVID-19 PCR Test" }];
  }
  if (testExists(2) && testExists(3)) {
    testTypesData = [{ value: "1", label: "COVID-19 Antigenic Test" }];
  } else if (testExists(1) && testExists(2) && testExists(3)) {
    testTypesData = [];
  }

  const handleChangeTestType = (selectedTestOption) => {
    setSelectedTestOption(selectedTestOption);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const postData = [
      {
        establishmentId: props.location.state.props.data.id,
        testTypeId: Number(selectedTestOption.value),
        amount: Number(amount),
        serviceCharge: Number(serviceCharge),
        total,
      },
    ];

    const id = props.location.state.props.data.id;

    await priceApi
      .createPrice(postData, id)
      .then((response) => {
        setMessage("price updated");
        history.goBack();
      })
      .catch((err) => console.error("error:" + err));
  };

  return (
    <LayoutPageComponent>
      <p className="title">
        <b>Modifier</b>
      </p>
      <p style={{ paddingLeft: "30px" }}>
        <i>{message}</i>
      </p>
      <form className="form" onSubmit={handleSubmit}>
        <div style={{ width: "35%" }}>
          <p> Sélectionnez le type de test : </p>
          <Select
            value={selectedTestOption}
            onChange={handleChangeTestType}
            options={testTypesData}
          />
        </div>

        <br />
        <div>
          <p>Montant</p>
          <input
            required
            type="number"
            name="amount"
            value={amount}
            className="input-large"
            onChange={(e) => setAmount(e.target.value)}
          />
        </div>
        <div>
          <p>Service Charge</p>
          <input
            required
            type="number"
            name="serviceCharge"
            value={serviceCharge}
            className="input-large"
            onChange={(e) => {
              setServiceCharge(e.target.value);
              setTotal(Number(e.target.value) + Number(amount));
            }}
          />
        </div>
        <div>
          <p>Total</p>
          <input
            readOnly
            required
            type="text"
            name="total"
            value={total}
            className="input-large"
          />
        </div>

        <div>
          <button type="submit" className="btnMain">
            Modifier
          </button>
        </div>
      </form>
    </LayoutPageComponent>
  );
};

export default AddOnlinePayment;
