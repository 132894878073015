import React from "react";
import * as companyApi from "../../api/Company";
import CompanyListTable from "../../components/CompanyListTableComponent";
import CustomLink from "../../components/CustomLink";
import "../../style/style.scss";
import LayoutPageComponent from "../../components/LayoutPageComponent";

class ListCompany extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      msg: "",
      isFetching: false,
      data: [],
    };
  }

  async componentDidMount() {
    this.setState({ isFetching: true, msg: "Patientez svp..." });
    const response = await companyApi.getCompanies();

    if (response.error) {
      this.setState({ isFetching: false, msg: "Aucune compagnie trouvée." });
    } else {
      this.setState({ data: response.data, isFetching: false, msg: "" });
    }
  }

  render() {
    return (
      <LayoutPageComponent>
        <p className="title">Liste des sociétés</p>
        <div style={{ display: "flex" }}>
          <div>
            <CustomLink className="btnMain" tag="div" to="/create-company">
              <p className="btnText">Créer une société</p>
            </CustomLink>
          </div>
          &nbsp;&nbsp;&nbsp;
          <div>
            <CustomLink className="btnMain" tag="div" to="/sepa">
              <p className="btnText">Générer SEPA (XML)</p>
            </CustomLink>
          </div>
          &nbsp;&nbsp;&nbsp;
          <div>
            <CustomLink className="btnMain" tag="div" to="/import-sepa">
              <p className="btnText">Importer SEPA (xlsx)</p>
            </CustomLink>
          </div>
        </div>
        <p>{this.state.msg}</p>
        {!this.state.isFetching && <CompanyListTable data={this.state.data} />}
      </LayoutPageComponent>
    );
  }
}

export default ListCompany;
