/**
    This file is used to perform all http requests
    Not by Gianluca : remove this, it is a bad practice to do this that way
    The error, data is not appropriate
*/

export function doGet(url, headers = {}) {
  return fetch(url, {
    method: "GET",
    headers: headers,
  })
    .then((response) => response.json())
    .then(
      (result) => {
        return { error: false, data: result };
      },
      (error) => {
        console.error("Err: ", error);
        return { error: true, data: error };
      }
    )
    .catch((response) => {
      return { error: true, data: response };
    });
}

export function doPost(url, body, headers = null) {
  return fetch(url, {
    method: "POST",
    headers: headers,
    body: JSON.stringify(body),
  })
    .then((response) => {
      if (response.status === 200) {
        return response.json().then((result) => {
          return { error: false, data: result };
        });
      } else {
        // This is not a good then the json may not be valid
        return response.text().then((error) => {
          return { error: true, data: JSON.parse(error) };
        });
      }
    })
    .catch((response) => {
      return { error: true, data: response };
    });
}

export function doPostRaw(url, body, headers = null) {
  return fetch(url, {
    method: "POST",
    headers: headers,
    body: body,
  })
    .then((response) => {
      if (response.status === 200) {
        return response.json().then((result) => {
          return { error: false, data: result };
        });
      } else {
        // This is not a good then the json may not be valid
        return response.text().then((error) => {
          return { error: true, data: JSON.parse(error) };
        });
      }
    })
    .catch((response) => {
      return { error: true, data: response };
    });
}

export function doPut(url, body, headers = null) {
  return fetch(url, {
    method: "PUT",
    headers: headers,
    body: JSON.stringify(body),
  })
    .then((response) => {
      if (response.status === 200)
        return response.json().then((result) => {
          return { error: false, data: result };
        });
      else
        return response.json().then((error) => {
          return { error: true, data: error };
        });
    })
    .catch((response) => {
      return { error: true, data: response };
    });
}

export function doPatch(url, body, headers = null) {
  return fetch(url, {
    method: "PATCH",
    headers: headers,
    body: JSON.stringify(body),
  })
    .then((response) => {
      if (response.status === 200) {
        return response.json().then((result) => {
          return { error: false, data: result };
        });
      } else {
        // This is not a good then the json may not be valid
        return response.text().then((error) => {
          return { error: true, data: JSON.parse(error) };
        });
      }
    })
    .catch((response) => {
      return { error: true, data: response };
    });
}

export function download(url, patientID, headers = null) {
  return (
    fetch(url, {
      method: "GET",
      headers: headers,
    })
      // 1. Convert the data into 'blob'
      .then((response) => {
        if (response.status === 200) {
          response.blob().then((blob) => {
            // 2. Create blob link to download
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `${patientID}-REPORT.pdf`); // 3. Append to html page
            document.body.appendChild(link); // 4. Force download
            link.click(); // 5. Clean up and remove the link
            link.parentNode.removeChild(link);
          });
          return { error: false };
        } else {
          return { error: true };
        }
      })
      .catch((response) => {
        return { error: true, data: response };
      })
  );
}
