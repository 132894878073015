import React, { useState, useEffect } from "react";
import * as DateUtils from "../utils/DateUtils";
import CustomLink from "./CustomLink";
import * as patientApi from "../api/Patient";
import * as resultApi from "../api/Result";

import moment from "moment";

const PatientDetailsTableComponentNew = (props) => {
  const [document1, setDocument1] = useState();
  const [document2, setDocument2] = useState();
  const [document3, setDocument3] = useState();
  const [imageArray, setImageArray] = useState([]);
  const imgArray = [];

  useEffect(() => {
    if (
      props.data.tubeData[0]?.tube?.tubeDocs.length > 0 &&
      props.data.tubeData[0]?.tube?.tubeDocs[0] !== "null"
    ) {
      resultApi
        .getResultDocuments(props.data.tubeData[0]?.tube?.tubeDocs[0])
        .then((objectUrl) => {
          setDocument1(objectUrl);
          if (objectUrl !== "null") {
            imgArray.push(objectUrl);
          }
        })
        .catch((err) => {});

      setImageArray(imgArray);
    }

    if (props.data.tubeData[0]?.tube?.tubeDocs.length > 1) {
      resultApi
        .getResultDocuments(props.data.tubeData[0]?.tube?.tubeDocs[1])
        .then((objectUrl) => {
          setDocument2(objectUrl);
          if (objectUrl !== "null") {
            imgArray.push(objectUrl);
          }
        })
        .catch((err) => {});

      setImageArray(imgArray);
    }

    if (props.data.tubeData[0]?.tube?.tubeDocs.length > 2) {
      resultApi
        .getResultDocuments(props.data.tubeData[0]?.tube?.tubeDocs[2])
        .then((objectUrl) => {
          setDocument3(objectUrl);
          if (objectUrl !== "null") {
            imgArray.push(objectUrl);
          }
        })
        .catch((err) => {});
      setImageArray(imgArray);
    }
    // eslint-disable-next-line
  }, []);

  const fileDownloadHandler = async (pictures) => {
    for (var i = 0; i < pictures.length; i++) {
      const response = await fetch(pictures[i]);
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = "picture.jpeg";
        a.click();
      });
    }
  };

  const handleDownload = (patientID) => {
    patientApi.downloadPatientReport(patientID).then((value) => {
      if (value["error"]) {
        alert("Rapport indisponible.");
      } else {
        alert("Rapport téléchargé avec succès.");
      }
    });
  };

  const displayFullAccommodationType = (accommodationType) => {
    let fullTextAccommodationType = "";
    switch (accommodationType) {
      case "I":
        fullTextAccommodationType =
          "Hébergement individuel privé (maison, appartement)";
        break;
      case "H":
        fullTextAccommodationType = "Hospitalisé au moment du prélèvement";
        break;
      case "E":
        fullTextAccommodationType = "Résident en EHPAD";
        break;
      case "C":
        fullTextAccommodationType = "En milieu carcéral";
        break;
      case "A":
        fullTextAccommodationType = "Autre structure d’hébergement collectif";
        break;
      case "U":
        fullTextAccommodationType = "Ne sait pas";
        break;
      default:
        fullTextAccommodationType = "Ne sait pas";
    }

    return fullTextAccommodationType;
  };

  return (
    <>
      {/* <p className="table-title">Paiement : </p> */}
      <table className="table-recap">
        <tr className="">
          <td>
            <p className="table-title">Paiement : </p>
          </td>
          <td>
            <p className="recap-label">
              {props.data.tubeData
                ? props.data.tubeData[0]?.tube?.paymentMethod
                : ""}
            </p>
          </td>
        </tr>
        {props.data.tubeData[0]?.tube?.paymentMethod === "online" && (
          <tr className="recap-row">
            <td>
              <p className="recap-label">Test Type</p>
            </td>
            <td>
              <p className="recap-label">
                {props.data.tubeData[0]?.tube?.testType.id === 1
                  ? "Antigenic"
                  : props.data.tubeData[0]?.tube?.testType.id === 2
                  ? "RT-PCR"
                  : "PCR Premium"}
              </p>
            </td>
          </tr>
        )}

        {props.data.tubeData[0]?.tube?.paymentMethod === "online" && (
          <tr className="recap-row">
            <td>
              <p className="recap-label">Price</p>
            </td>
            <td>
              <p className="recap-label">
                {Number(props.data.tubeData[0]?.tube?.amount) +
                  Number(props.data.tubeData[0]?.tube?.serviceCharge)}
              </p>
            </td>
          </tr>
        )}
      </table>

      <p className="table-title">Identité :</p>
      <table className="table-recap">
        <tr className="recap-row">
          <td>
            <p className="recap-label">Sexe</p>
          </td>
          <td>
            <p className="label">
              {props.data.patientData.gender === "M" ? "Masculin" : "Féminin"}
            </p>
          </td>
        </tr>
        <tr className="recap-row">
          <td>
            <p className="recap-label">Prénom</p>
          </td>
          <td>{props.data.patientData.firstName}</td>
        </tr>
        <tr className="recap-row">
          <td>
            <p className="recap-label">Nom</p>
          </td>
          <td>{props.data.patientData.lastName}</td>
        </tr>
        <tr className="recap-row">
          <td>
            <p className="recap-label">Date de naissance</p>
          </td>
          <td>
            <p className="label">
              {moment(props.data.patientData.dateOfBirth).format("DD-MM-YYYY")}
            </p>
          </td>
        </tr>
        <tr className="recap-row">
          <td>
            <p className="recap-label">Lieu de naissance</p>
          </td>
          <td>
            <p className="label">
              {props.data.patientData.birthPlace !== null
                ? props.data.patientData.birthPlace
                : "N/D"}
            </p>
          </td>
        </tr>
        <tr className="recap-row">
          <td>
            <p className="recap-label">Nationalité</p>
          </td>
          <td>
            <p className="label">
              {props.data.patientData.infoSupp?.nationality}
            </p>
          </td>
        </tr>
        <tr className="recap-row">
          <td>
            <p className="recap-label">Adresse mail personnelle</p>
          </td>
          <td>
            <p className="label">{props.data.patientData.email}</p>
          </td>
        </tr>
        <tr className="recap-row">
          <td>
            <p className="recap-label">Téléphone mobile personnel</p>
          </td>
          <td>
            <p className="label">{props.data.patientData.phoneNumber}</p>
          </td>
        </tr>
        <tr className="recap-row">
          <td>
            <p className="recap-label">
              {props.data.patientData.patientSource === "Spain"
                ? "DNI/NIE"
                : "N° de sécurité sociale"}
            </p>
          </td>
          <td>
            <p className="label">
              {props.data.nir !== null ? props.data.patientData.nir : "N/D"}
            </p>
          </td>
        </tr>
        <tr className="recap-row">
          <td>
            <p className="recap-label">Date de création</p>
          </td>
          <td>
            <p className="label">
              {DateUtils.dateToFrenchFormat(props.data.patientData.createdAt)}
            </p>
          </td>
        </tr>
        <tr className="recap-row">
          <td>
            <p className="recap-label">Patient Source</p>
          </td>
          <td>
            <p className="label">
              {props.data.patientData.patientSource === "Spain"
                ? "Mipruebarapida"
                : "Montestrapide"}
            </p>
          </td>
        </tr>
      </table>

      {/*Move to another component*/}
      <p className="table-title">Test :</p>
      <table className="table-recap">
        <tr className="recap-row">
          <td>
            <p className="recap-label">Nom du test</p>
          </td>
          <td>
            <span className="label">
              {props.data.tubeData[0]?.tube?.testType?.label?.fr}
            </span>
            &nbsp; (
            <CustomLink
              className="see-more-link"
              tag="a"
              onClick={() =>
                handleDownload(
                  props.data?.tubeData[0]?.tube?.cassette?.result?.id
                )
              }
            >
              Télécharger le rapport
            </CustomLink>
            )
          </td>
        </tr>
      </table>
      {/* document associes */}
      <p className="table-title">Document associés :</p>
      <table className="table-recap">
        <tr className="recap-row">
          <td style={{ display: "flex", justifyContent: "space-between" }}>
            {props.data.tubeData[0]?.tube.tubeDocs.length > 0 && (
              <a href={document1} download target="_blank" rel="noreferrer">
                <img
                  src={document1}
                  style={{ height: "300px", padding: "5px" }}
                  alt=""
                />
              </a>
            )}
            {props.data.tubeData[0]?.tube?.tubeDocs.length > 1 && (
              <a href={document2} download target="_blank" rel="noreferrer">
                <img
                  src={document2}
                  style={{ height: "300px", padding: "5px" }}
                  alt=""
                />
              </a>
            )}
            {props.data.tubeData[0]?.tube?.tubeDocs.length > 2 && (
              <a href={document3} download target="_blank" rel="noreferrer">
                <img
                  src={document3}
                  style={{ height: "300px", padding: "5px" }}
                  alt=""
                />
              </a>
            )}
          </td>
          {props.data.tubeData[0]?.tube?.tubeDocs.length > 0 && (
            <button
              type="button"
              style={{
                display: "inline-block",
                padding: "0.25rem 0.75rem",
                backgroundColor: "#4867d8",
                boxShadow: "0 0 20px -10px rgb(0 0 0 / 50%)",
                color: "#ffffff",
                fontWeight: "bold",
                border: "0",
                width: "200px",
                height: "40px",
                borderRadius: "10px",
                cursor: "pointer",
                margin: "10px",
              }}
              onClick={() => fileDownloadHandler(imageArray)}
            >
              Télécharger
            </button>
          )}
        </tr>
      </table>

      {/* Hébergement */}
      <p className="table-title">Hébergement :</p>
      <table className="table-recap">
        <tr className="recap-row">
          <td>
            <p className="recap-label">Nature de l’hébergement</p>
          </td>
          <td>
            <p className="label">
              {displayFullAccommodationType(
                props.data.patientData.accommodationType
              )}
            </p>
          </td>
        </tr>
        <tr className="recap-row">
          <td>
            <p className="recap-label">Adresse</p>
          </td>
          <td>
            <p className="label">
              {props.data.patientData.address?.street
                ? props.data.patientData.address?.street
                : "N/D"}
            </p>
          </td>
        </tr>
        <tr className="recap-row">
          <td>
            <p className="recap-label">Code Postal</p>
          </td>
          <td>
            <p className="label">{props.data.patientData.address?.zip}</p>
          </td>
        </tr>
        <tr className="recap-row">
          <td>
            <p className="recap-label">Ville</p>
          </td>
          <td>
            <p className="label">
              {props.data.patientData.address?.city
                ? props.data.patientData.address?.city
                : "N/D"}
            </p>
          </td>
        </tr>
        <tr className="recap-row">
          <td>
            <p className="recap-label">Pays</p>
          </td>
          <td>
            <p className="label">{props.data.patientData.address?.country}</p>
          </td>
        </tr>
      </table>
    </>
  );
};

export default PatientDetailsTableComponentNew;
