import * as requests from "../utils/HttpRequests";

const baseUrl = process.env.REACT_APP_API_BASE_URL;

//Get list of packing
export async function getPackingList(data) {
  const response = await requests.doPost(baseUrl + "/packing/list/", data, {
    "content-type": "application/json",
    auth: localStorage.getItem("token"),
  });
  return response;
}

//Get single packing
export async function getPacking(packingId) {
  const response = await requests.doGet(baseUrl + "/packing/get/" + packingId, {
    "content-type": "application/json",
    auth: localStorage.getItem("token"),
  });
  return response;
}
//
