import React from "react";
import * as patientApi from "../../api/Patient";
import LayoutPageComponent from "../../components/LayoutPageComponent";
import PatientDetailsTableComponentNew from "../../components/PatientDetailsTableComponentNew";

class PatientDetailsNew extends React.Component {
  constructor(props) {
    super(props);

    this.patientID = this.props.history.location["state"]
      ? this.props.history.location["state"]
      : null;

    this.patientIDNew = props.location.state.patientID
      ? props.location.state.patientID
      : null;

    this.patientTubeId = props.location.state.tubeID
      ? props.location.state.tubeID
      : null;

    this.state = {
      isFetching: false,
      msgPatient: this.patientData === null ? "Aucun patient sélectionné" : "",
      patientID: this.patientID !== null ? this.patientID : "",
      patientIDNew: this.patientIDNew !== null ? this.patientIDNew : "",
      patientTubeId: this.patientTubeId !== null ? this.patientTubeId : "",
      patientData: {},
      patientDataNew: {},
    };
  }

  async componentWillMount() {
    this.setState({ isFetching: true, msgPatient: "Patientez svp..." });
    const responseNew = await patientApi.getPatient(this.state.patientIDNew);

    const responseTube = await patientApi.getTube(this.state.patientTubeId);

    if (responseNew.error) {
      this.setState({ isFetching: false, msgPatient: "Aucun patient trouvée" });
    } else {
      var data = { patientData: responseNew.data, tubeData: responseTube.data };
      this.setState({
        patientDataNew: data,
        isFetching: false,
        msgPatient: "",
      });
    }
  }

  render() {
    //Get user logged
    return (
      <LayoutPageComponent>
        <p className="title">ID Patient : {this.state.patientIDNew}</p>
        <p>{this.state.msgPatient}</p>
        {!this.state.isFetching && (
          <PatientDetailsTableComponentNew data={this.state.patientDataNew} />
        )}
      </LayoutPageComponent>
    );
  }
}

export default PatientDetailsNew;
