import React, { Component } from "react";
import { Button, Input } from "@material-ui/core";
import LayoutPageComponent from "../../components/LayoutPageComponent";
import * as companyApi from "../../api/Company";

class UploadSepa extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedFile: null,
      msg: "",
    };
  }

  handleFileChange = (event) => {
    const file = event.target.files[0];
    this.setState({ selectedFile: file, msg: "" });
  };

  handleUploadFile = async () => {
    if (!this.state.selectedFile) {
      alert("Please select an Excel Sepa file");
      return;
    }

    this.setState({ msg: "" });
    const response = await companyApi.uploadSepaFile(this.state.selectedFile);

    if (response.error) {
      this.setState({
        msg: "Unable to upload this file. Please check if file is correct.",
      });
      return;
    }

    this.setState({ msg: "Sepa file is successfully uploaded." });
  };

  render() {
    const { msg } = this.state;

    return (
      <LayoutPageComponent>
        <p className="title">Importer un fichier Sepa</p>
        <div className="filterControl">
          <div>
            <p>Choisissez un fichier (.xslx or .xls)</p>
            <Input
              id="outlined-basic"
              type={"file"}
              onChange={this.handleFileChange}
              accept={".xlsx, .xls"}
              variant="outlined"
            />
          </div>
          <br />
          <div>
            <Button
              variant="contained"
              color="primary"
              onClick={this.handleUploadFile}
            >
              Mettre à jour
            </Button>
          </div>
          <p>{msg}</p>
        </div>
      </LayoutPageComponent>
    );
  }
}

export default UploadSepa;
