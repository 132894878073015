import React from 'react';
import Datatable from './DatatableComponent';
import * as patientApi from '../api/Patient';
import ListResultHeader from '../pages_config/list_result_header.json';
import * as DateUtils from '../utils/DateUtils'
import "../style/style.scss";


const ResultListTable = (props) => {
    //Get column from ListResultHeader in pages configuration
    const columns = ListResultHeader;

    var resultData = [];

    const handleDownload = (patientID) => {
        patientApi.downloadPatientReport(patientID).then((value) => {
            if (value["error"]) {
                alert("Rapport indisponible.");
            } else {
                alert("Rapport téléchargé avec succès.");
            }
        });
    }

    props.data.forEach(element => {
        if (element) {
            resultData.push({
                id: element.id,
                createdAt: DateUtils.dateToFrenchFormat(element.createdAt),
                patient: element.patientId,
                name: { toShow: element.user?.firstName + " " + element.user?.lastName, linkTo: element.user?.id, pathName: "/user-details" },
                status: element.lines.Ag,
                rapport: { toShow: "Rapport", linkTo: element.patientId, download: true },
                details: { toShow: "Voir", linkTo: JSON.stringify(element), pathName: "/result-details" }
            });
        }
    });

    return (
        <Datatable columns={columns} data={resultData} action={handleDownload} showFilterBar={true} />
    );
}

export default ResultListTable;