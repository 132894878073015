import React from "react";
import { Page, Document, StyleSheet, Image, Text } from "@react-pdf/renderer";
import magentine_healthcare from "../../assets/magentine_healthcare.png";

const styles = StyleSheet.create({
  pageBackground: {
    position: "absolute",
    minWidth: "100%",
    minHeight: "100%",
    display: "block",
    height: "370",
    width: "345",
    zIndex: -1,
    margin: -10,
    top: "-100",
    left: -28,
    paddingTop: -25,
  },
  body: {
    paddingTop: 20,
    paddingBottom: 65,
    paddingHorizontal: 30,
    marginTop: 80,
    textAlign: "center",
  },
  title: {
    fontSize: 32,
    textAlign: "center",
    fontFamily: "Times-Bold",
    fontWeight: "heavy",
    marginTop: -60,
  },
  identityContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginRight: 20,
  },
  author: {
    fontSize: 14,
    textAlign: "center",
    marginBottom: 20,
    fontFamily: "Times-Roman",
  },
  subHeadTitle: {
    fontSize: 14,
    textAlign: "center",
    marginBottom: 20,
    fontFamily: "Times-Roman",
    fontWeight: "bold",
  },
  subtitle: {
    fontSize: 14,
    marginTop: 18,
    marginBottom: 18,
    fontFamily: "Times-Roman",
    fontWeight: "bold",
    textDecoration: "underline",
  },
  text: {
    marginTop: 3,
    marginBottom: 6,
    fontSize: 24,
    textAlign: "center",
    fontFamily: "Helvetica-Bold",
    fontWeight: "heavy",
  },
  image: {
    width: 350,
    height: 350,
  },
  qrImage: {
    position: "relative",
    width: 185,
    height: 190,
    marginLeft: "auto",
    marginRight: "auto",
    zIndex: 0,
    padding: -30,
    top: "-50",
    left: "-18",
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: "center",
    color: "grey",
  },
  headerContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 10,
    padding: "3",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
});

const PatientPDF = ({ patient, qr }) => {
  return (
    <Document>
      <Page size="A6" style={styles.body}>
        <div style={styles.headerContainer}>
          <Image style={styles.pageBackground} src={magentine_healthcare} />
          <Image style={styles.qrImage} src={qr} />
        </div>
        <Text style={styles.title}>{patient.id}</Text>
        <Text style={styles.text}>
          {patient.firstName} {patient.lastName}
        </Text>
      </Page>
    </Document>
  );
};
export default PatientPDF;
