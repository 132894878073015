import React from 'react'
import Datatable from './DatatableComponent'
import * as patientApi from '../api/Patient'
import ListPatientHeader from '../pages_config/list_patient_header.json'
import * as DateUtils from '../utils/DateUtils'
import '../style/style.scss'

const PatientListTable = (props) => {
  //Get column from ListPatientHeader in pages configuration
  let columns = ListPatientHeader

  var patientData = []

  const handleDownload = (patientID) => {
    patientApi.downloadPatientReport(patientID).then((value) => {
      if (value['error']) {
        alert('Rapport indisponible.')
      } else {
        alert('Rapport téléchargé avec succès.')
      }
    })
  }
  let isOperator = localStorage.getItem('isOperator')
  props.data.forEach((element) => {
    let data = element?.tube?.pouch
    // eslint-disable-next-line
    // let data = element?.tube?.pouch?.map((barcode) => {
    //   if (barcode.pouchBarcode === element?.tube?.barcodeTube) {
    //     return barcode.pouchBarcode;
    //   }
    // });

    let testType = ' '
    let pouchBarcode = ''
    if (element.tube?.testType?.id.toString() === '2') {
      testType = 'PCR'
    } else if (element.tube?.testType?.id.toString() === '1') {
      testType = 'TAG'
    }
    var resultLine = ''
    if (
      element.tube?.cassette?.result?.lines?.Ag == null ||
      element.tube?.cassette?.result?.lines?.Ag === undefined
    ) {
      resultLine = element.tube?.cassette?.result?.lines?.T
    } else {
      resultLine = element.tube?.cassette?.result?.lines?.Ag
    }

    if (testType === 'PCR') {
      // eslint-disable-next-line
      pouchBarcode = element.pouch ? element.pouch[0]?.pouchBarcode : ''
    }
    const record = {
      recap: {
        toShow: element.id,
        linkTo: JSON.stringify(element),
        pathName: '/patient-with-result',
        pdf: true,
      },
      // detail: {toShow: "Voir", linkTo: JSON.stringify(element), pathName: "/patient-with-result"},
      id: {
        toShow: element.id,
        linkTo: JSON.stringify(element),
        pathName: '/patient-with-result',
      },
      lastName: {
        toShow: element.lastName,
        linkTo: JSON.stringify(element),
        pathName: '/patient-with-result',
      },
      firstName: {
        toShow: element.firstName,
        linkTo: JSON.stringify(element),
        pathName: '/patient-with-result',
      },
      payment: element?.tube?.paymentMethod,

      phoneNumber: element.phoneNumber,
      // patientDate: DateUtils.dateToFrenchFormat(element.createdAt),
      // cassetteDate: element.tube?.cassette?.createdAt ? DateUtils.dateToFrenchFormat(element.tube?.cassette?.createdAt) : "",
      tubeDate: DateUtils.dateToFrenchFormat(element.tube?.createdAt),
      gender: element.gender,
      testType: testType,
      rapport: {
        toShow: resultLine,
        linkTo: element?.tube?.cassette?.result?.id,
        download: true,
      },
      // does not work resultLink: { toShow: ">", linkTo: JSON.stringify(element.tube?.cassette?.result), pathName: "/result-details" },
      status: element.status,
      tube: element.tube?.barcodeTube,
      user: element?.user?.email,

      pouch:
        element?.tube?.pouch === null ? '' : data === undefined ? '' : data,
      cassette: element.tube?.cassette?.barcodeCassette,
      // result: element.tube?.cassette?.result?.id,
    }
    if (isOperator === 'true') {
      record.id = { toShow: element.id }
      columns = columns.reduce((acm, item) => {
        if (
          ![
            'Récap',
            "Nom d'usage",
            'Prénom',
            'Email',
            'Gender',
            'Résultat',
          ].includes(item.Header)
        ) {
          acm.push(item)
        }
        return acm
      }, [])
    }
    patientData.push(record)
  })

  return (
    <Datatable
      columns={columns}
      data={patientData}
      action={handleDownload}
      showFilterBar={true}
      setFilterValue={props.setFilterValue}
    />
  )
}

export default PatientListTable
