import React from "react";
import * as userApi from "../../api/User";
import UserDetailsTableComponent from "../../components/UserDetailsTableComponent";
import LayoutPageComponent from "../../components/LayoutPageComponent";

class UserDetails extends React.Component {
  constructor(props) {
    super(props);
    this.userID = this.props.history.location["state"]
      ? this.props.history.location["state"]
      : null;

    this.state = {
      isFetching: false,
      msg: this.userID === null ? "Aucun utilisateur sélectionné" : "",
      userID: this.userID !== null ? this.userID : "",
      userData: {},
    };

    // console.log(this.state.userID);
  }

  async componentWillMount() {
    this.setState({ isFetching: true, msg: "Patientez svp..." });
    const response = await userApi.getUser(this.state.userID);

    // console.log('response from user_details', response);

    if (response.error) {
      this.setState({ isFetching: false, msg: "Aucun utilisateur trouvé." });
    } else {
      var data = response.data;
      this.setState({ userData: data, isFetching: false, msg: "" });
    }
  }

  // activate and deactivate user
  deactivateUser = async () => {
    try {
      // eslint-disable-next-line
      const response = await userApi
        .updateUser(this.state.userID, { active: !this.state.userData.active })
        .then((response) => {
          const tmpUser = this.state.userData;
          tmpUser.active = response.data.active;
          this.setState({ userData: tmpUser });
        });
    } catch (e) {
      console.error(e);
    }
  };
  changeStatus = async () => {
    try {
      // eslint-disable-next-line
      const response = await userApi
        .updateUser(this.state.userID, {
          isOperator: !this.state.userData.isOperator,
        })
        .then((response) => {
          const tmpUser = this.state.userData;
          tmpUser.isOperator = response.data.isOperator;
          this.setState({ userData: tmpUser });
        });
    } catch (e) {
      console.error(e);
    }

    // console.log('change status', this.state.userData);
  };

  render() {
    return (
      <LayoutPageComponent>
        <p className="title">Détails utilisateur</p>
        <p>Informations utilisateur</p>
        <p>{this.state.msg}</p>
        <button className="btnMain" onClick={() => this.deactivateUser()}>
          Activate/Deactivate
        </button>
        {/* <button className="btnMain" onClick={() => this.changeStatus()}>switch</button> */}
        {!this.state.isFetching && (
          <UserDetailsTableComponent
            data={this.state.userData}
            handleChange={this.changeStatus}
          />
        )}
      </LayoutPageComponent>
    );
  }
}

export default UserDetails;
