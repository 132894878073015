import React, { useEffect, useState } from "react";
import * as companyApi from "../api/Company";
import Select from "react-select";

const CompanyComponent = (props) => {
  const { name, setCompany, label, data, childDependency, width } = props;
  const [companyData, setCompanyData] = useState([]);

  useEffect(() => {
    let companyList = [];
    data.map((company) =>
      companyList.push({ value: company.id, label: company.name })
    );
    setCompanyData(companyList);
    // eslint-disable-next-line
  }, []);

  const handleValueChange = async (selected) => {
    setCompany(selected.value);

    if (childDependency) {
      childDependency(null);
      const response = await companyApi.getCompany(selected.value);
      if (response.error) {
        childDependency(null);
      } else {
        childDependency(response.data.establishments);
      }
    }
  };

  return (
    <div style={{ width: width ? width + "px" : "" }}>
      <p>{label}</p>
      <Select
        placeholder={"Choix de société"}
        name={name}
        onChange={handleValueChange}
        options={companyData}
      />
    </div>
  );
};

export default CompanyComponent;
