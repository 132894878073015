import * as requests from "../utils/HttpRequests";
import config from "../config/load_config";

const apiUrl = process.env.REACT_APP_API_BASE_URL + config.API.PRICE;

//Get payment details
export async function getPrice(id) {
  //   const payload = { establishment, startDate, endDate, testType };
  const url = apiUrl + `?establishmentId=${id}`;
  const response = await requests.doGet(url, {
    "content-type": "application/json",
    auth: localStorage.getItem("token"),
  });
  return response;
}

export async function updatePrice(id, data) {
  //   const payload = { establishment, startDate, endDate, testType };
  const response = await requests.doPatch(apiUrl + "/" + id, data, {
    "content-type": "application/json",
    auth: localStorage.getItem("token"),
  });
  return response;
}
export async function createPrice(data, establishmentId) {
  //   const payload = { establishment, startDate, endDate, testType };
  const url = apiUrl + `?establishmentId=${establishmentId}`;

  const response = await requests.doPost(url, data, {
    "content-type": "application/json",
    auth: localStorage.getItem("token"),
  });
  return response;
}
