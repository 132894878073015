import countryList from "react-select-country-list";

const CountryComponent = (props) => {
  const { name, setCountry, className, label, value } = props;

  const handleValueChange = (e) => {
    setCountry(e.target.value);
  };

  const listOfCountry = countryList().getData();

  return (
    <div>
      <p>{label}</p>
      <select name={name} className={className} onChange={handleValueChange}>
        {[
          <option value="">Choix du pays</option>,
          listOfCountry.map((cty) => {
            return value === cty.value ? (
              <option value={cty.value} selected>
                {cty.label}
              </option>
            ) : (
              <option value={cty.value}>{cty.label}</option>
            );
          }),
        ]}
      </select>
    </div>
  );
};

export default CountryComponent;
