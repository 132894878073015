import React, { Fragment } from "react";
import * as companyApi from "../../api/Company";
import LayoutPageComponent from "../../components/LayoutPageComponent";
import Select from "react-select";
import { Button } from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import BillingCompanyDetailsTableComponent from "../../components/BillingCompanyDetailsTableComponent";
import DataTable from "react-data-table-component";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

class Invoicing extends React.Component {
  constructor(props) {
    super(props);

    this.companyID = this.props.history.location["state"]
      ? this.props.history.location["state"]
      : null;
    let currentYear = new Date();

    this.state = {
      isFetching: false,
      msgCompany:
        this.companyData === null ? "Aucune compagnie sélectionnée" : "",
      msgEntities: "",
      msgUser: "",
      companyID: this.companyID !== null ? this.companyID : "",
      companyData: {},
      invoiceData: null,
      selectedMonth: null,
      editEmail: false,
      invoicingEmail: "",
      invoiceSentMsg: "",
      priceRange: null,
      priceRangeList: [],
      months: [
        { value: "01", label: "Janvier" },
        { value: "02", label: "Février" },
        { value: "03", label: "Mars" },
        { value: "04", label: "Avril" },
        { value: "05", label: "Mai" },
        { value: "06", label: "Juin" },
        { value: "07", label: "Juillet" },
        { value: "08", label: "Août" },
        { value: "09", label: "Septembre" },
        { value: "10", label: "Octobre" },
        { value: "11", label: "Novembre" },
        { value: "12", label: "Décembre" },
      ],
      establishmentTableHeader: [
        {
          name: "ID",
          selector: "establishmentid",
          sortable: true,
        },
        {
          name: "Établissement",
          selector: "estblishmentname",
          sortable: true,
        },
        {
          name: "Produit",
          selector: "test_type",
          sortable: true,
        },
        {
          name: "Quantité",
          selector: "count",
          sortable: true,
        },
        {
          name: "Prix de vente",
          selector: "price",
          sortable: true,
        },
        {
          name: "Prix total",
          selector: "total",
          sortable: true,
        },
      ],
      establishmentTableHeaderNew: [
        {
          name: "ID",
          selector: "establishmentid",
          sortable: true,
          omit: true,
        },
        {
          name: "Établissement",
          selector: "estblishmentname",
          sortable: true,
          omit: true,
        },
        {
          name: "",
          selector: "",
          sortable: true,
        },
        {
          name: "",
          selector: "",
          sortable: true,
        },
        {
          name: "",
          selector: "test_type",
          sortable: true,
        },
        {
          name: "Quantité",
          selector: "count",
          sortable: true,
        },
        {
          name: "Prix de vente",
          selector: "price",
          sortable: true,
        },
        {
          name: "Prix total",
          selector: "total",
          sortable: true,
        },
      ],
      selectedYear: currentYear,
      totalAmountInformations: null,
      extraServicesHeader: [
        {
          name: "Nom",
          selector: "name",
          sortable: true,
        },
        {
          name: "Quantité",
          selector: "qty",
          sortable: true,
        },
        {
          name: "Prix de vente",
          selector: "price",
          sortable: true,
        },
        {
          name: "Prix total",
          selector: "amount",
          sortable: true,
        },
      ],
      extraServicesData: [],
      extraServiceNewData: [],
      addExtraService: false,
      extraService: null,
      price_total: null,
    };
  }

  handleMonthChange = (month) => {
    this.setState({
      selectedMonth: month,
    });
  };

  handleYearChange = (year) => {
    this.setState({
      selectedYear: year,
    });
  };

  handleInvoicingEmailChange = (e) => {
    this.setState({
      invoicingEmail: e.target.value,
    });
  };

  handleUpdateEmail = async () => {
    if (!this.state.invoicingEmail || this.state.invoicingEmail === "") {
      alert("Please fill invoicing email address");
      return;
    }

    const data = {
      email: this.state.invoicingEmail,
    };

    await companyApi.updateInvoice(this.state.companyData?.id, data);
    this.setState({
      editEmail: false,
    });
  };

  handleEditEmail = () => {
    this.setState({
      editEmail: true,
      invoicingEmail:
        this.state.invoicingEmail === "N/A" ? "" : this.state.invoicingEmail,
    });
  };

  handleSendInvoice = async () => {
    this.setState({
      invoiceSentMsg: "",
    });

    const data = {
      services: this.state.extraServiceNewData,
      status: "confirmed",
      email: this.state.invoicingEmail,
    };
    await companyApi.updateInvoice(this.state.companyData?.id, data);

    this.setState({
      invoiceSentMsg: "Invoice sent to billing email address",
      extraServiceNewData: [],
    });
  };

  handleGetInvoice = async ({ companyID, selectedMonth, selectedYear }) => {
    if (!selectedMonth) {
      alert("Please select a month");
      return;
    }

    if (selectedYear === "") {
      alert("Please select year");
      return;
    }

    this.setState({
      isFetching: true,
      msgCompany: "Patientez svp...",
      invoiceData: null,
    });
    const response = await companyApi.getCompanyInvoice(
      companyID,
      selectedMonth.value,
      selectedYear.getFullYear()
    );

    if (response.error) {
      this.setState({
        isFetching: false,
        msgCompany: "Aucune facture trouvée pour cette période.",
      });
    } else {
      let data = response.data;

      if (data && data.length !== 0) {
        let invoiceInfos = data.details;
        let totalPrice = 0;
        let invoiceData = [];
        let invoiceExtraData = [];
        let priceRangeList = [];

        if (invoiceInfos?.tests?.length > 0) {
          // eslint-disable-next-line
          invoiceInfos?.tests?.map((establishmentInvoice) => {
            establishmentInvoice["total"] =
              // eslint-disable-next-line
              invoiceInfos?.isRanged != 1
                ? (
                    parseFloat(establishmentInvoice.count) *
                    parseFloat(establishmentInvoice.price)
                  ).toFixed(2)
                : "";
            totalPrice =
              // eslint-disable-next-line
              invoiceInfos?.isRanged != 1
                ? parseFloat(totalPrice) +
                  parseFloat(establishmentInvoice.total)
                : 0;
            invoiceData.push(establishmentInvoice);
          });
        }
        if (invoiceInfos?.tests?.length > 0) {
          // eslint-disable-next-line
          invoiceInfos?.priceRangeList?.map((data) => {
            priceRangeList.push(data);
          });
        }
        if (invoiceInfos?.extraServices?.length > 0) {
          // eslint-disable-next-line
          invoiceInfos?.extraServices?.map((extra) => {
            invoiceExtraData.push(extra);
          });
        }
        priceRangeList = this.setState({
          invoiceData: invoiceData,
          extraServicesData: invoiceExtraData,
          companyData: data,
          invoicingEmail:
            data.email == null ? data?.details?.tests[0]?.email : data.email,
          priceRangeList: priceRangeList,
          totalAmountInformations: {
            subTotal: data.totalAmount?.toFixed(2),
            totalPrice: data.totalAmount?.toFixed(2),
            tva: (data.totalAmount * (parseFloat(data.tax) * 0.01)).toFixed(2),
            total: data.finalAmount?.toFixed(2),
          },
        });

        this.setState({ isFetching: false, msgCompany: "" });
      } else {
        this.setState({
          isFetching: false,
          msgCompany: "Aucune facture trouvée pour cette période.",
        });
      }
    }
  };

  handleDisplayExtraServiceForm = () => {
    this.setState({
      addExtraService: !this.state.addExtraService,
    });
  };

  handleSubmitExtraServiceForm = () => {
    const { extraService } = this.state;
    let totalPrice;
    let tax;

    if (!extraService.name || extraService.name === "") {
      alert("Please fill the name of extra service");
      return;
    }

    if (!extraService.qty || extraService.qty === "") {
      alert("Please fill the quantity of extra service");
      return;
    }

    if (!extraService.price || extraService.price === "") {
      alert("Please fill the price of extra service");
      return;
    }

    totalPrice = (
      this.state.companyData.totalAmount + parseFloat(extraService.amount)
    ).toFixed(2);
    tax = (parseFloat(this.state.companyData?.tax) * 0.01).toFixed(2);

    this.setState((prevState) => ({
      extraServicesData: [...prevState.extraServicesData, extraService],
      extraServiceNewData: [...prevState.extraServiceNewData, extraService],
      totalAmountInformations: {
        subTotal: totalPrice,
        totalPrice: totalPrice,
        tva: (totalPrice * tax).toFixed(2),
        total: parseFloat(totalPrice) + totalPrice * tax,
      },
      addExtraService: false,
      //extraService: null
    }));
  };

  handleExtraServiceFormChange = (e) => {
    this.setState((prevState) => ({
      extraService: {
        ...prevState.extraService,
        [e.target.name]: e.target.value,
      },
    }));

    if (
      this.state.extraService?.qty !== "" &&
      this.state.extraService?.price !== ""
    ) {
      this.setState((prevState) => ({
        extraService: {
          ...prevState.extraService,
          amount: (
            parseFloat(prevState.extraService.qty) *
            parseFloat(prevState.extraService.price)
          ).toFixed(2),
        },
      }));
    }
  };

  render() {
    const {
      months,
      selectedMonth,
      selectedYear,
      invoiceData,
      establishmentTableHeader,
      totalAmountInformations,
      editEmail,
      invoicingEmail,
      invoiceSentMsg,
      extraServicesHeader,
      addExtraService,
      extraService,
      extraServicesData,
    } = this.state;

    return (
      <LayoutPageComponent>
        <div className="filters-container">
          <div className="filterControlContainer">
            <p> Select month : </p>
            <Select
              value={selectedMonth}
              onChange={this.handleMonthChange}
              options={months}
            />
          </div>
          <div className="filterControlContainer">
            <p> Select year : </p>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                views={["year"]}
                value={selectedYear}
                onChange={this.handleYearChange}
              />
            </MuiPickersUtilsProvider>
          </div>
          <div className="filterControlContainer">
            <Button
              variant="contained"
              color="primary"
              onClick={() => this.handleGetInvoice(this.state)}
            >
              Generate
            </Button>
          </div>
        </div>

        <p>{this.state.msgCompany}</p>
        <p>
          {invoiceData && invoiceData.length === 0
            ? "No reccord for the selected month"
            : ""}
        </p>
        {invoiceData && invoiceData.length !== 0 && (
          <Fragment>
            <p className="title">{this.state.companyData.invoiceName}</p>
            <BillingCompanyDetailsTableComponent
              data={this.state.companyData}
            />
            <DataTable
              columns={establishmentTableHeader}
              data={invoiceData}
              wrap={true}
            />
            <br />

            {this.state.companyData.details.isRanged === 1 ? (
              <Table aria-label="caption table">
                <caption></caption>
                <TableHead>
                  <TableRow>
                    <TableCell>range</TableCell>
                    <TableCell>Prix de vente</TableCell>
                    <TableCell>Quantité</TableCell>
                    <TableCell>total</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.priceRangeList.map((row) => {
                    return (
                      <TableRow key={row.companyID}>
                        <TableCell>{row.range}</TableCell>
                        <TableCell>{row.range_price}</TableCell>
                        <TableCell>{row.count}</TableCell>
                        <TableCell>{row.range_price * row.count}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            ) : (
              ""
            )}

            <p className="title">Extra services</p>
            <DataTable
              columns={extraServicesHeader}
              data={extraServicesData}
              wrap={true}
            />
            <br />
            {addExtraService && (
              <Fragment>
                <input
                  className="input-large"
                  name={"name"}
                  placeholder={"Nom"}
                  type={"text"}
                  value={extraService?.name}
                  onChange={this.handleExtraServiceFormChange}
                />
                <br />
                <input
                  className="input-large"
                  name={"qty"}
                  placeholder={"Quantité"}
                  type={"number"}
                  value={extraService?.qty}
                  onChange={this.handleExtraServiceFormChange}
                />
                <br />
                <input
                  className="input-large"
                  name={"price"}
                  placeholder={"Prix de vente"}
                  type={"number"}
                  value={extraService?.price}
                  onChange={this.handleExtraServiceFormChange}
                />
                <br />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={this.handleSubmitExtraServiceForm}
                  >
                    Add
                  </Button>
                  &nbsp;&nbsp;&nbsp;
                  <Button
                    variant="contained"
                    color="default"
                    onClick={this.handleDisplayExtraServiceForm}
                  >
                    Cancel
                  </Button>
                </div>
              </Fragment>
            )}

            {!addExtraService && (
              <Button
                variant="contained"
                color="primary"
                onClick={this.handleDisplayExtraServiceForm}
              >
                Add new extra service
              </Button>
            )}

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                width: "100%",
                height: "max-content",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-end",
                  width: "max-content",
                  height: "max-content",
                  marginRight: "200px",
                }}
              >
                <p>Sous-total: {totalAmountInformations.subTotal}</p>
                <p>Prix total: {totalAmountInformations.totalPrice}</p>
                <p>Taxe: {totalAmountInformations.tva}</p>
                <p>Total: {totalAmountInformations.total}</p>
              </div>
            </div>
            <label>
              Email address for billing : &nbsp;&nbsp;&nbsp;
              {editEmail ? (
                <input
                  className="input-large"
                  placeholder={"email"}
                  type={"email"}
                  value={invoicingEmail}
                  onChange={this.handleInvoicingEmailChange}
                />
              ) : (
                <span>{invoicingEmail}</span>
              )}
              &nbsp;&nbsp;&nbsp;
              {editEmail ? (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.handleUpdateEmail}
                >
                  Update
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.handleEditEmail}
                >
                  Edit
                </Button>
              )}
            </label>
            <br />
            <br />
            <Button
              variant="contained"
              color="primary"
              onClick={() => this.handleSendInvoice(this.state)}
            >
              Send invoice
            </Button>
            <p>{invoiceSentMsg}</p>
          </Fragment>
        )}
      </LayoutPageComponent>
    );
  }
}

export default Invoicing;
