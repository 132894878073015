import React, { useState, useEffect } from "react";
import * as manufacturerApi from "../../api/Manufacturer";
import IncubationComponent from "../../components/IncubationComponent";
import "../../style/style.scss";
import LayoutPageComponent from "../../components/LayoutPageComponent";

const UpdateManufacturerTest = (props) => {
  const manufacturerTestID = props.history.location["state"]
    ? props.history.location["state"]
    : null;

  const [isFetching, setIsFetching] = useState(false);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [brand, setBrand] = useState("");
  const [RDT_type, setRDT_type] = useState("");
  const [manufacturer, setManufacturer] = useState("");
  const [disease, setDisease] = useState("");
  const [incubation, setIncubation] = useState("");
  const [lines, setLines] = useState("");
  const [reference, setReference] = useState("");
  // eslint-disable-next-line
  const [available, setAvailable] = useState(true);
  const [msg, setMsg] = useState("");

  useEffect(() => {
    setIsFetching(true);
    setMsg("Patientez svp...");

    async function fetchData() {
      const response = await manufacturerApi.getManufacturerTest(
        manufacturerTestID
      );

      if (response.error) {
        setIsFetching(false);
        setMsg("Aucun test trouvé.");
      } else {
        const data = response.data;
        setIsFetching(false);
        setMsg("");
        setName(data.name);
        setDescription(data.description);
        setBrand(data.brand);
        setRDT_type(data.RDT_type);
        setManufacturer(data.manufacturer);
        setDisease(data.disease);
        setIncubation(data.incubation);
        setLines(JSON.stringify(data.lines));
        setReference(data.reference);
      }
    }

    fetchData();
    // eslint-disable-next-line
  }, []);

  //Create manufacturer test when form is submitted
  const handleSubmit = async (e) => {
    setMsg("");
    e.preventDefault();
    if (
      name !== "" &&
      description !== "" &&
      brand !== "" &&
      RDT_type !== "" &&
      manufacturer !== "" &&
      disease !== "" &&
      incubation?.min !== "" &&
      incubation?.max !== "" &&
      incubation?.nominal !== "" &&
      lines !== "" &&
      name != null &&
      description != null &&
      brand != null &&
      RDT_type != null &&
      manufacturer != null &&
      disease != null &&
      incubation?.min != null &&
      incubation?.max != null &&
      incubation?.nominal != null &&
      lines != null &&
      reference !== "" &&
      reference != null
    ) {
      const manufacturerTest = {
        name: name,
        display_name: name,
        description: description,
        brand: brand,
        deki_device: "",
        RDT_type: RDT_type,
        manufacturer: manufacturer,
        disease: disease,
        incubation: {
          min: parseInt(incubation.min.trim()),
          max: parseInt(incubation.max.trim()),
          nominal: parseInt(incubation.nominal.trim()),
        },
        lines: JSON.parse(lines),
        nb_line: Object.keys(JSON.parse(lines)).length,
        reference: reference,
        available: available,
      };
      const response = await manufacturerApi.updateManufacturerTest(
        manufacturerTestID,
        manufacturerTest
      );
      setMsg(
        response.error
          ? "La mise à jour de ce test a échoué."
          : "La mise à jour de ce test s'est éffectué avec succès."
      );
    } else {
      setMsg("Remplissez tous les champs svp.");
    }
  };

  return (
    <LayoutPageComponent>
      <p className="title">Modification d’un test</p>
      <p>{msg}</p>
      {!isFetching && (
        <form className="form" onSubmit={handleSubmit}>
          <div>
            <p>Nom du test</p>
            <input
              type="text"
              name="name"
              className="input-large"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div>
            <p>Description</p>
            <input
              type="text"
              name="description"
              className="input-large"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>
          <div>
            <p>Marque</p>
            <input
              type="text"
              name="brand"
              className="input-large"
              value={brand}
              onChange={(e) => setBrand(e.target.value)}
            />
          </div>
          <div>
            <p>Type de test légal</p>
            <input
              type="text"
              name="RDT_type"
              className="input-large"
              value={RDT_type}
              onChange={(e) => setRDT_type(e.target.value)}
            />
          </div>
          <div>
            <p>Fabricant</p>
            <input
              type="text"
              name="manufacturer"
              className="input-large"
              value={manufacturer}
              onChange={(e) => setManufacturer(e.target.value)}
            />
          </div>
          <div>
            <p>Pathologie</p>
            <input
              type="text"
              name="disease"
              className="input-large"
              value={disease}
              onChange={(e) => setDisease(e.target.value)}
            />
          </div>
          <p>Incubation</p>
          <IncubationComponent
            incubation={setIncubation}
            incubationData={incubation}
          />
          <div>
            <p>Lines</p>
            <textarea
              name="lines"
              className="input-large"
              value={lines}
              onChange={(e) => setLines(e.target.value)}
            ></textarea>
          </div>
          <div>
            <p>Reference</p>
            <input
              type="text"
              name="reference"
              className="input-large"
              value={reference}
              onChange={(e) => setReference(e.target.value)}
            />
          </div>
          <div>
            <button type="submit" className="btnMain">
              Valider
            </button>
          </div>
        </form>
      )}
    </LayoutPageComponent>
  );
};

export default UpdateManufacturerTest;
