import * as requests from '../utils/HttpRequests';
import config from '../config/load_config';

const apiUrl = process.env.REACT_APP_API_BASE_URL + config.BASE_ADMIN;
const endPointManufacturerTestList = config.API.MANUFACTURER;
const endPointManufacturerTestDetails = config.API.MANUFACTURER_DETAILS;

//Get list of manufacturer test
export async function getManufacturerTests(){
    const response = await requests.doGet(apiUrl + endPointManufacturerTestList, {
        "content-type": "application/json",
        "auth": localStorage.getItem("token") 
    });
    return response;
}

//Get a manufacturer test
export async function getManufacturerTest(manufacturerTestID){
    const response = await requests.doGet(apiUrl + endPointManufacturerTestDetails.replace(":id", manufacturerTestID), {
        "content-type": "application/json",
        "auth": localStorage.getItem("token") 
    });
    return response;
}

//Create a manufacturer test
export async function createManufacturerTest(manufacturerData){
    const response = await requests.doPost(apiUrl + endPointManufacturerTestList, manufacturerData, {
        "content-type": "application/json",
        "auth": localStorage.getItem("token") 
    });
    return response;
}

//Update a manufacturer test
export async function updateManufacturerTest(manufacturerTestID, manufacturerData){
    const response = await requests.doPut(apiUrl + endPointManufacturerTestDetails.replace(":id", manufacturerTestID), manufacturerData, {
        "content-type": "application/json",
        "auth": localStorage.getItem("token") 
    });
    return response;
}
