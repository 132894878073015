const CompanyTypeComponent = (props) => {

    const { name, setCompanyType, className, label } = props;

    const handleValueChange = (e) => {
        setCompanyType(e.target.value);
    }

    return(
        <div>
            <p>{label}</p>
            <select name={name} className={className} onChange={handleValueChange} >
                <option value="">Choix du type d'entreprise</option>
                <option value="labo">Labo</option>
                <option value="pharma">Pharma</option>
                <option value="clinic">Clinic</option>
                <option value="hospital">Hospital</option>
                <option value="liberal">Libéral</option>
                <option value="occupational_medicine">Occupational Medicine</option>
                <option value="service_company">Société de Service</option>
            </select>
        </div>
    );
}

export default CompanyTypeComponent;