import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal)
function showAlert() {


    return MySwal.fire({
        width: 500,
        title: "<span class='abort-modal-title'>Déconnexion</span>",
        text: "Êtes-vous sûr de vouloir vous déconnecter ?",
        icon: 'warning',
        backdrop: 'rgba(255, 255, 255, 0.5)',
        showCancelButton: true,
        cancelButtonText: "Non",
        confirmButtonText: "Oui"
    }).then((result) => {
        if (result.isConfirmed) {
            window.location.href = '/';
        }
    })

}

export default showAlert;