const EtablishmentTypeComponent = (props) => {

    const { name, setEtablishmentType, className, label } = props;

    const handleValueChange = (e) => {
        setEtablishmentType(e.target.value);
    }

    return(
        <div>
            <p>{label}</p>
            <select name={name} className={className} onChange={handleValueChange} >
                <option value="">Choix du type d'établissement</option>
                <option value="labo_pre_analytique">Labo pré analytique</option>
                <option value="pharmacy">Pharmacie</option>
            </select>
        </div>
    );
}

export default EtablishmentTypeComponent;