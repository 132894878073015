import React, { useEffect, useState } from "react";
import * as companyApi from "../api/Company";
import { Link } from "react-router-dom";

const FacturationDetailsTableComponentNoRange = (props) => {
  const [priceData, setPriceData] = useState([]);
  const [loading, setLoading] = useState(true);
  // eslint-disable-next-line
  useEffect(async () => {
    const response = await companyApi.getPriceEstablishment(
      props.companyId,
      props.establishment.id
    );

    setPriceData(response.data);
    setLoading(false);
    // eslint-disable-next-line
  }, [props.establishment.id]);

  const testExists = (testID) => {
    return priceData.some(function (el) {
      // eslint-disable-next-line
      return el.testTypeId == testID && el.price > 0 && el.isRanged === 0;
    });
  };

  return (
    <>
      {!loading && priceData?.length > 0 ? (
        <table className="table-recap">
          <p>
            <b>Facturation</b>
          </p>

          <tr>
            <td>
              <p className="recap-label">Establishment ID : </p>
            </td>
            <td>
              <p className="label">{props.establishment?.id}</p>
            </td>
          </tr>
          <tr>
            <td>
              <p className="recap-label">Establishment name : </p>
            </td>
            <td>
              <p className="label">{props.establishment?.name}</p>
            </td>
          </tr>
          <br />
          {testExists(1) && (
            <>
              <tr>
                <td>
                  <p className="recap-label">Type de test : </p>
                </td>
                <td>
                  <p className="label" style={{ fontWeight: "bold" }}>
                    Antigen
                  </p>
                </td>
              </tr>
              <tr className="recap-row">
                <td>
                  <p className="recap-label">Ranged : </p>
                </td>
                <td>
                  <p className="label">No</p>
                </td>
              </tr>

              <tr className="recap-row">
                <td>
                  <p className="recap-label">Price : </p>
                </td>

                {priceData?.length > 0 &&
                  priceData.map((data) => {
                    return data.testTypeId === 1 && <td>{data.price} €</td>;
                  })}
              </tr>
              <tr className="recap-row">
                <td>
                  <p className="recap-label">Tax : </p>
                </td>
                <td>
                  {priceData?.length > 0 &&
                    priceData.map((data) => {
                      return (
                        data.testTypeId === 1 && (
                          <p className="label">{data.tax}</p>
                        )
                      );
                    })}
                </td>
              </tr>
              <br />
              <br />
            </>
          )}
          {testExists(2) && (
            <>
              <tr>
                <td>
                  <p className="recap-label">Type de test : </p>
                </td>
                <td>
                  <p className="label" style={{ fontWeight: "bold" }}>
                    RT-PCR
                  </p>
                </td>
              </tr>
              <tr className="recap-row">
                <td>
                  <p className="recap-label">Ranged : </p>
                </td>
                <td>
                  <p className="label">No</p>
                </td>
              </tr>
              <tr className="recap-row">
                <td>
                  <p className="recap-label">Price : </p>
                </td>
                <td>
                  {priceData.length > 0 &&
                    priceData.map((data) => {
                      return (
                        data.testTypeId === 2 && (
                          <p className="label">{data.price} €</p>
                        )
                      );
                    })}
                </td>
              </tr>
              <tr className="recap-row">
                <td>
                  <p className="recap-label">Tax : </p>
                </td>
                <td>
                  {priceData?.length > 0 &&
                    priceData.map((data) => {
                      return (
                        data.testTypeId === 2 && (
                          <p className="label">{data.tax}</p>
                        )
                      );
                    })}
                </td>
              </tr>
              <br />
              <br />
            </>
          )}

          {testExists(3) && (
            <>
              <tr>
                <td>
                  <p className="recap-label">Type de test : </p>
                </td>
                <td>
                  <p className="label" style={{ fontWeight: "bold" }}>
                    PCR Premium
                  </p>
                </td>
              </tr>
              <tr className="recap-row">
                <td>
                  <p className="recap-label">Ranged : </p>
                </td>
                <td>
                  <p className="label">No</p>
                </td>
              </tr>
              <tr className="recap-row">
                <td>
                  <p className="recap-label">Price : </p>
                </td>
                <td>
                  {priceData.length > 0 &&
                    priceData.map((data) => {
                      return (
                        data.testTypeId === 3 && (
                          <p className="label">{data.price} €</p>
                        )
                      );
                    })}
                </td>
              </tr>
              <tr className="recap-row">
                <td>
                  <p className="recap-label">Tax : </p>
                </td>
                <td>
                  {priceData.length > 0 &&
                    priceData.map((data) => {
                      return (
                        data.testTypeId === 3 && (
                          <p className="label">{data.tax}</p>
                        )
                      );
                    })}
                </td>
              </tr>
            </>
          )}

          <br />
        </table>
      ) : (
        ""
      )}
      <Link
        to={{
          pathname: "/company/price/establishment/modify",
          state: {
            companyId: props.companyId,
            establishment: props.establishment,
          },
        }}
      >
        <button
          style={{
            display: "inline-block",
            padding: "0.25rem 1rem",
            backgroundColor: "#4867d8",
            boxShadow: "0 0 20px -10px rgb(0 0 0 / 50%)",
            color: "#ffffff",
            fontWeight: "bold",
            border: "0",
            width: "200px",
            height: "40px",
            borderRadius: "10px",
          }}
        >
          Modifier
        </button>
      </Link>
    </>
  );
};

export default FacturationDetailsTableComponentNoRange;
