import React, { useEffect, useState } from "react";

import * as companyApi from "../api/Company";

const FacturationDetailsTableComponent = React.memo((props) => {
  const [priceData, setPriceData] = useState([]);
  // eslint-disable-next-line
  useEffect(async () => {
    try {
      const response = await companyApi.getPrice(props.data.id);
      setPriceData(response.data);
    } catch (e) {}
    // eslint-disable-next-line
  }, [props.data.id]);

  const testExists = (testID) => {
    return priceData.some(function (el) {
      // eslint-disable-next-line
      return el.testTypeId == testID;
    });
  };

  return (
    <>
      {priceData.length > 0 && (
        <table className="table-recap">
          <p>
            <b>Facturation</b>
          </p>
          <tr>
            <td>
              <p className="recap-label">Company ID : </p>
            </td>
            <td>
              <p className="label">{props.data.id}</p>
            </td>
          </tr>
          <tr>
            <td>
              <p className="recap-label">Company name : </p>
            </td>
            <td>
              <p className="label">{props.data.name}</p>
            </td>
          </tr>

          <br />
          {testExists(1) && (
            <>
              <tr>
                <td>
                  <p className="recap-label">Type de test : </p>
                </td>
                <td>
                  <p className="label" style={{ fontWeight: "bold" }}>
                    Antigenic
                  </p>
                </td>
              </tr>
              <tr className="recap-row">
                <td>
                  <p className="recap-label">Ranged : </p>
                </td>
                <td>
                  <p className="label">Yes</p>
                </td>
              </tr>

              <table style={{ width: "31vw", textAlign: "left" }}>
                <tr>
                  <th style={{ paddingLeft: "2rem" }}>Range</th>
                  <th>Price</th>
                  <th>Tax</th>
                </tr>

                {priceData.length > 0 &&
                  priceData.map((data) => {
                    return (
                      // eslint-disable-next-line
                      data.testTypeId == 1 && (
                        <tr>
                          <td>{data.range}</td>
                          <td>{data.rangePrice} €</td>
                          <td>{data.tax}</td>
                        </tr>
                      )
                    );
                  })}
              </table>
              <br />
              <br />
            </>
          )}

          {testExists(2) && (
            <>
              <tr>
                <td>
                  <p className="recap-label">Type de test : </p>
                </td>
                <td>
                  <p className="label" style={{ fontWeight: "bold" }}>
                    RT-PCR
                  </p>
                </td>
              </tr>
              <tr className="recap-row">
                <td>
                  <p className="recap-label">Ranged : </p>
                </td>
                <td>
                  <p className="label">Yes</p>
                </td>
              </tr>

              <table style={{ width: "31vw", textAlign: "left" }}>
                <tr>
                  <th style={{ paddingLeft: "2rem" }}>Range</th>
                  <th>Price</th>
                  <th>Tax</th>
                </tr>

                {priceData.length > 0 &&
                  priceData.map((data) => {
                    return (
                      // eslint-disable-next-line
                      data.testTypeId == 2 && (
                        <tr>
                          <td>{data.range}</td>
                          <td>{data.rangePrice} €</td>
                          <td>{data.tax}</td>
                        </tr>
                      )
                    );
                  })}
              </table>
              <br />
              <br />
            </>
          )}

          {testExists(3) && (
            <>
              <tr>
                <td>
                  <p className="recap-label">Type de test : </p>
                </td>
                <td>
                  <p className="label" style={{ fontWeight: "bold" }}>
                    PCR Premium
                  </p>
                </td>
              </tr>
              <tr className="recap-row">
                <td>
                  <p className="recap-label">Ranged : </p>
                </td>
                <td>
                  <p className="label">Yes</p>
                </td>
              </tr>

              <table style={{ width: "31vw", textAlign: "left" }}>
                <tr>
                  <th style={{ paddingLeft: "2rem" }}>Range</th>
                  <th>Price</th>
                  <th>Tax</th>
                </tr>

                {priceData.length > 0 &&
                  priceData.map((data) => {
                    return (
                      // eslint-disable-next-line
                      data.testTypeId == 3 && (
                        <tr>
                          <td>{data.range}</td>
                          <td>{data.rangePrice} €</td>
                          <td>{data.tax}</td>
                        </tr>
                      )
                    );
                  })}
              </table>
              <br />
              <br />
            </>
          )}
        </table>
      )}
    </>
  );
});

export default FacturationDetailsTableComponent;
