import React from "react";
import * as manufacturerApi from "../../api/Manufacturer";
import ManufacturerListTable from "../../components/ManufacturerTestListTableComponent";
import CustomLink from "../../components/CustomLink";
import "../../style/style.scss";
import LayoutPageComponent from "../../components/LayoutPageComponent";

class ListManufacturer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      msg: "",
      isFetching: false,
      data: [],
    };
  }

  async componentDidMount() {
    this.setState({ isFetching: true, msg: "Patientez svp..." });
    const response = await manufacturerApi.getManufacturerTests();

    if (response.error) {
      this.setState({ isFetching: false, msg: "Aucun test trouvé." });
    } else {
      this.setState({ data: response.data, isFetching: false, msg: "" });
    }
  }

  render() {
    //Get user role
    const isAdmin = JSON.parse(localStorage.getItem("user")).key;

    return (
      <LayoutPageComponent>
        <p className="title">Liste des tests</p>
        {isAdmin && (
          <CustomLink
            className="btnMain"
            tag="div"
            to="/create-manufacturer-test"
          >
            <p className="btnText">Créer un test</p>
          </CustomLink>
        )}
        <p>{this.state.msg}</p>
        {!this.state.isFetching && (
          <ManufacturerListTable data={this.state.data} />
        )}
      </LayoutPageComponent>
    );
  }
}

export default ListManufacturer;
