import React, { useState } from "react";
import * as priceApi from "../../api/Price";
import LayoutPageComponent from "../../components/LayoutPageComponent";

const UpdatePrice = (props) => {
  const priceString = props.history.location["state"]
    ? props.history.location["state"]
    : null;
  const priceObject = priceString ? JSON.parse(priceString) : null;
  const price = priceObject?.price;
  // eslint-disable-next-line
  const establishmentId = priceObject?.establishmentId;

  let testTypeName;
  if (price.testTypeId === 1) {
    testTypeName = "Antigen";
  } else if (price.testTypeId === 2) {
    testTypeName = "RT-PCR";
  } else {
    testTypeName = "PCR Premium";
  }
  // const [selectedFile, setSelectedFile] = useState();
  // eslint-disable-next-line
  const [isSelected, setIsSelected] = useState(false);
  // eslint-disable-next-line
  const [testType, setTestType] = useState(testTypeName || "");
  const [amount, setAmount] = useState(price?.amount || "");
  const [serviceCharge, setServiceCharge] = useState(
    price?.serviceCharge || ""
  );
  const [total, setTotal] = useState(price?.total || "");

  const [message, setMessage] = useState("");

  // eslint-disable-next-line
  const changeHandler = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size > 1024000) alert("File exeed limit : 1MB");
      else {
        // setSelectedFile(event.target.files[0]);
        setIsSelected(true);
      }
    }
  };

  // eslint-disable-next-line
  const reset = () => {
    // setSelectedFile(null);
    setIsSelected(false);
    setAmount("");
    setServiceCharge("");
    setTotal("");
    setMessage("");
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const postData = {
      amount,
      serviceCharge,
      total,
    };

    priceApi
      .updatePrice(price?.id, postData)
      .then((res) => setMessage("price updated"))
      .catch((err) => console.error("error:" + err));
  };

  return (
    <LayoutPageComponent>
      <p className="title">
        <b>Modifier</b>
      </p>

      <form className="form" onSubmit={handleSubmit}>
        <div>
          <p>Test Type</p>
          <input
            readOnly
            type="text"
            name="testType"
            value={testType}
            className="input-large"
          />
        </div>
        <div>
          <p>Amount</p>
          <input
            required
            type="number"
            name="amount"
            value={amount}
            className="input-large"
            onChange={(e) => setAmount(e.target.value)}
          />
        </div>
        <div>
          <p>Service Charge</p>
          <input
            required
            type="number"
            name="serviceCharge"
            value={serviceCharge}
            className="input-large"
            onChange={(e) => {
              setServiceCharge(e.target.value);
              setTotal(Number(e.target.value) + Number(amount));
            }}
          />
        </div>
        <div>
          <p>Total</p>
          <input
            required
            readOnly
            type="number"
            name="total"
            value={total}
            className="input-large"
            onChange={(e) => setTotal(e.target.value)}
          />
        </div>

        <div>
          <button type="submit" className="btnMain">
            Modifier
          </button>
          <p style={{ paddingLeft: "30px" }}>
            <i>{message}</i>
          </p>
        </div>
      </form>
    </LayoutPageComponent>
  );
};

export default UpdatePrice;
