//This Hook represent actions zone for home page
import React from "react";
import ButtonActionComponent from "./ButtonActionComponent";
import iconCompany from "../assets/icon-societes/icon-societes@3x.png";
import iconUser from "../assets/icon-utilisateurs/icon-utilisateurs@3x.png";
import iconPatient from "../assets/icon-patients/icon-patients@3x.png";
import iconExport from "../assets/icon-patients/iconExport.png";
import iconImport from "../assets/icon-patients/icon-import.svg";
import iconResult from "../assets/icon-result/icon-result@3x.png";
import iconPacking from "../assets/packing/packinglist.svg";
import iconPayment from "../assets/icon-payment/credit-card.svg";

const HomeActionsComponent = (props) => {
  //Get user role
  // const [isAdmin, setIsAdmin] = useState(user.key);
  var isAdmincheck = localStorage.getItem("isAdmin");
  var isOperator = localStorage.getItem("isOperator");
  const isPaymentEnable = JSON.parse(localStorage.getItem("user")).payment;

  var canImport = localStorage.getItem("canImport");
  return (
    <div className="actions-container">
      <div className="buttons-action-container">
        {isAdmincheck === "true" && (
          <ButtonActionComponent
            icon={iconCompany}
            text="Sociétés"
            pathname="/list-company"
          />
        )}

        {isAdmincheck === "true" && (
          <ButtonActionComponent
            icon={iconCompany}
            text="Établissements"
            pathname="/list-etablishment"
          />
        )}

        {isAdmincheck === "true" && (
          <ButtonActionComponent
            icon={iconUser}
            text="Utilisateurs"
            pathname="/create-user"
          />
        )}

        <ButtonActionComponent
          icon={iconPatient}
          text="Patients"
          pathname="/list-patient"
        />
        {isOperator === "false" && (
          <ButtonActionComponent
            icon={iconExport}
            text="Outil d'export"
            pathname="/export-patient"
          />
        )}
        {canImport === "true" && isOperator === "false" && (
          <ButtonActionComponent
            icon={iconImport}
            text="Import Utility"
            pathname="/import-patient"
          />
        )}
        {isAdmincheck === "true" && (
          <ButtonActionComponent
            icon={iconResult}
            text="Resultats"
            pathname="/list-result"
          />
        )}

        {isAdmincheck === "true" && (
          <ButtonActionComponent
            icon={iconCompany}
            text="Stats"
            pathname="/stats"
          />
        )}

        {(isOperator === "true" || isOperator === "false") && (
          <ButtonActionComponent
            icon={iconPacking}
            text="Packing"
            pathname="/packing-list"
          />
        )}
        {(isAdmincheck === "true" || isPaymentEnable === "yes") &&
          isOperator === "false" && (
            <ButtonActionComponent
              icon={iconPayment}
              text="Paiement"
              pathname="/payment"
            />
          )}
      </div>
    </div>
  );
};

export default HomeActionsComponent;
