import React from 'react';
import ManufacturerDetailsTable from "./ManufacturerTestDetailsComponent";
import CassetteDetailsComponent from "./CassetteDetailsComponent";
import ResultTestDetailsComponent from "./ResultTestDetailsComponent";
import ResultNetworkImage from "./ResultNetworkImage";

const ResultDetailsComponent = (props) => {

    return (<>
        <div>
            <p className="table-title">Résultat :</p>
            <ResultTestDetailsComponent data={props.data} />

            <p className="table-title">Cassette :</p>
            <CassetteDetailsComponent data={props.data.cassette} />

            <p className="table-title">Manufacturer Test :</p>
            <ManufacturerDetailsTable data={props.data.manufacturerTest} />
        </div>
        <div>
            <ResultNetworkImage resultId={props.data.id}/>
        </div>
    </>);
}

export default ResultDetailsComponent;