import React from "react";
import Datatable from "./DatatableComponent";
import * as DateUtils from "../utils/DateUtils";
import "../style/style.scss";

const SignatoryListTable = (props) => {
  //Get column from ListUserHeader in pages configuration
  const columns = [
    {
      Header: "Titre",
      accessor: "title",
      isLink: false,
    },
    {
      Header: "Prénom",
      accessor: "firstName",
      isLink: false,
    },
    {
      Header: "Nom",
      accessor: "lastName",
      isLink: false,
    },
    {
      Header: "Email",
      accessor: "email",
      isLink: false,
    },
    {
      Header: "Nom du fichier de la signature",
      accessor: "filename",
      isLink: false,
    },
    {
      Header: "Date de création",
      accessor: "createdAt",
      isLink: false,
    },
    {
      Header: "statut",
      accessor: "status",
      isLink: false,
    },
    {
      Header: "Action",
      accessor: "edit.toShow",
      isLink: true,
    },
  ];

  let userData = [];
  let establishmentId = props.establishmentId;

  props.data.forEach((element) => {
    const dataForEdit = {
      establishmentId: establishmentId,
      signatory: element,
    };

    userData.push({
      title: element.title,
      firstName: element.firstName,
      lastName: element.lastName,
      email: element.email,
      filename: element.filename,
      createdAt: DateUtils.dateToFrenchFormat(element.createdAt),
      status: element?.isActive === false ? "Innactive" : "Active",
      edit: {
        toShow: "Modifier",
        linkTo: JSON.stringify(dataForEdit),
        pathName: "/update-signatory",
      },
    });
  });

  return <Datatable columns={columns} data={userData} />;
};

export default SignatoryListTable;
