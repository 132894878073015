import React from 'react';
import * as DateUtils from '../utils/DateUtils';
import CustomLink from './CustomLink';

const CompanyDetailsTableComponent = (props) => {
    return (
        <table className="table-recap">
            <tr className="recap-row">
                <td><p className="recap-label">Nom de la société</p></td>
                <td>
                    <CustomLink className="see-more-link" tag="a" to={{
                        "pathname": "/company-details",
                        "state": props.data.id
                    }}>
                        {props.data.name}
                    </CustomLink>
                </td>
            </tr>
            <tr className="recap-row">
                <td><p className="recap-label">ID Société</p></td>
                <td><p className="label">{props.data.id}</p></td>
            </tr>
            <tr className="recap-row">
                <td><p className="recap-label">No. SIREN</p></td>
                <td><p className="label">{props.data.administrativeNumber}</p></td>
            </tr>
            <tr className="recap-row">
                <td><p className="recap-label">Type de société</p></td>
                <td><p className="label">{props.data.type}</p></td>
            </tr>
            <tr className="recap-row">
                <td><p className="recap-label">Adresse</p></td>
                <td><p className="label">{props.data.address.street}</p></td>
            </tr>
            <tr className="recap-row">
                <td><p className="recap-label">Code postal</p></td>
                <td><p className="label">{props.data.address.zip}</p></td>
            </tr>
            <tr className="recap-row">
                <td><p className="recap-label">Ville</p></td>
                <td><p className="label">{props.data.address.city}</p></td>
            </tr>
            <tr className="recap-row">
                <td><p className="recap-label">Date de création</p></td>
                <td><p className="label">{DateUtils.dateToFrenchFormat(props.data.createdAt)}</p></td>
            </tr>
            <tr className="recap-row">
                <td><p className="recap-label">Date de modification</p></td>
                <td><p className="label">{DateUtils.dateToFrenchFormat(props.data.updatedAt)}</p></td>
            </tr>
        </table>

    );
}

export default CompanyDetailsTableComponent;