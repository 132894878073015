import React from "react";
import * as patientApi from "../../api/Patient";
import PatientDetailsTable from "../../components/PatientDetailsTableComponent";
import LayoutPageComponent from "../../components/LayoutPageComponent";

class NewPatientDetails extends React.Component {
  constructor(props) {
    super(props);

    this.patientIDNew = props.location.state.patientData
      ? props.location.state.patientData
      : null;

    this.state = {
      isFetching: false,
      msgPatient: this.patientData === null ? "Aucun patient sélectionné" : "",
      patientIDNew: this.patientIDNew !== null ? this.patientIDNew : "",
      patientDataNew: {},
    };
  }

  async componentWillMount() {
    this.setState({ isFetching: true, msgPatient: "Patientez svp..." });
    const responseNew = await patientApi.getPatient(this.state.patientIDNew);

    if (responseNew.error) {
      this.setState({ isFetching: false, msgPatient: "Aucun patient trouvée" });
    } else {
      var data = responseNew.data;
      this.setState({
        patientDataNew: data,
        isFetching: false,
        msgPatient: "",
      });
    }
  }

  render() {
    //Get user logged
    return (
      <LayoutPageComponent>
        <p className="title">ID Patient : {this.state.patientDataNew.id}</p>
        <p>{this.state.msgPatient}</p>
        {!this.state.isFetching && (
          <PatientDetailsTable data={this.state.patientDataNew} />
        )}
      </LayoutPageComponent>
    );
  }
}

export default NewPatientDetails;
