import React from "react";
import * as patientApi from "../../api/Patient";
import * as establishmentApi from "../../api/Etablishment";
import "../../style/style.scss";
// import DatePicker from "react-datepicker";
import Select from "react-select";
import DataTable from "react-data-table-component";
import Checkbox from "@material-ui/core/Checkbox";
import { CSVLink } from "react-csv";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns"; // import
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import ReactExport from "react-export-excel";
import moment from "moment";
import LayoutPageComponent from "../../components/LayoutPageComponent";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class ExportPatient extends React.Component {
  constructor(props) {
    super(props);
    var today = new Date();
    var startDateValueDateFormat = today.setDate(today.getDate());
    this.state = {
      msg: "",
      selectedDate: null,
      checkedB: false,
      isFetching: false,
      selectedEstablishment: null,
      startDate: startDateValueDateFormat,
      endDate: today,
      testTypeId: 2,
      selectedTestOption: null,
      selectedExportOption: null,
      selectedNationality: null,
      data: [],
      establishmentsData: [],
      excelData: null,

      tableData: null,
      tableDataCsvBackup: [],
      tableDataExcelBackup: [],
      tableColumns: [
        {
          name: "ID",
          selector: "ID",
          sortable: true,
        },
        {
          name: "RESULT",
          selector: "RESULT",
          sortable: true,
        },
        {
          name: "Date et heure de prélèvement",
          selector: "DATE AND TIME OF COLLECTION",
          sortable: true,
        },
        {
          name: "Etablissement",
          selector: "ESTABLISHMENT",
          sortable: true,
        },
        {
          name: "Numéro SS",
          selector: "SS NUMBER",
          sortable: true,
        },

        {
          name: "Type de test",
          selector: "TYPE OF TEST",
          sortable: true,
          right: true,
        },
        {
          name: "Prénom",
          selector: "FIRST NAME",
          sortable: true,
          right: true,
        },
        {
          name: "Nom",
          selector: "LAST NAME",
          sortable: true,
          right: true,
        },
        {
          name: "Genre",
          selector: "GENDER",
          sortable: true,
          right: true,
        },

        {
          name: "Code-barres du tube",
          selector: "TUBE_BARCODE",
          sortable: true,
          right: true,
        },

        {
          name: "Date de naissance",
          selector: "BIRTH DATE",
          sortable: true,
          right: true,
        },

        {
          name: "EMAIL",
          selector: "EMAIL",
          sortable: true,
          right: true,
        },

        {
          name: "Numéro de téléphone",
          selector: "PHONE NUMBER",
          sortable: true,
          right: true,
        },

        {
          name: "Adresse",
          selector: "ADDRESS NUMBER",
          sortable: true,
          right: true,
        },

        {
          name: "Code postal",
          selector: "POSTAL CODE",
          sortable: true,
          right: true,
        },

        {
          name: "Ville",
          selector: "CITY",
          sortable: true,
          right: true,
        },

        {
          name: "Pays",
          selector: "COUNTRY",
          sortable: true,
          right: true,
        },

        {
          name: "Hébergement",
          selector: "ACCOMODATION TYPE",
          sortable: true,
          right: true,
        },

        {
          name: "Raison du test",
          selector: "REASON OF TEST",
          sortable: true,
          right: true,
        },

        {
          name: "Professionnel de santé",
          selector: "HEALTH PROFESSIONAL -(APP)",
          sortable: true,
          right: true,
        },

        {
          name: "Symptômes",
          selector: "APSYM (APP)",
          sortable: true,
          right: true,
        },

        {
          name: "Facteurs de risque",
          selector: "RISKS (APP)",
          sortable: true,
          right: true,
        },
      ],
      table2Data: null,
      table2Columns: [
        {
          name: "NOM ELEVE",
          selector: "NOM ELEVE",
          sortable: true,
        },
        {
          name: "PRENOM ELEVE",
          selector: "PRENOM ELEVE",
          sortable: true,
        },

        {
          name: "DATE NAISSANCE",
          selector: "DATE NAISSANCE",
          sortable: true,
        },
        {
          name: "SEXE",
          selector: "SEXE",
          sortable: true,
          right: true,
        },
        {
          name: "ADRESSE",
          selector: "ADRESSE",
          sortable: true,
          right: true,
        },
        {
          name: "CP",
          selector: "CP",
          sortable: true,
          right: true,
        },

        {
          name: "VILLE",
          selector: "VILLE",
          sortable: true,
          right: true,
        },

        {
          name: "TELEPHONE",
          selector: "TELEPHONE",
          sortable: true,
          right: true,
        },
        {
          name: "SS",
          selector: "SS",
          sortable: true,
          right: true,
        },
        {
          name: "REGIME",
          selector: "REGIME",
          sortable: true,
          right: true,
        },
        {
          name: "DEPARTEMENT",
          selector: "DEPARTEMENT",
          sortable: true,
          right: true,
        },
        {
          name: "CENTRE SECU",
          selector: "CENTRE SECU",
          sortable: true,
          right: true,
        },

        {
          name: "MAIL",
          selector: "MAIL",
          sortable: true,
          right: true,
        },
        {
          name: "CLASSE",
          selector: "CLASSE",
          sortable: true,
          right: true,
        },
        {
          name: "ECOLE",
          selector: "ECOLE",
          sortable: true,
          right: true,
        },
        {
          name: "ANALYSES",
          selector: "ANALYSES",
          sortable: true,
          right: true,
        },

        {
          name: "CT",
          selector: "CT",
          sortable: true,
          right: true,
        },
      ],
      selectedRows: null,
      activateExportTable: false,
      labioData: null,
    };
  }

  handleChangeTestType = (selectedTestOption) => {
    this.setState({ selectedTestOption });
  };

  handleChangeExportType = (selectedExportOption) => {
    this.setState({ selectedExportOption });
  };

  handleChangeEstablishment = (selectedEstablishment) => {
    this.setState({ selectedEstablishment });
  };

  handleChangeNationality = (selectedNationality) => {
    this.setState({ selectedNationality });
  };

  setStartDate(dateValue) {
    this.setState({
      startDate: dateValue,
    });
  }

  setEndDate(dateValue) {
    this.setState({
      endDate: dateValue,
    });
  }

  async submitFilters(stateData) {
    try {
      this.setState({
        labioData: null,
        selectedRows: null,
        table2Data: null,
        excelData: null,
        isFetching: false,
        msg: "",
      });

      if (stateData.selectedTestOption == null) {
        alert("Sélectionnez le type de test");
      } else if (stateData.selectedEstablishment == null) {
        alert("Sélectionnez l'établissement");
      } else if (stateData.selectedNationality == null) {
        alert("Select Nationality");
      } else {
        var startdateStateValue = new Date(stateData.startDate);
        var enddateStateValue = new Date(stateData.endDate);
        var startMonth = (startdateStateValue.getMonth() + 1)
          .toString()
          .padStart(2, "0");
        var startDay = startdateStateValue
          .getDate()
          .toString()
          .padStart(2, "0");
        var startTime = moment(startdateStateValue).format("HH:mm");

        var startDateValue =
          startdateStateValue.getFullYear() +
          "-" +
          startMonth +
          "-" +
          startDay +
          "T" +
          startTime +
          ":00.000Z";

        var endMonth = (enddateStateValue.getMonth() + 1)
          .toString()
          .padStart(2, "0");
        var endDay = enddateStateValue.getDate().toString().padStart(2, "0");
        var endTime = moment(enddateStateValue).format("HH:mm");
        var endDateValue =
          enddateStateValue.getFullYear() +
          "-" +
          endMonth +
          "-" +
          endDay +
          "T" +
          endTime +
          ":00.000Z";

        this.setState({ isFetching: true, msg: "Patientez svp..." });

        this.setState({ isFetching: true, msg: "Patientez svp..." });
        const responseTestFilterData = await patientApi.getPatientTestExport(
          stateData.selectedEstablishment.value,
          startDateValue,
          endDateValue,
          stateData.selectedTestOption.value,
          stateData.selectedNationality.value
        );

        if (responseTestFilterData.error || responseTestFilterData == null) {
          this.setState({ isFetching: false, msg: "Aucun patient trouvé." });
        } else {
          var departMentsArray = [
            {
              CP: "75",
              DEPARTMENT: "751",
              CENTRESECU: "0552",
            },
            {
              CP: "77",
              DEPARTMENT: "771",
              CENTRESECU: "0208",
            },
            {
              CP: "78",
              DEPARTMENT: "781",
              CENTRESECU: "0552",
            },
            {
              CP: "91",
              DEPARTMENT: "911",
              CENTRESECU: "0112",
            },
            {
              CP: "92",
              DEPARTMENT: "921",
              CENTRESECU: "0552",
            },
            {
              CP: "93",
              DEPARTMENT: "931",
              CENTRESECU: "0895",
            },
            {
              CP: "94",
              DEPARTMENT: "941",
              CENTRESECU: "0391",
            },
            {
              CP: "95",
              DEPARTMENT: "951",
              CENTRESECU: "0202",
            },
          ];

          var classEcoleArray = [
            {
              ID: "52",
              CLASS: "CHAMPS",
              ECOLE: "PHARCHA",
            },
            {
              ID: "105",
              CLASS: "CENTRALE",
              ECOLE: "PHARCEN",
            },
            {
              ID: "56",
              CLASS: "NAT",
              ECOLE: "PHARNAT",
            },
          ];

          //                     responseTestFilterData.data = responseTestFilterData.data.filter((item) =>
          //     new Date(item.tube.createdAt).getTime() >= new Date(startDateValue).getTime() && new Date(item.tube.createdAt).getTime() <= new Date(endDateValue).getTime()
          // );

          var tableArray = [];
          var table2Array = [];
          var tableExcelArray = [];
          var resonOfTestValue = "NA";
          var addressValue = "NA";

          // eslint-disable-next-line
          responseTestFilterData.data.map((responseObj) => {
            responseObj.address.street = responseObj?.address?.street
              ? responseObj?.address?.street.replace(
                  responseObj?.address?.streetNumber
                    ? responseObj?.address?.streetNumber
                    : "  ",
                  " "
                )
              : responseObj?.address?.street;

            if (
              responseObj.phoneNumber != null &&
              responseObj.phoneNumber !== ""
            ) {
              var isdCode = responseObj.phoneNumber.slice(0, 3);
              if (
                isdCode.toString() === "033" ||
                isdCode.toString() === "+33"
              ) {
                responseObj.phoneNumber = responseObj.phoneNumber.replace(
                  "033",
                  "0"
                );
                responseObj.phoneNumber = responseObj.phoneNumber.replace(
                  "+33",
                  "0"
                );
              }
            }

            if (
              stateData.selectedTestOption.value.toString() === "1" &&
              responseObj.tube.cassette == null
            ) {
            } else if (
              stateData.selectedTestOption.value.toString() === "1" &&
              responseObj?.tube?.cassette?.result == null
            ) {
            } else if (
              stateData.selectedTestOption.value.toString() === "0" &&
              responseObj?.tube?.cassette?.result == null &&
              responseObj?.tube?.testType?.id === 1
            ) {
            } else if (
              stateData.selectedTestOption.value.toString() === "0" &&
              responseObj.tube.cassette == null &&
              responseObj?.tube?.testType?.id === 1
            ) {
            } else {
              if (
                responseObj.testMotivations !== undefined &&
                responseObj.testMotivations != null &&
                // eslint-disable-next-line
                responseObj?.tube?.testType?.id ==
                  stateData.selectedTestOption.value
              ) {
                if (responseObj.testMotivations.professionalNeeded === true) {
                  resonOfTestValue = "professionalNeeded";
                } else if (responseObj.testMotivations.travelByPlane === true) {
                  resonOfTestValue = "travelByPlane";
                } else if (
                  responseObj.testMotivations
                    .hasBeenInContactWithCovidPerson === true
                ) {
                  resonOfTestValue = "hasBeenInContactWithCovidPerson";
                } else if (
                  responseObj.testMotivations
                    .hasBeenContactedByHealthInvestigator === true
                ) {
                  resonOfTestValue = "hasBeenContactedByHealthInvestigator";
                } else if (
                  responseObj.testMotivations.hasBeenInContactWithCluster ===
                  true
                ) {
                  resonOfTestValue = "hasBeenInContactWithCluster";
                } else if (
                  responseObj.testMotivations.hasMedicalPrescription === true
                ) {
                  resonOfTestValue = "hasMedicalPrescription";
                } else if (
                  responseObj.testMotivations.haveHautingPersonAtRisk === true
                ) {
                  resonOfTestValue = "haveHautingPersonAtRisk";
                } else if (
                  responseObj.testMotivations.haveScreeningVoucher === true
                ) {
                  resonOfTestValue = "haveScreeningVoucher";
                } else if (responseObj.testMotivations.others === true) {
                  resonOfTestValue = "others";
                } else if (
                  responseObj.testMotivations.screeningForMySelf === true
                ) {
                  resonOfTestValue = "screeningForMySelf";
                } else if (
                  responseObj.testMotivations.willGoingToInstitution === true
                ) {
                  resonOfTestValue = "willGoingToInstitution";
                }
              }

              addressValue = this.formatStreetName(
                responseObj?.address?.street,
                responseObj?.address?.country
              );

              if (responseObj?.address?.country === "FR") {
                responseObj.address.zip = responseObj?.address?.zip.replace(
                  /\D/g,
                  ""
                );
              } else {
              }

              var rowObj = {
                ID: responseObj.id,
                "DATE AND TIME OF COLLECTION":
                  responseObj?.tube?.createdAt == null
                    ? "NA"
                    : moment(responseObj?.tube?.createdAt).format(
                        "YYYY-MM-DD HH:mm:ss"
                      ),
                ESTABLISHMENT: stateData.selectedEstablishment.label
                  .trim()
                  .toString(),
                "SS NUMBER": (responseObj.nir = null ? "NA" : responseObj?.nir),
                "TYPE OF TEST":
                  responseObj?.tube?.testType?.label?.en.toString(),
                "FIRST NAME":
                  responseObj?.firstName == null
                    ? "NA"
                    : responseObj?.firstName.toString().toUpperCase(),
                "LAST NAME":
                  responseObj?.lastName == null
                    ? "NA"
                    : responseObj?.lastName.toString().toUpperCase(),
                TUBE_BARCODE:
                  responseObj?.tube?.barcodeTube == null
                    ? "NA"
                    : responseObj?.tube?.barcodeTube.toString(),
                "BIRTH DATE":
                  responseObj?.dateOfBirth == null
                    ? "NA"
                    : moment(responseObj?.dateOfBirth).format("DD/MM/YYYY"),
                GENDER:
                  responseObj?.gender == null
                    ? "NA"
                    : responseObj?.gender.toString(),
                EMAIL:
                  responseObj?.email == null
                    ? "NA"
                    : responseObj?.email.toString(),
                "PHONE NUMBER":
                  responseObj?.phoneNumber == null
                    ? "NA"
                    : this.RemoveAccents(responseObj?.phoneNumber.toString()),
                "ADDRESS NUMBER":
                  addressValue != null
                    ? this.RemoveAccents(addressValue).toString().toUpperCase()
                    : "",
                "POSTAL CODE":
                  responseObj?.address?.zip == null
                    ? "NA"
                    : responseObj?.address?.zip.toString().toUpperCase(),
                CITY:
                  responseObj?.address?.city == null
                    ? "NA"
                    : responseObj?.address?.city.toString().toUpperCase(),
                COUNTRY:
                  responseObj?.address?.country == null
                    ? "NA"
                    : responseObj?.address?.country.toString().toUpperCase(),
                "ACCOMODATION TYPE":
                  responseObj?.accommodationType == null
                    ? "NA"
                    : responseObj?.accommodationType.toString(),
                "REASON OF TEST": resonOfTestValue,
                "HEALTH PROFESSIONAL -(APP)": "NA",
                "APSYM (APP)":
                  responseObj?.patientEnhanced.APSYM == null
                    ? "NA"
                    : responseObj?.patientEnhanced.APSYM.toString(),
                "RISKS (APP)":
                  responseObj?.patientEnhanced.riskFactor === false
                    ? "No"
                    : "Yes",

                RESULT:
                  responseObj?.tube?.cassette?.result == null
                    ? "-"
                    : this.handleResultLines(
                        responseObj?.tube?.cassette?.result
                      ),
              };

              var twoZip = responseObj.address.zip.substring(0, 2);
              var NRValue = "";

              var NRValueLabio = "";

              if (
                responseObj.nir === undefined ||
                responseObj.nir == null ||
                responseObj.nir === ""
              ) {
              } else {
                NRValue = responseObj.nir;
                NRValueLabio = responseObj.nir;
              }

              var departmentValue = "";
              var secuValue = "";
              var classValue = "NA";
              var ecolValue = "NA";
              // eslint-disable-next-line
              departMentsArray.map((departmentObj, i) => {
                // eslint-disable-next-line
                if (departmentObj.CP == twoZip) {
                  departmentValue = departmentObj.DEPARTMENT;
                  secuValue = departmentObj.CENTRESECU;
                }
              });
              if (departmentValue === "") {
                departmentValue = twoZip.toString() + "1";
              }
              if (secuValue === "") {
                secuValue = "0552";
              }

              var establishmentId = "";

              // eslint-disable-next-line
              stateData.establishmentsData.map((establishmentObj, i) => {
                if (
                  // eslint-disable-next-line
                  establishmentObj.value ==
                  stateData.selectedEstablishment.value
                ) {
                  establishmentId = establishmentObj.value;
                }
              });

              // eslint-disable-next-line
              classEcoleArray.map((classEcoleObj, i) => {
                // eslint-disable-next-line
                if (classEcoleObj.ID == establishmentId) {
                  classValue = classEcoleObj.CLASS;
                  ecolValue = classEcoleObj.ECOLE;
                }
              });
              var rowObj2 = {
                ID: responseObj.id,
                "NOM ELEVE":
                  responseObj.firstName == null
                    ? "NA"
                    : this.RemoveAccents(
                        responseObj.firstName.toString().toUpperCase()
                      ),
                "PRENOM ELEVE":
                  responseObj.lastName == null
                    ? "NA"
                    : this.RemoveAccents(
                        responseObj.lastName.toString().toUpperCase()
                      ),
                "DATE NAISSANCE":
                  responseObj.dateOfBirth == null
                    ? "NA"
                    : moment(responseObj.dateOfBirth).format("DD/MM/YYYY"),
                SEXE: responseObj.gender == null ? "NA" : responseObj.gender,
                ADRESSE: this.RemoveAccents(addressValue),
                CP:
                  responseObj.address.zip == null
                    ? "NA"
                    : responseObj.address.zip.toString(),
                VILLE:
                  responseObj.address.city == null
                    ? "NA"
                    : this.RemoveAccents(
                        responseObj.address.city.toString().toUpperCase()
                      ),
                TELEPHONE:
                  responseObj.phoneNumber == null
                    ? "NA"
                    : this.RemoveAccents(responseObj.phoneNumber.toString()),
                SS: NRValue,
                TUBE_BARCODE:
                  responseObj.tube.barcodeTube == null
                    ? "NA"
                    : responseObj.tube.barcodeTube.toString(),
                REGIME: "01",
                DEPARTEMENT: departmentValue.toString(),
                "CENTRE SECU": secuValue.toString(),
                MAIL:
                  responseObj.email == null
                    ? "NA"
                    : responseObj.email.toString(),
                CLASSE: classValue.toString(),
                ECOLE: ecolValue.toString(),
                ANALYSES: "£CO, COV, R19",
                CT: "MAGENTI",
                "DATE AND TIME OF COLLECTION":
                  responseObj?.tube?.createdAt == null
                    ? "NA"
                    : moment(responseObj.tube.createdAt).format(
                        "YYYY-MM-DD HH:mm:ss"
                      ),
                RESULT:
                  responseObj?.tube?.cassette?.result == null
                    ? "-"
                    : this.handleResultLines(
                        responseObj?.tube?.cassette?.result
                      ),
              };

              var ssFestival = this.removeText(NRValue, "2B", "18");
              ssFestival = this.removeText(ssFestival, "2A", "19");
              var regimValue = "01";

              var unitmapping = {
                days: 24 * 60 * 60 * 1000,
                hours: 60 * 60 * 1000,
                minutes: 60 * 1000,
                seconds: 1000,
              };

              function floor(value) {
                return Math.floor(value);
              }

              function getHumanizedDiff(diff) {
                return (
                  floor(diff / unitmapping.days) +
                  " days " +
                  floor((diff % unitmapping.days) / unitmapping.hours) +
                  " hours " +
                  floor((diff % unitmapping.hours) / unitmapping.minutes) +
                  " minutes " +
                  floor((diff % unitmapping.minutes) / unitmapping.seconds) +
                  " seconds "
                  // +
                  // floor(diff % unitmapping.seconds) +
                  // " milliseconds"
                );
              }

              var rowObj3 = {
                ID: responseObj.id,
                CIVILITE:
                  responseObj.gender == null && responseObj.dateOfBirth == null
                    ? "NA"
                    : this.getCivilite(
                        responseObj.gender,
                        this.getAge(responseObj.dateOfBirth)
                      ),
                NOM_ELEVE:
                  responseObj.lastName == null
                    ? "NA"
                    : this.RemoveAccents(
                        responseObj.lastName.toString().toUpperCase()
                      ),
                PRENOM_ELEVE:
                  responseObj.firstName == null
                    ? "NA"
                    : this.RemoveAccents(
                        responseObj.firstName.toString().toUpperCase()
                      ),
                NOM_NAISSANCE:
                  responseObj.birthName == null
                    ? ""
                    : this.RemoveAccents(responseObj.birthName.toString()),
                DATE_NAISSANCE:
                  responseObj.dateOfBirth == null
                    ? "NA"
                    : moment(responseObj.dateOfBirth).format("DD/MM/YYYY"),
                TUBE_BARCODE:
                  responseObj.tube.barcodeTube == null
                    ? "NA"
                    : responseObj.tube.barcodeTube.toString(),
                SEXE: responseObj.gender == null ? "NA" : responseObj.gender,
                PAYMENT_METHOD: responseObj.tube.paymentMethod,
                TEST_PRICE:
                  responseObj.tube.amount === ""
                    ? "NA"
                    : responseObj.tube.amount,
                TYPE_OF_TEST:
                  responseObj?.tube?.testType?.id === 1
                    ? "Covid-19 Antigen Rapid test"
                    : responseObj?.tube?.testType?.id === 2
                    ? "Covid-19 PCR test"
                    : responseObj?.tube?.testType?.id === 3
                    ? "COVID-19 PCR Premium"
                    : "",

                SERVICE_CHARGE:
                  responseObj.tube.serviceCharge === ""
                    ? "NA"
                    : responseObj.tube.serviceCharge,
                ADRESSE: this.RemoveAccents(addressValue),
                CP: responseObj.address.zip.toString(),
                VILLE:
                  responseObj.address.city == null
                    ? "NA"
                    : this.RemoveAccents(
                        responseObj.address.city.toString().toUpperCase()
                      ),
                PAYS: responseObj?.address?.country,
                TELEPHONE:
                  responseObj.phoneNumber == null
                    ? "NA"
                    : this.RemoveAccents(responseObj.phoneNumber.toString()),
                SS: NRValue,
                SSFESTIVAL: ssFestival,

                SSLABIO: NRValueLabio,
                REGIME: regimValue.toString(),
                DEPARTEMENT: departmentValue.toString(),
                CENTRE_SECU: secuValue.toString(),
                MAIL:
                  responseObj.email == null
                    ? "NA"
                    : responseObj.email.toString(),
                CLASSE: classValue.toString(),
                ECOLE: ecolValue.toString(),
                ANALYSES: "£CO, COV, R19",
                CT: "MAGENTI",
                "ACCOMODATION TYPE":
                  responseObj?.accommodationType == null
                    ? "NA"
                    : responseObj?.accommodationType.toString(),
                "REASON OF TEST": resonOfTestValue,
                "HEALTH PROFESSIONAL -(APP)": "NA",
                "APSYM (APP)": responseObj?.patientEnhanced?.APSYM.toString(),
                "RISKS (APP)":
                  responseObj.patientEnhanced.riskFactor === false
                    ? "No"
                    : "Yes",
                "CENTRE SECUBOIM": "801",
                "DATE AND TIME OF COLLECTION BIOLAM":
                  moment(responseObj.tube.createdAt).format("YYYY-MM-DD") +
                  " 15:00:00",
                "DATE AND TIME OF COLLECTION":
                  responseObj?.tube?.createdAt == null
                    ? "NA"
                    : moment(responseObj.tube.createdAt).format(
                        "DD-MM-YYYY HH:mm:ss"
                      ),
                "ID TEST": "FESTIVAL",
                ANALYSESFESTIVAL: "£CO, AGF",
                CTFESTIVAL: "CAPEFES",
                SECUFESTIVAL:
                  responseObj.address.country === "FR"
                    ? secuValue.toString()
                    : "0552",
                DEPARTMENTFESTIVAL:
                  responseObj.address.country === "FR"
                    ? departmentValue.toString()
                    : "751",
                UTILISATEUR: responseObj?.user?.email,
                UTILISATEURID: responseObj?.user?.lastName,
                BRAND_OF_TEST:
                  responseObj?.tube?.cassette?.manufacturerTest?.display_name,
                BATCH_NO: responseObj?.tube?.cassette?.batchNumber,
                DURATION_INCUBATION: getHumanizedDiff(
                  new Date(responseObj?.tube?.cassette?.result?.createdAt) -
                    new Date(responseObj?.tube?.cassette?.createdAt)
                ),
                EXPIRY_DATE: responseObj?.tube?.cassette?.expirationDate,
                DATE_TIME_RESULT:
                  responseObj?.tube?.cassette?.result?.createdAt == null
                    ? "NA"
                    : moment(
                        responseObj?.tube?.cassette?.result?.createdAt
                      ).format("DD-MM-YYYY HH:mm:ss"),
                // responseObj?.tube?.cassette?.result?.createdAt,
                AG_LINE: responseObj?.tube?.cassette?.result?.lines?.Ag,
                C_LINE: responseObj?.tube?.cassette?.result?.lines?.C,
                FIRST_NAME: responseObj?.firstName,
                LAST_NAME: responseObj?.lastName,
                EMAIL_ADDRESS: responseObj?.email,
                DATE_TIME_OF_COLLECTION: responseObj?.tube?.createdAt,
                USER_NAME: responseObj?.user?.firstName,
                USER_SURNAME: responseObj?.user?.lastName,
                USER_EMAIL: responseObj?.user?.email,
                RESULT:
                  responseObj?.tube?.cassette?.result == null
                    ? "-"
                    : this.handleResultLines(
                        responseObj?.tube?.cassette?.result
                      ),
              };

              tableArray.push(rowObj);
              table2Array.push(rowObj2);
              tableExcelArray.push(rowObj3);
            }
          });

          this.setState({
            tableDataCsvBackup: table2Array,
            tableDataExcelBackup: tableExcelArray,
            tableData: tableArray,
            isFetching: false,
            msg: "",
          });

          console.log();
        }
      }
    } catch (e) {
      // alert("Something went wrong!");
    }
  }

  formatStreetName = (name, nationality) => {
    const mapObject = {
      residence: "res",
      batiment: "bat",
      avenue: "av",
      boulevard: "bd",
      escalier: "esc",
      appartement: "appt",
      porte: "pte",
    };
    const nameWithoutAccent = this.RemoveAccents(name);
    let streetName;
    if (nationality === "FR" && nameWithoutAccent != null) {
      streetName = nameWithoutAccent
        .toString()
        .toLowerCase()
        .replace(
          /\b(?:residence|batiment|avenue|boulevard|escalier|appartement|porte)\b/gi,
          (matchedValue) => mapObject[matchedValue]
        );
    } else {
      streetName = name;
    }

    return streetName;
  };

  checkSelectedRows(stateDataRows) {
    // console.log("selected rows", JSON.stringify(stateDataRows.selectedRows));
  }

  RemoveAccents(strAccents) {
    try {
      // eslint-disable-next-line
      var strAccents = strAccents.split("");
      // eslint-disable-next-line
      var strAccentsOut = new Array();
      var strAccentsLen = strAccents.length;
      var accents =
        "ÀÁÂÃÄÅàáâãäåÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠšŸÿýŽž-,.+()'";
      var accentsOut =
        "AAAAAAaaaaaaOOOOOOOooooooEEEEeeeeeCcDIIIIiiiiUUUUuuuuNnSsYyyZz   0   ";
      for (var y = 0; y < strAccentsLen; y++) {
        if (accents.indexOf(strAccents[y]) !== -1) {
          strAccentsOut[y] = accentsOut.substr(
            accents.indexOf(strAccents[y]),
            1
          );
        } else {
          strAccentsOut[y] = strAccents[y];
        }
      }
      strAccentsOut = strAccentsOut.join("");

      strAccentsOut = strAccentsOut.replaceAll("'", " ");
      return strAccentsOut;
    } catch (e) {
      // console.log("error", JSON.stringify(e));
    }
  }

  handleResultLines(result) {
    let res = "";
    let key = "Ag";
    let keyNew = "T"; // TODO handle all cases
    if (
      result.lines["C"] === "unknown" ||
      result.lines["C"] === "invalid" ||
      result.lines[key] === "invalid"
    ) {
      res = "Invalide";
    } else if (
      result.lines[key] === "negative" ||
      result.lines[keyNew] === "negative" ||
      result.lines[key] === "Negative" ||
      result.lines[keyNew] === "Negative"
    ) {
      res = "Negative";
    } else if (
      result.lines[key] === "positive" ||
      result.lines[key] === "Positive" ||
      result.lines[keyNew] === "positive" ||
      result.lines[keyNew] === "Positive"
    ) {
      res = "Positive";
    }
    return res;
  }

  removeText(input, oldValue, newValue) {
    try {
      input = input.replaceAll(oldValue, newValue);
      return input;
    } catch (e) {
      // console.log("error", JSON.stringify(e));
    }
  }

  async componentDidMount() {
    try {
      this.setState({ isFetching: true, msg: "Patientez svp..." });
      var establishmentArray = [];
      var isAdmincheck = localStorage.getItem("isAdmin");
      if (isAdmincheck.toString() === "false") {
        var establishmentNameValue = localStorage.getItem("establishmentName");
        var establishmentIdValue = localStorage.getItem("establishment");
        var objEstablishmentStatic = {
          value: establishmentIdValue,
          label: establishmentNameValue,
        };
        establishmentArray.push(objEstablishmentStatic);
        this.setState({
          establishmentsData: establishmentArray,
          isFetching: false,
          msg: "",
        });
      } else {
        const responseEstablishment = await establishmentApi.getEtablishments();

        if (responseEstablishment.error) {
          this.setState({ isFetching: false, msg: "Aucun patient trouvé." });
        } else {
          // eslint-disable-next-line
          responseEstablishment.data.map((responseObj, i) => {
            var objEstablishment = {
              value: responseObj.id,
              label: responseObj.name,
            };
            establishmentArray.push(objEstablishment);
          });

          this.setState({
            establishmentsData: establishmentArray,
            isFetching: false,
            msg: "",
          });
        }
      }
    } catch (e) {
      // console.log("error", JSON.stringify(e));
    }
  }

  updateState = (state) => {
    this.setState({
      labioData: null,
      selectedRows: null,
      table2Data: null,
      excelData: null,
      isFetching: false,
      msg: "",
    });

    const { tableDataCsvBackup } = this.state;
    const { tableDataExcelBackup } = this.state;
    var selectedArr = state.selectedRows;
    var arraCSV = tableDataCsvBackup;
    var arraXcl = tableDataExcelBackup;
    var table2Array = [];
    var tableExcelArray = [];
    var labioDataArray = [];
    if (selectedArr.length > 0) {
      table2Array = arraCSV.filter((n) =>
        selectedArr.some(
          // eslint-disable-next-line
          (n2) => n.ID == n2.ID && n.TUBE_BARCODE == n2.TUBE_BARCODE
        )
      );

      tableExcelArray = arraXcl.filter((n) =>
        selectedArr.some(
          // eslint-disable-next-line
          (n2) => n.ID == n2.ID && n.TUBE_BARCODE == n2.TUBE_BARCODE
        )
      );

      table2Array = table2Array.filter(
        (v, i, a) =>
          a.findIndex((t) => JSON.stringify(t) === JSON.stringify(v)) === i
      );

      tableExcelArray = tableExcelArray.filter(
        (v, i, a) =>
          a.findIndex((t) => JSON.stringify(t) === JSON.stringify(v)) === i
      );

      // eslint-disable-next-line
      tableExcelArray.map((labioObj, i) => {
        var CIVILITE;
        var age;
        var dateMomentObject = moment(labioObj.DATE_NAISSANCE, "DD/MM/YYYY"); // 1st argument - string, 2nd argument - format
        var dateObject = dateMomentObject.toDate();
        age = this.getAge(dateObject);
        if (age > 18 && labioObj.SEXE.toLowerCase() === "f") {
          CIVILITE = "1";
        } else if (age > 18 && labioObj.SEXE.toLowerCase() === "m") {
          CIVILITE = "2";
        } else if (age < 19 && labioObj.SEXE.toLowerCase() === "f") {
          CIVILITE = "F";
        } else if (age < 19 && labioObj.SEXE.toLowerCase() === "m") {
          CIVILITE = "G";
        }

        var adr = this.RemoveAccents(this.RemoveAccents(labioObj.ADRESSE));
        var finalAddress = this.truncateText(adr, 60);

        var objLabio = {
          CIVILITE: CIVILITE,
          NOM_ELEVE: this.truncateText(
            this.RemoveAccents(labioObj.NOM_ELEVE.toString().toUpperCase()),
            30
          ),
          PRENOM_ELEVE: this.truncateText(
            this.RemoveAccents(labioObj.PRENOM_ELEVE.toString().toUpperCase()),
            25
          ),
          NOM_NAISSANCE:
            labioObj.NOM_NAISSANCE == null
              ? "Nom de naissance"
              : labioObj.NOM_NAISSANCE.toString(),
          DATE_NAISSANCE: labioObj.DATE_NAISSANCE.toString(),
          TELEPHONE: this.RemoveAccents(labioObj.TELEPHONE.toString()),
          ADRESSE: finalAddress,
          CP: labioObj.CP.toString(),
          VILLE: this.RemoveAccents(labioObj.VILLE.toString().toUpperCase()),
          SS: labioObj.SSLABIO,
          MAIL: labioObj.MAIL.toString(),
        };
        labioDataArray.push(objLabio);
      });

      this.setState({
        labioData: labioDataArray,
        selectedRows: state.selectedRows,
        table2Data: table2Array,
        excelData: tableExcelArray,
        isFetching: false,
        msg: "",
      });
    } else {
      this.setState({
        labioData: null,
        selectedRows: null,
        table2Data: null,
        excelData: null,
        isFetching: false,
        msg: "",
      });
    }
  };

  truncateText(text, length) {
    if (text != null) {
      if (text.length <= length) {
        return text;
      }

      return text.substr(0, length) + "\u2026";
    } else {
      return "";
    }
  }

  getAge(birth) {
    var ageMS = Date.parse(Date()) - Date.parse(birth);
    var age = new Date();
    age.setTime(ageMS);
    var ageYear = age.getFullYear() - 1970;

    return ageYear;
  }

  getCivilite = (gender, age) => {
    let civilite;

    if (gender === "M") {
      civilite = "Mr";
    } else {
      if (age > 18) {
        civilite = "Mme";
      } else {
        civilite = "Mlle";
      }
    }

    return civilite;
  };

  render() {
    // eslint-disable-next-line
    const { classes } = this.props;
    const { selectedTestOption } = this.state;
    const { selectedEstablishment } = this.state;
    const { selectedExportOption } = this.state;
    const { selectedNationality } = this.state;
    const testTypesData = [
      { value: "0", label: "All types of tests" },
      { value: "1", label: "COVID-19 Antigenic Test" },
      { value: "2", label: "COVID-19 PCR Test" },
      { value: "3", label: "COVID-19 PCR Premium" },
    ];
    const { labioData } = this.state;
    const exportFormatsData = [
      // { value: "1", label: "CSV DROUOT-PCR" },
      { value: "2", label: "EXCEL DROUOT-PCR" },
      // { value: "3", label: "STANDARD-CSV" },
      // { value: "4", label: "STANDARD-EXCEL" },
      // { value: "5", label: "LABIO-EXCEL" },
      // { value: "6", label: "BIOLAM-EXCEL" },
      { value: "7", label: "DROUOT-FESTIVAL-AG" },
      { value: "8", label: "Event-O-Excel" },
      // { value: "9", label: "OLD- EXCEL DROUOT PCR" },
      // { value: "10", label: "OLD DROUOT FESTIVAL AG" },
      { value: "11", label: "QC Monitoring" },
    ];
    const nationalityData = [
      { value: "ALL", label: "All" },
      { value: "FR", label: "France" },
      { value: "FRE", label: "Étrangers" },
    ];

    const fileNameFestival =
      this.state.selectedEstablishment?.label +
      "_" +
      moment(this.state.startDate).format("DD-MM-YYYY") +
      "_to_" +
      moment(this.state.endDate).format("DD-MM-YYYY");
    const { establishmentsData } = this.state;
    const { excelData } = this.state;

    return (
      <LayoutPageComponent>
        <p className="title">Outil d'export</p>
        <div className="filters">
          <div className="filterControl">
            <p> Sélectionnez la date : </p>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DateTimePicker
                value={this.state.startDate}
                onChange={(date) => this.setStartDate(date)}
              />
            </MuiPickersUtilsProvider>
          </div>
          <div className="filterControl">
            <p> Séletionnez la date de fin : </p>

            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DateTimePicker
                value={this.state.endDate}
                onChange={(date) => this.setEndDate(date)}
              />
            </MuiPickersUtilsProvider>
          </div>

          <div className="filterControl">
            <p> Select Type de test : </p>
            <Select
              value={selectedTestOption}
              onChange={this.handleChangeTestType}
              options={testTypesData}
            />
          </div>

          <div className="filterControl">
            <p> Sélectionnez l'établissement : </p>
            <Select
              value={selectedEstablishment}
              onChange={this.handleChangeEstablishment}
              options={establishmentsData}
            />
          </div>

          <div className="filterControl">
            <p> Nationality : </p>
            <Select
              value={selectedNationality}
              maxMenuHeight={100}
              onChange={this.handleChangeNationality}
              options={nationalityData}
            />
          </div>

          <div className="filterControl">
            <p> Sélectionnez le format d'export : </p>
            <Select
              value={selectedExportOption}
              maxMenuHeight={100}
              onChange={this.handleChangeExportType}
              options={exportFormatsData}
            />
          </div>

          <div className="filterControlButtons">
            {this.state.table2Data != null &&
              this.state.selectedExportOption != null &&
              // eslint-disable-next-line
              this.state.selectedExportOption.value == 1 && (
                <CSVLink
                  data={this.state.table2Data}
                  filename={"Drouot-PCR.csv"}
                >
                  <button class="filterbutton">Drouot-PCR</button>
                </CSVLink>
              )}

            {this.state.excelData != null &&
              this.state.selectedExportOption != null &&
              // eslint-disable-next-line
              this.state.selectedExportOption.value == 2 && (
                <ExcelFile
                  element={<button class="filterbutton">Export</button>}
                  filename={fileNameFestival}
                >
                  <ExcelSheet data={excelData} name="Drout_PCR">
                    <ExcelColumn label="ID" value="ID" />
                    <ExcelColumn label="CIVILITE" value="CIVILITE" />
                    <ExcelColumn label="NOM ELEVE" value="NOM_ELEVE" />
                    <ExcelColumn label="PRENOM ELEVE" value="PRENOM_ELEVE" />
                    <ExcelColumn
                      label="DATE NAISSANCE"
                      value="DATE_NAISSANCE"
                    />
                    <ExcelColumn label="SEXE" value="SEXE" />
                    <ExcelColumn label="ADRESSE" value="ADRESSE" />
                    <ExcelColumn label="CP" value="CP" />
                    <ExcelColumn label="VILLE" value="VILLE" />
                    <ExcelColumn label="PAYS" value="PAYS" />

                    <ExcelColumn label="TELEPHONE" value="TELEPHONE" />
                    <ExcelColumn label="SS" value="SS" />
                    <ExcelColumn label="REGIME" value="REGIME" />
                    <ExcelColumn label="DEPARTEMENT" value="DEPARTEMENT" />
                    <ExcelColumn label="CENTRE SECU" value="CENTRE_SECU" />
                    <ExcelColumn label="MAIL" value="MAIL" />
                    <ExcelColumn label="CLASSE" value="CLASSE" />
                    <ExcelColumn label="ECOLE" value="ECOLE" />
                    <ExcelColumn label="ANALYSES" value="ANALYSES" />
                    <ExcelColumn label="CT" value="CT" />
                  </ExcelSheet>
                </ExcelFile>
              )}

            {this.state.excelData != null &&
              this.state.selectedExportOption != null &&
              // eslint-disable-next-line
              this.state.selectedExportOption.value == 11 && (
                <ExcelFile
                  element={<button class="filterbutton">Export</button>}
                  filename={fileNameFestival}
                >
                  <ExcelSheet data={excelData} name="Drout_PCR">
                    <ExcelColumn label="ID" value="ID" />
                    <ExcelColumn label="User Name" value="USER_NAME" />
                    <ExcelColumn label="User Surname" value="USER_SURNAME" />

                    <ExcelColumn label="User email" value="USER_EMAIL" />
                    <ExcelColumn
                      label="Date et heure de prélèvement"
                      value="DATE AND TIME OF COLLECTION"
                    />
                    <ExcelColumn
                      label="Brand of the test"
                      value="BRAND_OF_TEST"
                    />
                    <ExcelColumn label="Batch no" value="BATCH_NO" />
                    <ExcelColumn label="Expiry date" value="EXPIRY_DATE" />
                    <ExcelColumn
                      label="Duration of incubation"
                      value="DURATION_INCUBATION"
                    />
                    <ExcelColumn
                      label="Date and time of result"
                      value="DATE_TIME_RESULT"
                    />
                    <ExcelColumn label="Ag line" value="AG_LINE" />
                    <ExcelColumn label="C line" value="C_LINE" />
                  </ExcelSheet>
                </ExcelFile>
              )}

            {this.state.excelData != null &&
              this.state.selectedExportOption != null &&
              // eslint-disable-next-line
              this.state.selectedExportOption.value == 9 && (
                <ExcelFile
                  element={<button class="filterbutton">Export</button>}
                  filename={fileNameFestival}
                >
                  <ExcelSheet data={excelData} name="Drout_PCR">
                    <ExcelColumn label="CIVILITE" value="CIVILITE" />
                    <ExcelColumn label="NOM ELEVE" value="NOM_ELEVE" />
                    <ExcelColumn label="PRENOM ELEVE" value="PRENOM_ELEVE" />
                    <ExcelColumn
                      label="DATE NAISSANCE"
                      value="DATE_NAISSANCE"
                    />
                    <ExcelColumn label="SEXE" value="SEXE" />
                    <ExcelColumn label="ADRESSE" value="ADRESSE" />
                    <ExcelColumn label="CP" value="CP" />
                    <ExcelColumn label="VILLE" value="VILLE" />
                    <ExcelColumn label="TELEPHONE" value="TELEPHONE" />
                    <ExcelColumn label="SS" value="SS" />
                    <ExcelColumn label="REGIME" value="REGIME" />
                    <ExcelColumn label="DEPARTEMENT" value="DEPARTEMENT" />
                    <ExcelColumn label="CENTRE SECU" value="CENTRE_SECU" />
                    <ExcelColumn label="MAIL" value="MAIL" />
                    <ExcelColumn label="CLASSE" value="CLASSE" />
                    <ExcelColumn label="ECOLE" value="ECOLE" />
                    <ExcelColumn label="ANALYSES" value="ANALYSES" />
                    <ExcelColumn label="CT" value="CT" />
                  </ExcelSheet>
                </ExcelFile>
              )}

            {this.state.excelData != null &&
              this.state.selectedExportOption != null &&
              // eslint-disable-next-line
              this.state.selectedExportOption.value == 6 && (
                <ExcelFile
                  element={<button class="filterbutton">Export</button>}
                  filename={"BIOLAM"}
                >
                  <ExcelSheet data={excelData} name="BIOLAM_PCR">
                    <ExcelColumn label="NOM ELEVE" value="NOM_ELEVE" />
                    <ExcelColumn label="PRENOM ELEVE" value="PRENOM_ELEVE" />
                    <ExcelColumn
                      label="DATE NAISSANCE"
                      value="DATE_NAISSANCE"
                    />
                    <ExcelColumn label="SEXE" value="SEXE" />
                    <ExcelColumn label="ADRESSE" value="ADRESSE" />
                    <ExcelColumn label="CP" value="CP" />
                    <ExcelColumn label="VILLE" value="VILLE" />
                    <ExcelColumn label="TELEPHONE" value="TELEPHONE" />
                    <ExcelColumn label="SS" value="SS" />
                    <ExcelColumn label="REGIME" value="REGIME" />
                    <ExcelColumn label="DEPARTEMENT" value="DEPARTEMENT" />
                    <ExcelColumn label="CENTRE SECU" value="CENTRE SECUBOIM" />
                    <ExcelColumn label="MAIL" value="MAIL" />
                    <ExcelColumn
                      label="Hébergement"
                      value="ACCOMODATION TYPE"
                    />
                    <ExcelColumn
                      label="Raison du test"
                      value="REASON OF TEST"
                    />
                    <ExcelColumn
                      label="HEALTH PROFESSIONAL -(APP)"
                      value="HEALTH PROFESSIONAL -(APP)"
                    />
                    <ExcelColumn label="Symptômes" value="APSYM (APP)" />
                    <ExcelColumn
                      label="Facteurs de risque"
                      value="RISKS (APP)"
                    />
                    <ExcelColumn
                      label="Date et heure de prélèvement"
                      value="DATE AND TIME OF COLLECTION BIOLAM"
                    />
                  </ExcelSheet>
                </ExcelFile>
              )}

            {this.state.selectedRows != null &&
              this.state.selectedExportOption != null &&
              // eslint-disable-next-line
              this.state.selectedExportOption.value == 3 && (
                <CSVLink
                  data={this.state.selectedRows}
                  filename={"MyPatients.csv"}
                >
                  <button class="filterbutton">Export</button>
                </CSVLink>
              )}

            {this.state.selectedRows != null &&
              this.state.selectedExportOption != null &&
              // eslint-disable-next-line
              this.state.selectedExportOption.value == 4 && (
                <ExcelFile
                  element={<button class="filterbutton">Export</button>}
                  filename={"MyPatients"}
                >
                  <ExcelSheet data={this.state.selectedRows} name="Drout_PCR">
                    <ExcelColumn label="ID" value="ID" />
                    <ExcelColumn
                      label="Date et heure de prélèvement"
                      value="DATE AND TIME OF COLLECTION"
                    />
                    <ExcelColumn label="ESTABLISHMENT" value="ESTABLISHMENT" />
                    <ExcelColumn label="SS NUMBER" value="SS NUMBER" />
                    <ExcelColumn label="Type de test" value="TYPE OF TEST" />
                    <ExcelColumn label="Prénom" value="FIRST NAME" />
                    <ExcelColumn label="Nom" value="LAST NAME" />
                    <ExcelColumn
                      label="Code-barres du tube"
                      value="TUBE_BARCODE"
                    />
                    <ExcelColumn label="BIRTH DATE" value="BIRTH DATE" />
                    <ExcelColumn label="Genre" value="GENDER" />
                    <ExcelColumn label="EMAIL" value="EMAIL" />
                    <ExcelColumn
                      label="Numéro de téléphone"
                      value="PHONE NUMBER"
                    />
                    <ExcelColumn label="MAIL" value="MAIL" />
                    <ExcelColumn label="Adresse" value="ADDRESS NUMBER" />
                    <ExcelColumn label="Code postal" value="POSTAL CODE" />
                    <ExcelColumn label="Ville" value="CITY" />
                    <ExcelColumn label="Pays" value="COUNTRY" />
                    <ExcelColumn
                      label="Hébergement"
                      value="ACCOMODATION TYPE"
                    />
                    <ExcelColumn
                      label="Raison du test"
                      value="REASON OF TEST"
                    />
                    <ExcelColumn
                      label="HEALTH PROFESSIONAL -(APP)"
                      value="HEALTH PROFESSIONAL -(APP)"
                    />
                    <ExcelColumn label="Symptômes" value="APSYM (APP)" />
                    <ExcelColumn
                      label="Facteurs de risque"
                      value="RISKS (APP)"
                    />
                    <ExcelColumn label="RESULT" value="RESULT" />
                  </ExcelSheet>
                </ExcelFile>
              )}

            {this.state.labioData != null &&
              this.state.selectedExportOption != null &&
              // eslint-disable-next-line
              this.state.selectedExportOption.value == 5 && (
                <ExcelFile
                  element={<button class="filterbutton">Export LABIO</button>}
                  filename={"LABIO"}
                >
                  <ExcelSheet data={labioData} name="LABIO">
                    <ExcelColumn label="Civilité" value="CIVILITE" />
                    <ExcelColumn label="Prénom" value="PRENOM_ELEVE" />
                    <ExcelColumn label="Nom" value="NOM_ELEVE" />
                    <ExcelColumn
                      label="Nom De Naissance"
                      value="NOM_NAISSANCE"
                    />

                    <ExcelColumn
                      label="Date Naissance"
                      value="DATE_NAISSANCE"
                    />
                    <ExcelColumn label="Téléphone" value="TELEPHONE" />
                    <ExcelColumn label="Adresse" value="ADRESSE" />
                    <ExcelColumn label="CodePostal" value="CP" />
                    <ExcelColumn label="Ville" value="VILLE" />
                    <ExcelColumn label="N°Secu" value="SS" />
                    <ExcelColumn label="Mail" value="MAIL" />
                  </ExcelSheet>
                </ExcelFile>
              )}

            {this.state.excelData != null &&
              this.state.selectedExportOption != null &&
              // eslint-disable-next-line
              this.state.selectedExportOption.value == 7 && (
                <ExcelFile
                  element={<button class="filterbutton">Export</button>}
                  filename={fileNameFestival}
                >
                  <ExcelSheet data={excelData} name={fileNameFestival}>
                    <ExcelColumn label="ID" value="ID" />
                    <ExcelColumn label="CIVILITE" value="CIVILITE" />
                    <ExcelColumn label="NOM" value="NOM_ELEVE" />
                    <ExcelColumn label="PRENOM" value="PRENOM_ELEVE" />
                    <ExcelColumn
                      label="DATE NAISSANCE"
                      value="DATE_NAISSANCE"
                    />
                    <ExcelColumn label="SEXE" value="SEXE" />
                    <ExcelColumn label="ADRESSE" value="ADRESSE" />
                    <ExcelColumn label="CP" value="CP" />
                    <ExcelColumn label="VILLE" value="VILLE" />
                    <ExcelColumn label="PAYS" value="PAYS" />
                    <ExcelColumn label="TELEPHONE" value="TELEPHONE" />
                    <ExcelColumn label="SS" value="SSFESTIVAL" />
                    <ExcelColumn label="REGIME" value="REGIME" />
                    <ExcelColumn
                      label="DEPARTEMENT"
                      value="DEPARTMENTFESTIVAL"
                    />
                    <ExcelColumn label="CENTRE SECU" value="SECUFESTIVAL" />
                    <ExcelColumn label="MAIL" value="MAIL" />
                    <ExcelColumn label="ID TEST" value="ID TEST" />
                    <ExcelColumn label="ANALYSES" value="ANALYSESFESTIVAL" />
                    <ExcelColumn label="CT" value="CTFESTIVAL" />
                  </ExcelSheet>
                </ExcelFile>
              )}
            {this.state.excelData != null &&
              this.state.selectedExportOption != null &&
              // eslint-disable-next-line
              this.state.selectedExportOption.value == 10 && (
                <ExcelFile
                  element={<button class="filterbutton">Export</button>}
                  filename={fileNameFestival}
                >
                  <ExcelSheet data={excelData} name={fileNameFestival}>
                    <ExcelColumn label="CIVILITE" value="CIVILITE" />
                    <ExcelColumn label="NOM" value="NOM_ELEVE" />
                    <ExcelColumn label="PRENOM" value="PRENOM_ELEVE" />
                    <ExcelColumn
                      label="DATE NAISSANCE"
                      value="DATE_NAISSANCE"
                    />
                    <ExcelColumn label="SEXE" value="SEXE" />
                    <ExcelColumn label="ADRESSE" value="ADRESSE" />
                    <ExcelColumn label="CP" value="CP" />
                    <ExcelColumn label="VILLE" value="VILLE" />
                    <ExcelColumn label="TELEPHONE" value="TELEPHONE" />
                    <ExcelColumn label="SS" value="SSFESTIVAL" />
                    <ExcelColumn label="REGIME" value="REGIME" />
                    <ExcelColumn
                      label="DEPARTEMENT"
                      value="DEPARTMENTFESTIVAL"
                    />
                    <ExcelColumn label="CENTRE SECU" value="SECUFESTIVAL" />
                    <ExcelColumn label="MAIL" value="MAIL" />
                    <ExcelColumn label="ID TEST" value="ID TEST" />
                    <ExcelColumn label="ANALYSES" value="ANALYSESFESTIVAL" />
                    <ExcelColumn label="CT" value="CTFESTIVAL" />
                  </ExcelSheet>
                </ExcelFile>
              )}

            {this.state.excelData != null &&
              this.state.selectedExportOption != null &&
              // eslint-disable-next-line
              this.state.selectedExportOption.value == 8 && (
                <ExcelFile
                  element={<button class="filterbutton">Export</button>}
                  filename={fileNameFestival}
                >
                  <ExcelSheet data={excelData} name={fileNameFestival}>
                    <ExcelColumn label="PATIENT ID" value="ID" />
                    <ExcelColumn label="NOM" value="NOM_ELEVE" />
                    <ExcelColumn label="PRENOM" value="PRENOM_ELEVE" />
                    <ExcelColumn
                      label="DATE NAISSANCE"
                      value="DATE_NAISSANCE"
                    />

                    <ExcelColumn label="SEXE" value="SEXE" />
                    <ExcelColumn label="ADRESSE" value="ADRESSE" />
                    <ExcelColumn label="CP" value="CP" />
                    <ExcelColumn label="VILLE" value="VILLE" />
                    <ExcelColumn label="COUNTRY" value="PAYS" />
                    <ExcelColumn
                      label="Date et heure de prélèvement"
                      value="DATE AND TIME OF COLLECTION"
                    />
                    <ExcelColumn label="TELEPHONE" value="TELEPHONE" />
                    <ExcelColumn label="SS" value="SSFESTIVAL" />
                    <ExcelColumn label="REGIME" value="REGIME" />
                    <ExcelColumn
                      label="DEPARTEMENT"
                      value="DEPARTMENTFESTIVAL"
                    />
                    <ExcelColumn label="CENTRE SECU" value="SECUFESTIVAL" />
                    <ExcelColumn label="MAIL" value="MAIL" />
                    <ExcelColumn label="ID TEST" value="ID TEST" />
                    <ExcelColumn label="ANALYSES" value="ANALYSESFESTIVAL" />
                    <ExcelColumn label="CT" value="CTFESTIVAL" />
                    <ExcelColumn label="UTILISATEUR" value="UTILISATEUR" />
                    <ExcelColumn label="ID UTILISATEUR" value="UTILISATEURID" />
                    <ExcelColumn
                      label="PAYMENT METHOD"
                      value="PAYMENT_METHOD"
                    />

                    <ExcelColumn label="PRICE OF TEST" value="TEST_PRICE" />
                    <ExcelColumn label="SERVICE FEES" value="SERVICE_CHARGE" />
                    <ExcelColumn label="Type of test" value="TYPE_OF_TEST" />
                    <ExcelColumn label="Result" value="AG_LINE" />
                  </ExcelSheet>
                </ExcelFile>
              )}

            <button
              onClick={() => this.submitFilters(this.state)}
              class="filterbutton"
            >
              Rechercher
            </button>
            <div></div>
          </div>
        </div>

        <p>{this.state.msg}</p>
        <div className="tableBox">
          {this.state.tableData != null && (
            <DataTable
              pagination={true}
              paginationDefaultPage={1}
              style={{ width: 4200 }}
              title="Patients"
              columns={this.state.tableColumns}
              data={this.state.tableData}
              wrap={true}
              fixedHeader={true}
              selectableRows
              selectableRowsComponent={Checkbox}
              onSelectedRowsChange={this.updateState}
            />
          )}
        </div>
      </LayoutPageComponent>
    );
  }
}

export default ExportPatient;
