import React from "react";
import * as entityApi from "../../api/Etablishment";
import * as priceApi from "../../api/Price";
import * as priceAddApi from "../../api/Company";
import CompanyDetailsTableComponent from "../../components/CompanyDetailsTableComponent";
import EntityDetailsTable from "../../components/EntityDetailsTableComponent";
import UserListTable from "../../components/UserListTableComponent";
import SignatoryListTable from "../../components/SignatoryListTable";
import SignatoryUpload from "./signatory_upload";
import LogoUpload from "./logo_upload";
import LayoutPageComponent from "../../components/LayoutPageComponent";
import OnlinePaymentList from "../../components/OnlinePaymentList";
import AddPriceButton from "../../components/PriceAddButton";
import OnlinePaymentStatus from "../../components/OnlinePaymentStatus";
import { Link } from "react-router-dom";

class EntityDetails extends React.Component {
  constructor(props) {
    super(props);

    this.entityID = this.props.history.location["state"]
      ? this.props.history.location["state"]
      : null;

    this.state = {
      show: false,
      buttonDisabled: true,
      buttonEnabled: false,
      clicked: false,
      isFetching: false,
      msgUser: "",
      msgEntity: this.entityID === null ? "Aucune entité sélectionnée" : "",
      entityID: this.entityID !== null ? this.entityID : "",

      entityData: {},
      signatories: [],
      usersList: [],
      companyData: {},
      onlinePayment: [],
      addPrice: [],
      isLoading: true,
    };
  }

  handleClick() {
    this.setState({
      clicked: true,
    });
  }

  async componentWillMount() {
    this.setState({ isFetching: true, msgEntity: "Patientez svp..." });
    const response = await entityApi.getEtablishment(this.state.entityID);

    if (response.error) {
      this.setState({
        isFetching: false,
        msgEntity: "Aucune entité trouvée.",
        msgUser: "Aucun utilisateur associé.",
      });
    } else {
      var data = response.data;
      this.setState({
        entityData: data,
        companyData: data.company,
        signatories: data.signatories,
        isFetching: false,
        msgEntity: "",
      });
      var users = data.users;
      if (users.length === 0) {
        this.setState({ msgUser: "Aucun utilisateur associé à cette entité." });
      } else {
        this.setState({ usersList: users, msgUser: "" });
      }
    }

    const priceResponse = await priceApi.getPrice(response.data.id);
    if (priceResponse.error) {
      this.setState({
        isFetching: false,
        msgEntity: "Aucune entité trouvée.",
        msgUser: "Aucun utilisateur associé.",
      });
    } else {
      let data = priceResponse.data;
      this.setState({
        onlinePayment: data,
        isFetching: false,
        msgEntity: "",
      });
    }

    const priceAddResponse = await priceAddApi.getPriceEstablishment(
      response.data?.company?.id,
      response.data?.id
    );
    if (priceAddResponse.error) {
      this.setState({
        isFetching: false,
        msgEntity: "Aucune entité trouvée.",
        msgUser: "Aucun utilisateur associé.",
      });
    } else {
      let data = priceAddResponse.data;
      this.setState({
        addPrice: data,
        isFetching: false,
        msgEntity: "",
        isLoading: false,
      });
    }
  }

  testExists = (testID) => {
    return this.state.addPrice.some(function (el) {
      // eslint-disable-next-line
      return el.testTypeId == testID && el.price > 0;
    });
  };

  render() {
    return (
      <LayoutPageComponent>
        <p className="title">
          <b>Détails établissement</b>
        </p>
        <p>
          <b>Informations société</b>
        </p>
        <p>{this.state.msgEntity}</p>
        {!this.state.isFetching && (
          <CompanyDetailsTableComponent data={this.state.companyData} />
        )}
        <p>
          <b>Informations établissement</b>
        </p>
        <p>{this.state.msgEntity}</p>
        {!this.state.isFetching && (
          <EntityDetailsTable data={this.state.entityData} />
        )}

        <br />
        <br />
        <p style={{ fontWeight: "bold" }}>Facturation</p>
        <Link
          to={{
            pathname: "/company/price/establishment",
            state: {
              companyId: this.state.companyData.id,
              establishment: this.state.entityData,
            },
          }}
        >
          <button
            style={{
              display: "inline-block",
              padding: "0.25rem 1rem",
              backgroundColor: "#4867d8",
              boxShadow: "0 0 20px -10px rgb(0 0 0 / 50%)",
              color: "#ffffff",
              fontWeight: "bold",
              border: "0",
              width: "200px",
              height: "40px",
              borderRadius: "10px",
            }}
          >
            Voir details
          </button>
        </Link>
        <p>{this.state.msgEntities}</p>

        {!this.state.isLoading && this.state.addPrice.length > 0 ? (
          <table className="table-recap">
            <tr className="recap-row">
              <td>
                <p className="recap-label">Email address</p>
              </td>
              <td>
                <p className="label">{this.state.entityData?.company?.email}</p>
              </td>
            </tr>
            <br />
            <br />

            <br />
          </table>
        ) : (
          ""
        )}
        <p>
          <b>Utilisateur(s) associé(s)</b>
        </p>
        <p>{this.state.msgUser}</p>
        <UserListTable
          data={this.state.usersList}
          company={this.state.companyData}
          establishment={this.state.entityData}
        />
        <p>
          <b>Signataires</b>
        </p>
        <div style={{ display: "flex" }}>
          <SignatoryListTable
            data={this.state.signatories}
            establishmentId={this.state.entityData?.id}
          />
          <button
            style={{
              marginLeft: "2rem",
              display: "inline-block",
              padding: "0.25rem 1rem",
              backgroundColor: "#4867d8",
              boxShadow: "0 0 20px -10px rgb(0 0 0 / 50%)",
              color: "#ffffff",
              fontWeight: "bold",
              border: "0",

              borderRadius: "10px",
              cursor: "pointer",
            }}
            onClick={() => this.setState({ show: !this.state.show })}
          >
            {this.state.show ? "Annuler" : "Ajouter"}
          </button>
        </div>
        {this.state.show && (
          <>
            <p>
              <b>Ajouter un signataire</b>
            </p>
            <SignatoryUpload establishmentId={this.state.entityID} />
            <p>
              <b>Modifier le logo</b>
            </p>
            <LogoUpload establishmentId={this.state.entityID} />
          </>
        )}

        <p style={{ display: "inline-block", marginTop: "2rem" }}>
          <b>Establishment configuration</b>
        </p>
        <OnlinePaymentStatus data={this.state.entityData} />
        <p style={{ display: "inline-block", marginBottom: "2rem" }}>
          <b>Online Payment</b>
        </p>
        {this.state.onlinePayment.length >= 3 ? (
          <AddPriceButton
            data={this.state.entityData}
            data1={this.state.onlinePayment}
            disable={this.state.buttonDisabled}
          />
        ) : (
          <AddPriceButton
            data={this.state.entityData}
            data1={this.state.onlinePayment}
            disable={this.state.buttonEnabled}
          />
        )}
        <br />
        <OnlinePaymentList data={this.state.onlinePayment} />
      </LayoutPageComponent>
    );
  }
}

export default EntityDetails;
