import * as requests from "../utils/HttpRequests";
import config from "../config/load_config";

const apiUrl =
  process.env.REACT_APP_API_BASE_URL + config.BASE_ADMIN + config.API.USER;
const endPointUserDetails = config.API.USER_DETAILS;

// Create user
export async function createUser(user) {
  //TO DO: Passer le paramètre token au header
  const response = await requests.doPost(apiUrl, user, {
    "content-type": "application/json",
    auth: localStorage.getItem("token"),
  });
  return response;
}

// Get user
export async function getUser(userID) {
  const response = await requests.doGet(
    apiUrl + endPointUserDetails.replace(":id", userID),
    {
      "content-type": "application/json",
      auth: localStorage.getItem("token"),
    }
  );
  return response;
}

// Update user
export async function updateUser(userID, body) {
  // const url = apiUrl + endPointUserDetails.replace(":id", userID);
  const response = await requests.doPut(
    apiUrl + endPointUserDetails.replace(":id", userID),
    body,
    {
      "content-type": "application/json",
      auth: localStorage.getItem("token"),
    }
  );
  return response;
}
