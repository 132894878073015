import React from 'react';

const ManufacturerTestDetailsComponent = (props) => {
    return (
        <table className="table-recap">
            <tr className="recap-row">
                <td><p className="recap-label">Référence</p></td>
                <td><p className="label">{props.data.reference}</p></td>
            </tr>
            <tr className="recap-row">
                <td><p className="recap-label">Fabriquant</p></td>
                <td><p className="label">{props.data.manufacturer}</p></td>
            </tr>
            <tr className="recap-row">
                <td><p className="recap-label">Nom</p></td>
                <td><p className="label">{props.data.display_name}</p></td>
            </tr>
            <tr className="recap-row">
                <td><p className="recap-label">Marque</p></td>
                <td><p className="label">{props.data.brand}</p></td>
            </tr>
            <tr className="recap-row">
                <td><p className="recap-label">Temps d'incubation</p></td>
                <td><p className="label">{(props.data.incubation.nominal / 60) + " minutes"}</p></td>
            </tr>
            <tr className="recap-row">
                <td><p className="recap-label">Temps d'incubation (min)</p></td>
                <td><p className="label">{(props.data.incubation.min / 60) + " minutes"}</p></td>
            </tr>
            <tr className="recap-row">
                <td><p className="recap-label">Temps d'incubation (max)</p></td>
                <td><p className="label">{(props.data.incubation.max / 60) + " minutes"}</p></td>
            </tr>
            <tr className="recap-row">
                <td><p className="recap-label">Type de test légal</p></td>
                <td><p className="label">{props.data.RDT_type}</p></td>
            </tr>
            <tr className="recap-row">
                <td><p className="recap-label">Pathologie</p></td>
                <td><p className="label">{props.data.disease}</p></td>
            </tr>
            <tr className="recap-row">
                <td><p className="recap-label">Lignes</p></td>
                <td><p className="label">{

                    Object.values(props.data.lines).map((line, index) => {
                        return index === 0 ? line.label : ", " + line.label;
                    })
                }</p></td>
            </tr>
            <tr className="recap-row">
                <td><p className="recap-label">Status</p></td>
                <td><p className="label">{props.data.available ? "Actif" : "Inactif"}</p></td>
            </tr>
        </table>

    );
}

export default ManufacturerTestDetailsComponent;