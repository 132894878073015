const ReaderComponent = (props) => {

    const { name, setReader, className, label } = props;

    const handleValueChange = (e) => {
        setReader(e.target.value);
    }

    return(
        <div>
            <p>{label}</p>
            <select name={name} className={className} onChange={handleValueChange} >
                <option value="">Choix du lecteur</option>
                <option value="manuel">Manuel</option>
                <option value="deki">Deki</option>
                <option value="diso">Diso</option>
            </select>
        </div>
    );
}

export default ReaderComponent;