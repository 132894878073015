import React from "react";
import * as resultApi from "../api/Result";

class ResultNetworkImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pictureUrl: null,
    };
    this.resultId = props.resultId;
    this.token = localStorage.getItem("token");
    this.imageUrl = props.imageUrl;
  }
  componentDidMount() {
    resultApi
      .getResultImage(this.resultId)
      .then((objectUrl) => {
        this.setState({
          pictureUrl: objectUrl,
        });
      })
      .catch((err) => {});
  }

  render() {
    return <img src={this.state.pictureUrl} alt="" />;
  }
}

export default ResultNetworkImage;
