import React from "react";
import * as patientApi from "../../api/Patient";
import LegacyPatientListTable from "../../components/LegacyPatientListTableComponent";
import "../../style/style.scss";
import LayoutPageComponent from "../../components/LayoutPageComponent";

class ListPatientOld extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      msg: "",
      isFetching: false,
      data: [],
    };
  }

  async componentDidMount() {
    this.setState({ isFetching: true, msg: "Patientez svp..." });
    const response = await patientApi.getPatientsOld();

    if (response.error) {
      this.setState({ isFetching: false, msg: "Aucun patient trouvé." });
    } else {
      this.setState({ data: response.data, isFetching: false, msg: "" });
    }
  }

  render() {
    return (
      <LayoutPageComponent>
        <p className="title">Liste des patients</p>
        <p>{this.state.msg}</p>
        {!this.state.isFetching && (
          <LegacyPatientListTable data={this.state.data} />
        )}
      </LayoutPageComponent>
    );
  }
}

export default ListPatientOld;
