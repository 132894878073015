//This Hook represent header component
import React from "react";
import { Link } from "react-router-dom";
import ModalLogout from "./ModalLogout";
import hamburger from "../assets/menu/hamburger.svg";

const HeaderComponent = (props) => {
  const { hideMenu, setHideMenu, smallSize } = props;

  return (
    <div className="header">
      {hideMenu && smallSize && (
        <div onClick={() => setHideMenu(false)} className="hamburger">
          <img src={hamburger} alt={"Open menu"} />
        </div>
      )}
      <div className="logout-button" onClick={() => ModalLogout()}>
        <Link>Déconnexion</Link>
      </div>
    </div>
  );
};

export default HeaderComponent;
