//This Hook represent an action button
import React from 'react';
import CustomLink from './CustomLink';

const ButtonActionComponent = (props) => {

    return (
        <CustomLink className="button-action" tag="div" to={props.pathname}>
            <div className="button-action-content">
                <div className="button-icon-container">
                    <img alt="Icone" src={props.icon} className="button-action-icon" />
                </div>
                <p className="button-action-text">{props.text}</p>
            </div>
        </CustomLink>
    );
}

export default ButtonActionComponent;