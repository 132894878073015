import React, { useState } from "react";
import EmailComponent from "../../components/EmailComponent";
import * as establishmentApi from "../../api/Etablishment";
import AuthWrapper from "../../components/AuthWrapper";

const SignatoryUpload = (props) => {
  const [selectedFile, setSelectedFile] = useState();
  const [isSelected, setIsSelected] = useState(false);
  const [title, setTitle] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [isValid, setIsValid] = useState(false); // for email
  const [message, setMessage] = useState("");
  // eslint-disable-next-line
  const [signatoryStatus, setSignatoryStatus] = useState(true);

  const changeHandler = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size > 1024000) alert("File exeed limit : 1MB");
      else {
        setSelectedFile(event.target.files[0]);
        setIsSelected(true);
      }
    }
  };

  const reset = () => {
    setSelectedFile(null);
    setIsSelected(false);
    setTitle("");
    setFirstname("");
    setLastname("");
    setEmail("");
    setIsValid(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!isValid || !selectedFile) return;

    const formData = new FormData();
    formData.append("signature", selectedFile);
    formData.append("lastName", lastname);
    formData.append("firstName", firstname);
    formData.append("title", title);
    formData.append("establishment", props.establishmentId);
    formData.append("email", email);
    formData.append("isActive", signatoryStatus);

    establishmentApi
      .addSignatory(formData)
      .then((res) => res.json())
      .then((json) => {
        setMessage(`Signatory ${firstname} ${lastname} added`);
        reset(); // reset the fields // TODO make them work with the formData reset
        e.target.reset(); // reset the email
      })
      .catch((err) => console.error("error:" + err));
  };

  return (
    <AuthWrapper>
      <div>
        <p style={{ paddingLeft: "30px" }}>
          <i>{message}</i>
        </p>
        <form className="form" onSubmit={handleSubmit}>
          <div>
            <p>Titre</p>
            <input
              type="text"
              name="title"
              value={title}
              placeholder="Mme, Dr, M."
              className="input-large"
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
          <div>
            <p>Prénom</p>
            <input
              type="text"
              name="firstName"
              value={firstname}
              className="input-large"
              onChange={(e) => setFirstname(e.target.value)}
            />
          </div>
          <div>
            <p>Nom</p>
            <input
              type="text"
              name="lastName"
              value={lastname}
              className="input-large"
              onChange={(e) => setLastname(e.target.value)}
            />
          </div>
          <EmailComponent
            name="email"
            value={setEmail}
            className="input-large"
            label="Email"
            isValid={setIsValid}
          />
          <div>
            <p>File</p>
            <input type="file" name="file" onChange={changeHandler} />
            {isSelected ? (
              <div>
                <p>
                  Filename: {selectedFile?.name}, Size : {selectedFile?.size}{" "}
                  bytes
                </p>
              </div>
            ) : (
              <p>Select a file to show details</p>
            )}
          </div>
          <div>
            <button type="submit" className="btnMain">
              Ajouter
            </button>
          </div>
        </form>
      </div>
    </AuthWrapper>
  );
};

export default SignatoryUpload;
