import * as requests from "../utils/HttpRequests";
import config from "../config/load_config";

const baseUrl = process.env.REACT_APP_API_BASE_URL;
const apiUrl =
  process.env.REACT_APP_API_BASE_URL + config.BASE_ADMIN + config.API.COMPANY;
const endPointCompanyDetails = config.API.COMPANY_DETAILS;

//Create company
export async function createCompany(company) {
  //TO DO: Passer le paramètre token au header
  const response = await requests.doPost(apiUrl, company, {
    "content-type": "application/json",
    auth: localStorage.getItem("token"),
  });
  return response;
}

//Get list of companies
export async function getCompanies() {
  const response = await requests.doGet(apiUrl, {
    auth: localStorage.getItem("token"),
  });
  return response;
}

//Get a company
export async function getCompany(companyID) {
  const response = await requests.doGet(
    apiUrl + endPointCompanyDetails.replace(":id", companyID),
    {
      auth: localStorage.getItem("token"),
    }
  );
  return response;
}

//Get price
export async function getPrice(companyID) {
  const url =
    baseUrl + "/" + config.API.BILLING_COMPANY + "/" + companyID + "/prices";

  const response = await requests.doGet(url, {
    auth: localStorage.getItem("token"),
  });
  return response;
}
//Get price for establishment
export async function getPriceEstablishment(companyID, establishmentId) {
  const url =
    baseUrl +
    "/" +
    config.API.BILLING_COMPANY +
    "/" +
    companyID +
    "/prices/" +
    establishmentId;

  const response = await requests.doGet(url, {
    auth: localStorage.getItem("token"),
  });
  return response;
}

//Update price
export async function updatePrice(companyID, data) {
  const url =
    baseUrl + "/" + config.API.BILLING_COMPANY + "/" + companyID + "/prices";

  const response = await requests.doPut(url, data, {
    "content-type": "application/json",
    auth: localStorage.getItem("token"),
  });
  return response;
}

//Get a company invoice
export async function getCompanyInvoice(companyID, month, year) {
  const response = await requests.doGet(
    `${baseUrl}/billing/monthly/details?month=${month}&year=${year}&company=${companyID}`,
    {
      auth: localStorage.getItem("token"),
    }
  );
  return response;
}

//Get a company where invoices are generated
export async function getInvoiceGeneratedCompany(month, year) {
  const response = await requests.doGet(
    `${baseUrl}/billing/invoice?month=${month}&year=${year}`,
    {
      auth: localStorage.getItem("token"),
    }
  );
  return response;
}

//Update a email invoice
export async function updateInvoice(invoiceId, data) {
  const response = await requests.doPatch(
    `${baseUrl}/billing/invoice/${invoiceId}`,
    data,
    {
      "content-type": "application/json",
      auth: localStorage.getItem("token"),
    }
  );
  return response;
}

//Generate Sepa
export async function generateSepaXML(data) {
  const response = await requests.doPost(
    `${baseUrl}/billing/invoice/generate-sepa-xml`,
    data,
    {
      "content-type": "application/json",
      auth: localStorage.getItem("token"),
    }
  );
  return response;
}

//Upload Sepa file
export async function uploadSepaFile(file) {
  const formData = new FormData();

  formData.append("sepa-account-data", file);

  const response = await requests.doPostRaw(
    `${baseUrl}/private/v0/billingInput/upload`,
    formData,
    {
      //"content-type": "multipart/form-data",
      auth: localStorage.getItem("token"),
    }
  );
  return response;
}
