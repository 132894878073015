import React from "react";
import ResultDetailsTable from "../../components/ResultDetailsComponent";
import LayoutPageComponent from "../../components/LayoutPageComponent";

class ResultDetails extends React.Component {
  constructor(props) {
    super(props);

    this.resultID = this.props.history.location["state"]
      ? this.props.history.location["state"]
      : null;

    this.state = {
      isFetching: false,
      msgResult: this.resultData === null ? "Aucun résultat sélectionné" : "",
      resultID: this.resultID !== null ? this.resultID : "",
      resultData: {},
    };
  }

  render() {
    return (
      <LayoutPageComponent>
        <p className="title">Détails résultat</p>
        <p>{this.state.msgResult}</p>
        {/*!this.state.isFetching && <ResultDetailsTable data={this.state.resultData} />*/}
        <ResultDetailsTable data={JSON.parse(this.resultID)} />
      </LayoutPageComponent>
    );
  }
}

export default ResultDetails;
