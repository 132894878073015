import React from "react";
import * as manufacturerApi from "../../api/Manufacturer";
import ManufacturerDetailsTableComponent from "../../components/ManufacturerTestDetailsComponent";
import LayoutPageComponent from "../../components/LayoutPageComponent";

class ManufacturerTestDetails extends React.Component {
  constructor(props) {
    super(props);

    this.manufacturerTestID = this.props.history.location["state"]
      ? this.props.history.location["state"]
      : null;

    this.state = {
      isFetching: false,
      msg: this.manufacturerTestID === null ? "Aucun test sélectionné" : "",
      manufacturerTestID:
        this.manufacturerTestID !== null ? this.manufacturerTestID : "",
      manufacturerData: {},
    };
  }

  async componentWillMount() {
    this.setState({ isFetching: true, msg: "Patientez svp..." });
    const response = await manufacturerApi.getManufacturerTest(
      this.state.manufacturerTestID
    );

    if (response.error) {
      this.setState({ isFetching: false, msg: "Aucun test trouvé." });
    } else {
      var data = response.data;
      this.setState({ manufacturerData: data, isFetching: false, msg: "" });
    }
  }

  render() {
    return (
      <LayoutPageComponent>
        <p className="title">Détails du test</p>
        <p>{this.state.msg}</p>
        {!this.state.isFetching && (
          <ManufacturerDetailsTableComponent
            data={this.state.manufacturerData}
          />
        )}
      </LayoutPageComponent>
    );
  }
}

export default ManufacturerTestDetails;
