import React, { useEffect, useState } from "react";
import * as companyApi from "../../api/Company";

import { useHistory } from "react-router-dom";
import Select from "react-select";

import LayoutPageComponent from "../../components/LayoutPageComponent";

const AddPriceNoRange = (props) => {
  var testTypesData = [
    { value: "1", label: "COVID-19 Antigenic Test" },
    { value: "2", label: "COVID-19 PCR Test" },
    { value: "3", label: "COVID-19 PCR Premium" },
  ];
  const history = useHistory();

  const [message, setMessage] = useState("");
  const [selectedTestOption, setSelectedTestOption] = useState(null);
  const [priceData, setPriceData] = useState([]);

  const [price, setPrice] = useState("");
  const [tax, setTax] = useState("");

  // eslint-disable-next-line
  useEffect(async () => {
    try {
      const response = await companyApi.getPrice(
        props.location.state?.companyId
      );
      setPriceData(response.data);
    } catch (e) {}
  }, [props.location.state?.companyId]);

  const testExists = (testID) => {
    return priceData.some(function (el) {
      // eslint-disable-next-line
      return el.testTypeId == testID;
    });
  };

  if (testExists(1)) {
    // setTestType([
    //   { value: "2", label: "COVID-19 PCR Test" },
    //   { value: "3", label: "COVID-19 PCR Premium" },
    // ]);
    testTypesData = [
      { value: "2", label: "COVID-19 PCR Test" },
      { value: "3", label: "COVID-19 PCR Premium" },
    ];
  }
  if (testExists(2)) {
    // setTestType([
    //   { value: "1", label: "COVID-19 Antigenic Test" },

    //   { value: "3", label: "COVID-19 PCR Premium" },
    // ]);

    testTypesData = [
      { value: "1", label: "COVID-19 Antigenic Test" },
      { value: "3", label: "COVID-19 PCR Premium" },
    ];
  }
  if (testExists(3)) {
    // setTestType([
    //   { value: "1", label: "COVID-19 Antigenic Test" },
    //   { value: "2", label: "COVID-19 PCR Test" },
    // ]);
    testTypesData = [
      { value: "1", label: "COVID-19 Antigenic Test" },
      { value: "2", label: "COVID-19 PCR Test" },
    ];
  }
  if (testExists(1) && testExists(2)) {
    // setTestType([{ value: "3", label: "COVID-19 PCR Premium" }]);
    testTypesData = [{ value: "3", label: "COVID-19 PCR Premium" }];
  }
  if (testExists(1) && testExists(3)) {
    // setTestType([{ value: "2", label: "COVID-19 PCR Test" }]);
    testTypesData = [{ value: "2", label: "COVID-19 PCR Test" }];
  }
  if (testExists(2) && testExists(3)) {
    // setTestType([{ value: "1", label: "COVID-19 Antigenic Test" }]);
    testTypesData = [{ value: "1", label: "COVID-19 Antigenic Test" }];
  } else if (testExists(1) && testExists(2) && testExists(3)) {
    // setTestType([]);
    testTypesData = [];
  }

  const handleChangeTestType = (selectedTestOption) => {
    setSelectedTestOption(selectedTestOption);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const postData1 = {
      testTypeId: Number(selectedTestOption.value),
      establishmentId: props.location.state.establishment.id,
      establishmentName: props.location.state.establishment.name,
      price: price,
      tax: Number(tax),
      isRanged: 0,
    };

    const id = props.location.state.companyId;

    await companyApi
      .updatePrice(id, postData1)
      .then((response) => {
        setMessage("price updated");
        history.goBack();
      })
      .catch((err) => console.error("error:" + err));
  };

  // const testTypesData = [
  //   { value: "1", label: "COVID-19 Antigenic Test" },
  //   { value: "2", label: "COVID-19 PCR Test" },
  //   { value: "3", label: "COVID-19 PCR Premium" },
  // ];

  return (
    <LayoutPageComponent>
      <p className="title">
        <b>Modifier</b>
      </p>
      <p style={{ paddingLeft: "30px" }}>
        <i>{message}</i>
      </p>
      <form className="form" onSubmit={handleSubmit}>
        <div style={{ width: "35%" }}>
          <p> Sélectionnez le type de test : </p>
          <Select
            value={selectedTestOption}
            onChange={handleChangeTestType}
            options={testTypesData}
          />
        </div>

        <br />

        <div>
          <br />
          <div>
            <p>Price</p>
            <input
              type="number"
              name="price"
              value={price}
              className="input-large"
              onChange={(e) => setPrice(e.target.value)}
            />
          </div>
        </div>

        <div>
          <p>Tax</p>
          <input
            type="text"
            name="tax"
            value={tax}
            className="input-large"
            onChange={(e) => setTax(e.target.value)}
          />
        </div>

        <div>
          <button type="submit" className="btnMain">
            Mettre à jour
          </button>
        </div>
      </form>
    </LayoutPageComponent>
  );
};

export default AddPriceNoRange;
