import React from "react";
import Datatable from "./DatatableComponent";
import "../style/style.scss";

const OnlinePaymentList = (props) => {
  //Get column from ListUserHeader in pages configuration
  const columns = [
    {
      Header: "Test Type",
      accessor: "testTypeId",
      isLink: false,
      sortable: true,
    },
    {
      Header: "Montant",
      accessor: "amount",
      isLink: false,
    },
    {
      Header: "Service Charge",
      accessor: "serviceCharge",
      isLink: false,
    },
    {
      Header: "Total",
      accessor: "total",
      isLink: false,
    },

    {
      Header: "Modify",
      accessor: "edit.toShow",
      isLink: true,
    },
  ];

  let onlinePaymentData = [];

  // eslint-disable-next-line
  props.data.map((element) => {
    const dataForEdit = {
      price: element,
    };
    onlinePaymentData.push({
      testTypeId:
        element.testTypeId === 1
          ? "Antigen"
          : element.testTypeId === 2
          ? "RT-PCR"
          : "PCR Premium",
      amount: element.amount,
      serviceCharge: element.serviceCharge,
      total: element.total,
      edit: {
        toShow: "Modifier",
        linkTo: JSON.stringify(dataForEdit),
        pathName: "/update-price",
      },
    });
  });

  return <Datatable columns={columns} data={onlinePaymentData} />;
};

export default OnlinePaymentList;
