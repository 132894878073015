import React from "react";
import * as companyApi from "../../api/Company";
import CompanyDetailsTable from "../../components/CompanyDetailsTableComponent";
import EntityListTable from "../../components/EntityListTableComponent";
import UserListTable from "../../components/UserListTableComponent";
import LayoutPageComponent from "../../components/LayoutPageComponent";
import { Link } from "react-router-dom";

class CompanyDetails extends React.Component {
  constructor(props) {
    super(props);

    this.companyID = this.props.history.location["state"]
      ? this.props.history.location["state"]
      : null;

    this.state = {
      isFetching: false,
      msgCompany:
        this.companyData === null ? "Aucune compagnie sélectionnée" : "",
      msgEntities: "",
      msgUser: "",
      companyID: this.companyID !== null ? this.companyID : "",
      companyData: {},
      entitiesList: [],
      usersList: [],
    };
  }

  async componentWillMount() {
    this.setState({ isFetching: true, msgCompany: "Patientez svp..." });
    const response = await companyApi.getCompany(this.state.companyID);

    if (response.error) {
      this.setState({
        isFetching: false,
        msgCompany: "Aucune compagnie trouvée.",
        msgEntities: "Aucune entité associée.",
      });
    } else {
      var data = response.data;
      this.setState({ companyData: data, isFetching: false, msgCompany: "" });
      //Check if this company associated to an entity
      var entities = data.establishments;
      if (entities.length === 0) {
        this.setState({
          msgEntities: "Aucune entité associée à cette société.",
        });
      } else {
        this.setState({ entitiesList: entities, msgEntities: "" });
      }
      //Check if this etablishment has users
      var users = data.users;
      if (users.length === 0) {
        this.setState({
          msgUser: "Aucun utilisateur associé à cette société.",
        });
      } else {
        this.setState({ usersList: users, msgUser: "" });
      }
    }
  }

  render() {
    return (
      <LayoutPageComponent>
        <p className="title">Détails société</p>
        <p>Informations société</p>
        <p>{this.state.msgCompany}</p>
        {!this.state.isFetching && (
          <CompanyDetailsTable data={this.state.companyData} />
        )}

        <br />
        <p style={{ fontWeight: "bold" }}>Facturation</p>
        <Link
          to={{
            pathname: "/company/price",
            state: { companyData: this.state.companyData },
          }}
        >
          <button
            style={{
              display: "inline-block",
              padding: "0.25rem 1rem",
              backgroundColor: "#4867d8",
              boxShadow: "0 0 20px -10px rgb(0 0 0 / 50%)",
              color: "#ffffff",
              fontWeight: "bold",
              border: "0",
              width: "200px",
              height: "40px",
              borderRadius: "10px",
              cursor: "pointer",
            }}
          >
            Voir details
          </button>
        </Link>
        <p>{this.state.msgEntities}</p>

        <table className="table-recap">
          <tr className="recap-row">
            <td>
              <p className="recap-label">Email address</p>
            </td>
            <td>
              <p className="label">{this.state.companyData.email}</p>
            </td>
          </tr>
        </table>

        {/* {!this.state.isFetching && (
          <FacturationDetailsTableComponent data={this.state.companyData} />
        )} */}

        <p>Établissement(s) associé(s)</p>
        <p>{this.state.msgEntities}</p>
        <EntityListTable data={this.state.entitiesList} />
        <p>Utilisateur(s) associé(s)</p>
        <p>{this.state.msgUser}</p>
        <UserListTable
          data={this.state.usersList}
          company={this.state.companyData}
        />
      </LayoutPageComponent>
    );
  }
}

export default CompanyDetails;
