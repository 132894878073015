import React from "react";
import * as etablishmentApi from "../../api/Etablishment";
import "../../style/style.scss";
import LayoutPageComponent from "../../components/LayoutPageComponent";

// DO not commit yet
class Statistics extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      msg: "",
      isFetching: false,
      sidep: [],
      link: "",
    };
  }

  downloadCSV() {
    // console.log("in download")
    etablishmentApi.getStatsCSV().then((value) =>
      this.setState({
        link: value,
      })
    );
  }

  render() {
    return (
      <LayoutPageComponent>
        <p className="title">Statistiques</p>
        <p>Cliquez pour obtenir le csv</p>
        <p>Puis ouvrer le lien pour télécharger</p>
        <button onClick={() => this.downloadCSV()} className="btnMain">
          Get
        </button>
        <a href={this.state.link}>{this.state.link}</a>
      </LayoutPageComponent>
    );
  }
}

export default Statistics;
