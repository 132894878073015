import * as requests from "../utils/HttpRequests";
import config from "../config/load_config";
import { fetchThenUrl } from "./shared";

const apiUrlAdmin = process.env.REACT_APP_API_BASE_URL + config.BASE_ADMIN;
const apiUrlPrivate = process.env.REACT_APP_API_BASE_URL + config.BASE_PRIVATE;

//Get list of results
export async function getResults(userEtablishment, requestDate) {
  const response = await requests.doGet(
    apiUrlAdmin +
      config.API.RESULT.replace("{userEtablishment}", userEtablishment).replace(
        "{requestDate}",
        requestDate
      ),
    {
      "content-type": "application/json",
      auth: localStorage.getItem("token"),
    }
  );
  return response;
}

//Get a result
export async function getResult(resultID) {
  const response = await requests.doGet(
    apiUrlAdmin + config.API.RESULT_DETAILS.replace(":id", resultID),
    {
      "content-type": "application/json",
      auth: localStorage.getItem("token"),
    }
  );
  return response;
}

//Download a patient report
export async function downloadPatientReport(patientID) {
  const response = await requests.download(
    apiUrlAdmin + config.API.PATIENT_REPORT.replace(":id", patientID),
    patientID,
    {
      "content-type": "application/json",
      auth: localStorage.getItem("token"),
    }
  );
  return response;
}

export const getResultImage = async (resultId) => {
  const imageUrl = `${apiUrlPrivate}/result/${resultId}/picture`;
  return fetchThenUrl(imageUrl, {
    method: "GET",
    headers: {
      auth: localStorage.getItem("token"),
    },
  });
};

export const getResultDocuments = async (resultId) => {
  const documentURL = `${apiUrlPrivate}${config.API.DOCUMENTS}/${resultId}`;
  return fetchThenUrl(documentURL, {
    method: "GET",
    headers: {
      auth: localStorage.getItem("token"),
    },
  });
};
