import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./pages/home";
import CreateUser from "./pages/users/create_user";
import UserDetails from "./pages/users/user_details";
import CreateCompany from "./pages/company/create_company";
import ListCompany from "./pages/company/list_company";
import CompanyDetails from "./pages/company/company_details";
import CreateEtablishment from "./pages/establishment/create_etablishment";
import ListEtablishment from "./pages/establishment/list_etablishment";
import EtablishmentDetails from "./pages/establishment/etablishment_details";
import Auth from "./pages/auth/login";
import ListPatient from "./pages/patients/list_patient";

import ExportPatient from "./pages/patients/export_patient";

import ListPatientOld from "./pages/patients/list_patient_old";
import PatientDetails from "./pages/patients/patient_details";
import PatientWithResult from "./pages/patients/patient_with_result";
import ListResult from "./pages/results/list_result";
import ResultDetails from "./pages/results/result_details";
import CreateManufacturerTest from "./pages/manufacturer/create_manufacturer";
import UpdateManufacturerTest from "./pages/manufacturer/update_manufacturer";
import ListManufacturerTest from "./pages/manufacturer/list_manufacturer";
import ManufacturerTestDetails from "./pages/manufacturer/manufacturer_details";
import Statistics from "./pages/statistic/statitics";
import UploadPatientList from "./pages/patients/upload_patient_list";
import UpdateSignatory from "./pages/establishment/update_signatory";
import Invoicing from "./pages/company/invoicing";
import PackingList from "./pages/packing/packing-list";
import PackingDetails from "./pages/packing/packing-details";
import Sepa from "./pages/company/sepa";
import UploadSepa from "./pages/company/upload_sepa";
import Payment from "./pages/payment/Payment";
import UpdatePrice from "./pages/establishment/update_price";
import AddOnlinePayment from "./pages/establishment/AddOnlinePayment";
import PatientDetailsNew from "./pages/patients/patient_detailsNew";
import NewPatientDetails from "./pages/patients/new_patient_details";
import AddPrice from "./pages/company/add_price";

import ModifyPrice from "./pages/company/modifyPrice";

import AddPriceNoRange from "./pages/establishment/add_priceNoRange";
import ModifyPriceInEstablishment from "./pages/establishment/modifyPriceInEstablishment";

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Auth} />
        <Route exact path="/home" component={Home} />
        <Route exact path="/create-user" component={CreateUser} />
        <Route exact path="/user-details" component={UserDetails} />
        <Route exact path="/create-company" component={CreateCompany} />
        <Route exact path="/list-company" component={ListCompany} />
        <Route exact path="/payment" component={Payment} />
        <Route exact path="/company-details" component={CompanyDetails} />
        <Route exact path="/invoicing" component={Invoicing} />
        <Route exact path="/sepa" component={Sepa} />
        <Route exact path="/import-sepa" component={UploadSepa} />
        <Route
          exact
          path="/create-etablishment"
          component={CreateEtablishment}
        />
        <Route exact path="/list-etablishment" component={ListEtablishment} />
        <Route
          exact
          path="/etablishment-details"
          component={EtablishmentDetails}
        />
        <Route exact path="/update-signatory" component={UpdateSignatory} />
        <Route exact path="/payment/price" component={AddOnlinePayment} />
        <Route exact path="/company/price" component={AddPrice} />
        <Route exact path="/company/price/modify" component={ModifyPrice} />
        <Route
          exact
          path="/company/price/establishment"
          component={AddPriceNoRange}
        />
        <Route
          exact
          path="/company/price/establishment/modify"
          component={ModifyPriceInEstablishment}
        />

        <Route exact path="/update-price" component={UpdatePrice} />
        <Route exact path="/list-patient" component={ListPatient} />
        <Route exact path="/export-patient" component={ExportPatient} />
        <Route exact path="/import-patient" component={UploadPatientList} />
        <Route exact path="/list-patient-old" component={ListPatientOld} />
        <Route exact path="/patient-details" component={PatientDetails} />
        <Route
          exact
          path="/patient-details-new"
          component={PatientDetailsNew}
        />
        <Route
          exact
          path="/new-patient-details"
          component={NewPatientDetails}
        />
        <Route
          exact
          path="/patient-with-result"
          component={PatientWithResult}
        />
        <Route exact path="/list-result" component={ListResult} />
        <Route exact path="/result-details" component={ResultDetails} />
        <Route
          exact
          path="/create-manufacturer-test"
          component={CreateManufacturerTest}
        />
        <Route
          exact
          path="/edit-manufacturer-test"
          component={UpdateManufacturerTest}
        />
        <Route
          exact
          path="/list-manufacturer-test"
          component={ListManufacturerTest}
        />
        <Route
          exact
          path="/manufacturer-test-details"
          component={ManufacturerTestDetails}
        />
        <Route exact path="/stats" component={Statistics} />
        <Route exact path="/packing-list" component={PackingList} />
        <Route exact path="/packing-details" component={PackingDetails} />
        <Route component={Auth} />
      </Switch>
    </Router>
  );
}

export default App;
