import React, { useState, useEffect } from "react";
import CountryComponent from "./CountryComponent";

const AddressComponent = (props) => {
  const { address, setAddress } = props;

  var addresValues = {};

  const [street, setStreet] = useState("");
  const [zip, setZip] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");

  //Get all changes of address
  addresValues.street = street;
  addresValues.zip = zip;
  addresValues.city = city;
  addresValues.country = country;

  //Handle fields change
  useEffect(() => {
    setAddress(addresValues);
    // eslint-disable-next-line
  }, [street, zip, city, country]);

  return (
    <div>
      <div>
        <p>Adresse *</p>
        <input
          type="text"
          name="street"
          value={address.street}
          className="input-large"
          onChange={(e) => setStreet(e.target.value)}
        />
      </div>
      <div>
        <p>Code Postal *</p>
        <input
          type="text"
          name="zip"
          value={address.zip}
          className="input-large"
          onChange={(e) => setZip(e.target.value)}
        />
      </div>
      <div>
        <p>Ville *</p>
        <input
          type="text"
          name="city"
          value={address.city}
          className="input-large"
          onChange={(e) => setCity(e.target.value)}
        />
      </div>
      <CountryComponent
        name="country"
        value={address.country}
        className="input-large"
        label="Pays *"
        setCountry={setCountry}
      />
    </div>
  );
};

export default AddressComponent;
