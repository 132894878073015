import * as requests from "../utils/HttpRequests";
import config from "../config/load_config";

const apiUrl = process.env.REACT_APP_API_BASE_URL + config.API.PAYMENT;

//Get payment details
export async function getPayment(data) {
  //   const payload = { establishment, startDate, endDate, testType };
  const response = await requests.doPost(apiUrl, data, {
    "content-type": "application/json",
    auth: localStorage.getItem("token"),
  });
  return response;
}

export async function downloadReceipt(refId) {
  window.open(
    process.env.REACT_APP_API_BASE_URL + "/payment/" + refId + "/receipt",
    "_blank"
  );
}
