//This Hook represent the user creation page
import { useState } from "react";
import { withRouter } from "react-router-dom";
import * as authApi from "../api/Auth";
import EmailComponent from "./EmailComponent";
import CustomLink from "./CustomLink";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

const LoginComponent = (props) => {
  const [isValid, setIsValid] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  //const [userLogged, setUserLogged] = useState("");

  const goHome = () => {
    props.history.push({
      pathname: "/home",
    });
  };

  //Create user when form is submitted
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isValid && password.trim() !== "") {
      const credentials = {
        email: email,
        password: password,
      };
      const response = await authApi.signIn(credentials);
      if (response.error) {
        alert("Echec d'authentification !");
      } else {
        localStorage.setItem("token", response.data.token);
        // localStorage.setItem(
        //   "isPaymentEnable",
        //   response.data.establishment.isPaymentEnabled
        // ); //todo

        localStorage.setItem("establishment", response.data.establishment.id);
        localStorage.setItem(
          "establishmentName",
          response.data.establishment.name
        );
        localStorage.setItem("isAdmin", response.data.admin);
        localStorage.setItem("isOperator", response.data.isOperator);
        if (
          // eslint-disable-next-line
          response.data.establishment.id == 111 ||
          // eslint-disable-next-line
          response.data.establishment.id == 112 ||
          response.data.admin
        ) {
          localStorage.setItem("canImport", "true");
        } else {
          localStorage.setItem("canImport", "true");
        }
        localStorage.setItem(
          "user",
          JSON.stringify({
            name: response.data.firstName,
            key: response.data.admin,
            company: response.data.company.id,
            isOperator: response.data.isOperator,
            etablishment: response.data.establishment.id,
            payment: response.data.establishment.isPaymentEnabled,
            email: response.data.email,
          })
        );

        goHome();
      }
    } else {
    }
  };

  const handlePasswordVisibility = (e) => {
    setShowPassword(!showPassword);
  };

  return (
    <div>
      <div className="login-field-container">
        <p className="subtitle-white">Connexion</p>
        <form className="form" onSubmit={handleSubmit}>
          <EmailComponent
            name="email"
            value={setEmail}
            className="input-large"
            placeholder="Identifiant"
            isValid={setIsValid}
          />
          <div className={"password-container"}>
            <input
              type={showPassword ? "text" : "password"}
              name="password"
              className="input-large small-margin"
              placeholder="Mot de passe"
              onChange={(e) => setPassword(e.target.value)}
            />
            <i onClick={handlePasswordVisibility} className={"password-icon"}>
              {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
            </i>
          </div>
          <div className="login-link-container">
            <CustomLink tag="a" to="" className="link-underline">
              Mot de passe oublié ?
            </CustomLink>
          </div>
          <div>
            <button type="submit" className="btnSecondary">
              Connexion
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default withRouter(LoginComponent);
