//This Hook represent the user creation page
import React, { useState } from "react";
import * as companyApi from "../../api/Company";
import CompanyTypeComponent from "../../components/CompanyTypeComponent";
import AddressComponent from "../../components/AddressComponent";
import "../../style/style.scss";
import LayoutPageComponent from "../../components/LayoutPageComponent";

const CreateCompany = (props) => {
  const [name, setName] = useState("");
  const [companyType, setCompanyType] = useState("");
  const [address, setAddress] = useState("");
  const [administrativeNumber, setAdministrativeNumber] = useState("");
  const [msg, setMsg] = useState("");
  // const [email, setEmail] = useState("");
  // const [testType, setTestType] = useState("");
  // const [range, setRange] = useState("");

  const [isLoading, setLoading] = useState(false);

  //Create user when form is submitted
  const handleSubmit = async (e) => {
    setMsg("");
    setLoading(true);
    e.preventDefault();
    if (
      name.trim() !== "" &&
      companyType.trim() !== "" &&
      address.street.trim() !== "" &&
      address.zip.trim() !== "" &&
      address.city.trim() !== "" &&
      address.country !== "" &&
      administrativeNumber !== ""
    ) {
      const company = {
        name: name,
        type: companyType,
        address: address,
        administrativeNumber: administrativeNumber,
      };
      const response = await companyApi.createCompany(company);
      setLoading(false);
      setMsg(
        response.error
          ? response.data["message"]
          : "La création de cette société s'est éffectuée avec succès."
      );
    } else {
      setMsg("Remplissez correctement les champs svp.");
    }
  };

  return (
    <LayoutPageComponent>
      <p className="title">Création d’une société</p>
      <form className="form" onSubmit={handleSubmit}>
        <div>
          <p>Nom de la société *</p>
          <input
            type="text"
            name="name"
            className="input-large"
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <CompanyTypeComponent
          name="type"
          className="input-large"
          label="Type de société *"
          setCompanyType={setCompanyType}
        />
        <AddressComponent setAddress={setAddress} address={address} />
        <div>
          <p>N° SIREN, DUNS, ACS *</p>
          <input
            type="text"
            name="administrativeNumber"
            className="input-large"
            onChange={(e) => setAdministrativeNumber(e.target.value)}
          />
        </div>

        <div>
          <button disabled={isLoading} type="submit" className="btnMain">
            Valider
          </button>
        </div>
        <p>{msg}</p>
      </form>
    </LayoutPageComponent>
  );
};

export default CreateCompany;
