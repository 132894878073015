import React from "react";

const BillingCompanyDetailsTableComponent = (props) => {
  return (
    <table className="table-recap">
      <tr className="recap-row">
        <td>
          <p className="recap-label">Nom du compte</p>
        </td>
        <td>
          <p className="label">{props.data.companyName}</p>
        </td>
      </tr>
      <tr className="recap-row">
        <td>
          <p className="recap-label">ID</p>
        </td>
        <td>
          <p className="label">{props.data.companyId}</p>
        </td>
      </tr>
      <tr className="recap-row">
        <td>
          <p className="recap-label">Adresse de facturation</p>
        </td>
        <td>
          <p className="label">{`${props.data?.details?.tests[0]?.adr_st?.trim()}, ${
            props.data?.details?.tests[0]?.adr_zip
          } ${props.data?.details?.tests[0]?.adr_ct} (${
            props.data?.details?.tests[0]?.adr_cntr
          })`}</p>
        </td>
      </tr>
      <tr className="recap-row">
        <td>
          <p className="recap-label">No. SIREN</p>
        </td>
        <td>
          <p className="label">{props.data?.details?.tests[0]?.companysiren}</p>
        </td>
      </tr>
      <tr className="recap-row">
        <td>
          <p className="recap-label">TVA</p>
        </td>
        <td>
          <p className="label">{props.data.tax}%</p>
        </td>
      </tr>
    </table>
  );
};

export default BillingCompanyDetailsTableComponent;
