import * as requests from "../utils/HttpRequests";
import config from "../config/load_config";

const apiUrl = process.env.REACT_APP_API_BASE_URL + config.BASE_ADMIN;

//Get list of patients
export async function getPatients(establishment, requestDate) {
  const response = await requests.doGet(
    apiUrl +
      config.API.PATIENT_SEARCH.replace(
        "{establishment}",
        establishment
      ).replace("{requestDate}", requestDate),
    {
      "content-type": "application/json",
      auth: localStorage.getItem("token"),
    }
  );

  return response;
}

//Get list of patients
export async function getPatientsDashboard(requestDate) {
  const response = await requests.doGet(
    apiUrl +
      config.API.PATIENT_DASHBOARD_SEARCH.replace("{requestDate}", requestDate),
    {
      "content-type": "application/json",
      auth: localStorage.getItem("token"),
    }
  );
  return response;
}
// Deprecated
//Get list of patients
export async function getPatientsOld() {
  const response = await requests.doGet(apiUrl + config.API.PATIENT, {
    "content-type": "application/json",
    auth: localStorage.getItem("token"),
  });
  return response;
}

//Get a patient
export async function getPatient(patientID) {
  const response = await requests.doGet(
    apiUrl + config.API.PATIENT_DETAILS.replace(":id", patientID),
    {
      "content-type": "application/json",
      auth: localStorage.getItem("token"),
    }
  );
  return response;
}

//Download a patient report
export async function downloadPatientReport(patientID) {
  const response = await requests.download(
    apiUrl + config.API.PATIENT_REPORT.replace(":id", patientID),
    patientID,
    {
      "content-type": "application/json",
      auth: localStorage.getItem("token"),
    }
  );
  return response;
}

//Get a patients to export
export async function getPatientTestExport(
  establishmentId,
  startDate,
  endDate,
  testTypeId,
  nationalityType
) {
  const response = await requests.doGet(
    apiUrl +
      config.API.PATIENT +
      `/export?establishment=${establishmentId}&minDate=${startDate}&maxDate=${endDate}&testTypeId=${testTypeId}&nationalityType=${nationalityType}`,
    {
      "content-type": "application/json",
      auth: localStorage.getItem("token"),
    }
  );
  return response;
}

//get tubid
export async function getTube(tubeID) {
  const url = apiUrl + config.API.TUBE + "/" + tubeID;

  const response = await requests.doGet(url, {
    "content-type": "application/json",
    auth: localStorage.getItem("token"),
  });

  return response;
}
