import React from "react";
import { Link } from "react-router-dom";

import LayoutPageComponent from "../../components/LayoutPageComponent";

import FacturationDetailsTableComponent from "../../components/FacturationDetailsTableComponent";

const AddPrice = (props) => {
  return (
    <LayoutPageComponent>
      <FacturationDetailsTableComponent
        data={props.location.state.companyData}
      />

      <Link
        to={{
          pathname: "/company/price/modify",
          state: { companyId: props.location.state.companyData.id },
        }}
      >
        <button
          style={{
            display: "inline-block",
            padding: "0.25rem 1rem",
            backgroundColor: "#4867d8",
            boxShadow: "0 0 20px -10px rgb(0 0 0 / 50%)",
            color: "#ffffff",
            fontWeight: "bold",
            border: "0",
            width: "200px",
            height: "40px",
            borderRadius: "10px",
            cursor: "pointer",
          }}
        >
          Modifier
        </button>
      </Link>
    </LayoutPageComponent>
  );
};

export default AddPrice;
