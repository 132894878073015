import React from "react";
import { Link } from "react-router-dom";

const AddPriceButton = (props) => {
  return (
    <Link
      to={{
        pathname: "/payment/price",
        state: { props },
      }}
    >
      <button
        style={{
          display: "inline-block",
          marginLeft: "4rem",
          padding: "0.25rem 1rem",
          backgroundColor: "#4867d8",
          boxShadow: "0 0 20px -10px rgb(0 0 0 / 50%)",
          color: "#ffffff",
          fontWeight: "bold",
          border: "0",
          width: "200px",
          height: "40px",
          borderRadius: "10px",
        }}
        disabled={props.disable}
      >
        Add
      </button>
    </Link>
  );
};

export default AddPriceButton;
