import React from "react";
import {Checkbox, FormControlLabel, RadioGroup} from "@material-ui/core";

const Lines = (props) => {

    let linesSelectedObject = props.selectedLines;

    const linesObject = {
        C: {
            label: "Control Line",
            choices: [
                "valid",
                "invalid",
                "unknown"
            ]
        },
        Ag: {
            label: "Ag",
            choices: [
                "Positive",
                "Negative",
                "unknown"
            ]
        }
    }

    const handleChange = (e) => {

        const key = e.target.value;

        if(e.target.checked) {
            linesSelectedObject[key] = linesObject[key];
        }else {
            delete linesSelectedObject[key];
        }

        props.setLines(linesSelectedObject);
    }

    return (
        <RadioGroup>
            {
                Object.entries(linesObject).map((line, index) => (
                    <FormControlLabel
                        value={line[0]}
                        control={<Checkbox color="primary" />}
                        label={line[1].label}
                        labelPlacement="right"
                        onChange={handleChange}
                    />
                ))
            }

        </RadioGroup>
    )
}

export default Lines;