import React from "react";
import {
  useTable,
  useSortBy,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
} from "react-table";
import matchSorter from "match-sorter";
import CustomLink from "./CustomLink";
import { pdf } from "@react-pdf/renderer";
import PatientPDF from "../pages/patients/patient_pdf_document";
import iconExport from "../assets/icon-patients/iconExport.png";
import { saveAs } from "file-saver";
import QRCode from "qrcode";

const DatatableComponent = (props) => {
  const columns = props.columns;
  const data = props.data;
  const handleAction = props.action;
  const displayFilterBar = props.showFilterBar;
  const setFilterValue = props.setFilterValue;
  const initialState = props.initialState;

  function fuzzyTextFilterFn(rows, id, filterValue) {
    return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
  }

  const handleDownloadPdf = async (pdfDocument, pdfDocumentName) => {
    const doc = pdfDocument;
    const asPdf = pdf();
    asPdf.updateContainer(doc);
    const blob = await asPdf.toBlob();
    saveAs(blob, `${pdfDocumentName}.pdf`);
  };

  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  function GlobalFilter({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
  }) {
    const count = preGlobalFilteredRows.length;
    const [value, setValue] = React.useState(globalFilter);
    const onChange = useAsyncDebounce((value) => {
      setGlobalFilter(value);
      setValue(value);
      if (setFilterValue) {
        setFilterValue(value);
      }
      document.getElementById("searchInput").focus();
    }, 200);

    return (
      <div className="search-field">
        Rechercher:{" "}
        <input
          id="searchInput"
          type="search"
          value={value}
          onChange={(e) => {
            onChange(e.target.value);
          }}
          placeholder={`${count} records...`}
          style={{
            fontSize: "1.1rem",
            border: "1",
          }}
        />
      </div>
    );
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      filterTypes,
      initialState,
    },
    useFilters,
    useGlobalFilter,
    useSortBy
  );

  return (
    <>
      {displayFilterBar ? (
        <GlobalFilter
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={state.globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
      ) : null}

      <table {...getTableProps()}>
        <thead>
          {/* Generate table headers */}
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  {/* Add a sort direction indicator */}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? " 🔽"
                        : " 🔼"
                      : ""}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {/* Generate rows according to data object in props */}
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {/* For each row, generate all cells. 
                            Means that you need to have same key numbers in json row than header object */}
                {/* eslint-disable-next-line */}
                {row.cells.map((cell) => {
                  if (!cell.column.isLink) {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  } else {
                    if (
                      !cell.row.original[cell.column.id.split(".")[0]]
                        .download &&
                      !cell.row.original[cell.column.id.split(".")[0]].pdf
                    ) {
                      return (
                        <td {...cell.getCellProps()}>
                          <CustomLink
                            className="see-more-link"
                            tag="a"
                            to={{
                              pathname:
                                cell.row.original[cell.column.id.split(".")[0]]
                                  .pathName,
                              state:
                                cell.row.original[cell.column.id.split(".")[0]]
                                  .linkTo,
                            }}
                          >
                            {cell.render("Cell")}
                          </CustomLink>
                        </td>
                      );
                    } else {
                      if (
                        cell.row.original[cell.column.id.split(".")[0]].download
                      ) {
                        return (
                          <td {...cell.getCellProps()}>
                            <CustomLink
                              className={
                                cell.row.original[
                                  cell.column.id.split(".")[0]
                                ].toShow?.toLowerCase() === "positive"
                                  ? "see-more-link-red"
                                  : "see-more-link"
                              }
                              tag="a"
                              onClick={() =>
                                handleAction(
                                  cell.row.original[
                                    cell.column.id.split(".")[0]
                                  ].linkTo
                                )
                              }
                            >
                              {cell.render("Cell")}
                            </CustomLink>
                          </td>
                        );
                      }

                      if (cell.row.original[cell.column.id.split(".")[0]].pdf) {
                        const patient = JSON.parse(
                          cell.row.original[cell.column.id.split(".")[0]].linkTo
                        );
                        return (
                          <td {...cell.getCellProps()}>
                            <img
                              src={iconExport}
                              alt={""}
                              height={20}
                              onClick={() => {
                                const qrUrl = QRCode.toDataURL(patient.id, {
                                  type: "png",
                                });

                                return handleDownloadPdf(
                                  <PatientPDF patient={patient} qr={qrUrl} />,
                                  `${patient.lastName}_${patient.id}`,
                                  qrUrl
                                );
                              }}
                            />
                          </td>
                        );
                      }
                    }
                  }
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};

export default DatatableComponent;
