/*
//This file load config from environment

//Developpement env config
import dev_conf from "./config.dev.json";
//Production env config
import prod_conf from "./config.prod.json";

const config = process.env.NODE_ENV === "production" ? prod_conf : dev_conf;*/

// DOne just for transition
import config from "./api_config.json";

config.DASHBOARD_MODE = true;

export default config;

