import React from "react";
import { useHistory } from "react-router-dom";
import Datatable from "./DatatableComponent";
import ListCompanyHeader from "../pages_config/list_company_header.json";
import * as DateUtils from "../utils/DateUtils";
import { redirectToCompanyDetails } from "../role_management/page_manager";
import "../style/style.scss";

const CompanyListTable = (props) => {
  const history = useHistory();

  redirectToCompanyDetails(history);

  //Get column from ListCompanyHeader in pages configuration
  const columns = ListCompanyHeader;

  var companyData = [];

  props.data.forEach((element) => {
    companyData.push({
      id: element.id,
      administrativeNumber: element.administrativeNumber,
      name: {
        toShow: element.name,
        linkTo: element.id,
        pathName: "/company-details",
      },
      type: element.type,
      zip: element.address.zip,
      createdAt: DateUtils.dateToFrenchFormat(element.createdAt),
      invoicing: {
        toShow: "Generate the invoice",
        linkTo: element.id,
        pathName: "/invoicing",
      },
    });
  });
  // const sortBy = [{ id: "id", desc: false }];
  return (
    <Datatable
      columns={columns}
      data={companyData}
      defaultSortFieldId={1}
      // sortBy={sortBy}
      initialState={{
        sortBy: [
          {
            id: "id",
            desc: false,
          },
        ],
      }}
    />
  );
};

export default CompanyListTable;
