import React from "react";
import LayoutPageComponent from "../../components/LayoutPageComponent";
import Select from "react-select";
import * as establishmentApi from "../../api/Etablishment";
import * as packingApi from "../../api/Packing";
import PackingListTable from "../../components/PackingListTableComponent";
import { Button } from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns"; // import

class PackingList extends React.Component {
  constructor(props) {
    super(props);
    let today = new Date();

    this.state = {
      startDate: today.toISOString(),
      endDate: today.toISOString(),
      msg: "",
      isFetching: true,
      establishmentId: null,
      data: [],
      establishmentsData: [],
      selectedEstablishment: null,
      filterValue: "",
      pouchBarcode: "",
    };
  }

  async componentDidMount() {
    await this.getEstablishment();

    if (this.props.history.action === "POP") {
      const selectedEstablishment = this.recoverState(
        "packing_selectedEstablishment"
      );
      this.setState({ selectedEstablishment });
      await this.handleGetPackingList(selectedEstablishment);
    }
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    if (nextState.filterValue !== "") {
      return false;
    }

    return true;
  }

  async setStartDate(dateValue) {
    await this.setState({
      startDate: dateValue,
    });
  }
  async setEndDate(dateValue) {
    await this.setState({
      endDate: dateValue,
    });
  }
  async getEstablishment() {
    let establishmentArray = [];
    const responseEstablishment = await establishmentApi.getEtablishments();

    if (responseEstablishment.error) {
      this.setState({ isFetching: false, msg: "Aucun établissement trouvé." });
    } else {
      // eslint-disable-next-line
      responseEstablishment.data.map((responseObj, i) => {
        var objEstablishment = {
          value: responseObj.id,
          label: responseObj.name,
        };
        establishmentArray.push(objEstablishment);
      });

      this.setState({
        establishmentsData: establishmentArray,
        isFetching: false,
        msg: "",
      });
    }
  }

  handleChangeEstablishment = async (selectedEstablishment) => {
    try {
      await this.setState({ selectedEstablishment });
      await this.setState({ filterValue: "" });
    } catch (e) {}
  };

  handleStartDate = async (startDate) => {
    try {
      await this.setState({ startDate });
      await this.setState({ filterValue: "" });
      clearInterval(this.interval);
    } catch (e) {}
  };
  handleEndDate = async (endDate) => {
    try {
      await this.setState({ endDate });
      await this.setState({ filterValue: "" });
      clearInterval(this.interval);
    } catch (e) {}
  };

  handleFilterValueChange = (value) => {
    this.setState({ filterValue: value });
  };

  handleGetPackingList = async (selectedEstablishment) => {
    if (selectedEstablishment == null) {
      alert("Sélectionnez l'établissement");
      return;
    }
    var startdateStateValue = new Date(this.state.startDate);
    var startMonth = (startdateStateValue.getMonth() + 1)
      .toString()
      .padStart(2, "0");
    var startDay = startdateStateValue.getDate().toString().padStart(2, "0");
    var startDate =
      startdateStateValue.getFullYear() + "-" + startMonth + "-" + startDay;

    const postdata = {
      establishemntId: selectedEstablishment.value,
      requestDate: startDate,
    };
    const response = await packingApi.getPackingList(postdata);
    if (response.error) {
      this.setState({ isFetching: false, msg: "Aucun emballage trouvé." });
    } else {
      this.setState({ data: response.data, isFetching: false, msg: "" });
    }

    this.persistState("packing_selectedEstablishment", selectedEstablishment);
  };

  handleChangeEstablishment = async (selectedEstablishment) => {
    try {
      await this.setState({ selectedEstablishment });
      await this.setState({ filterValue: "" });
      clearInterval(this.interval);
    } catch (e) {
      console.log("error", JSON.stringify(e));
    }
  };

  handleStartDate = async (startDate) => {
    try {
      await this.setState({ startDate });
      await this.setState({ filterValue: "" });
      clearInterval(this.interval);
    } catch (e) {
      console.log("error", JSON.stringify(e));
    }
  };
  handleEndDate = async (endDate) => {
    try {
      await this.setState({ endDate });
      await this.setState({ filterValue: "" });
      clearInterval(this.interval);
    } catch (e) {
      console.log("error", JSON.stringify(e));
    }
  };

  handleFilterValueChange = (value) => {
    this.setState({ filterValue: value });
  };

  persistState = (key, state) => {
    localStorage.setItem(key, JSON.stringify(state));
  };

  recoverState = (key) => {
    return JSON.parse(localStorage.getItem(key));
  };

  handleSearchPouch = (e) => {
    e.preventDefault();

    let newData = this.state.data?.map((pouch) => {
      const pouchExists = (barcode) => {
        return pouch.pouches.some(function (el) {
          return el.pouchBarcode === barcode;
        });
      };

      return pouchExists(this.state.pouchBarcode) && pouch;
    });

    let filterData = newData.filter((data) => data !== false);

    this.setState({ data: filterData });
  };

  render() {
    const { selectedEstablishment, establishmentsData, msg, data, isFetching } =
      this.state;
    return (
      <LayoutPageComponent>
        <p className="title">Packing list</p>
        <div className="filters">
          <div className="filterControl">
            <p> Sélectionnez la date : </p>

            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                value={this.state.startDate}
                onChange={(date) => this.handleStartDate(date)}
              />
            </MuiPickersUtilsProvider>
            {/* <p> Select End Date : </p>

            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                value={this.state.endDate}
                onChange={(date) => this.handleEndDate(date)}
              />
            </MuiPickersUtilsProvider> */}
          </div>

          <div className="filterControl">
            <p> Sélectionnez l'établissment : </p>
            <Select
              value={selectedEstablishment}
              onChange={this.handleChangeEstablishment}
              options={establishmentsData}
            />
          </div>
          <br />

          <div className="filterControlButtons">
            <Button
              variant="contained"
              color="primary"
              onClick={() => this.handleGetPackingList(selectedEstablishment)}
            >
              Rechercher
            </Button>

            <div></div>
            <div style={{ marginTop: "2rem" }}>
              <input
                placeholder="Code-barres pochette"
                type="text"
                value={this.state.pouchBarcode}
                onChange={(e) =>
                  this.setState({ pouchBarcode: e.target.value })
                }
              />
              <button
                onClick={this.handleSearchPouch}
                style={{ marginLeft: "1rem" }}
              >
                {" "}
                Recherche par pochette{" "}
              </button>
            </div>
          </div>
        </div>

        {/*  */}
        <p>{msg}</p>
        <p>{data.length === 0 && "No packing lists registered."}</p>
        {data.length > 0 && !isFetching && (
          <PackingListTable
            data={data}
            setFilterValue={this.handleFilterValueChange}
          />
        )}
      </LayoutPageComponent>
    );
  }
}

export default PackingList;
