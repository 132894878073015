import React from "react";
import CustomLink from "./CustomLink";
import * as DateUtils from "../utils/DateUtils";

const CassetteDetailsComponent = (props) => {
  return (
    <table className="table-recap">
      <tr className="recap-row">
        <td>
          <p className="recap-label"> Name of the test</p>
        </td>
        <td>
          <CustomLink
            className="see-more-link"
            tag="a"
            to={{
              pathname: "/manufacturer-test-details",
              state: props.data?.manufacturerTest?.id,
            }}
          >
            {props.data?.manufacturerTest?.display_name}
          </CustomLink>
        </td>
      </tr>
      <tr className="recap-row">
        <td>
          <p className="recap-label">No. Cassette</p>
        </td>
        <td>{props.data?.barcodeCassette}</td>
      </tr>
      <tr className="recap-row">
        <td>
          <p className="recap-label">Date et heure</p>
        </td>
        <td>
          <p className="label">
            {DateUtils.dateToFrenchFormat(props.data?.createdAt)}
          </p>
        </td>
      </tr>
      {/* <tr className="recap-row">
                <td><p className="recap-label">Tube n°</p></td>
                <td>{props.data?.tube?.barcodeTube}</td>
            </tr>
            <tr className="recap-row">
                <td><p className="recap-label">Patient</p></td>
                <td>
                    <CustomLink className="see-more-link" tag="a" to={{
                        "pathname": "/patient-details",
                        "state": props.data?.patientId
                    }}>
                        {props.data?.patientId}
                    </CustomLink>
                </td>
            </tr> */}
    </table>
  );
};

export default CassetteDetailsComponent;
