import React from "react";
import * as DateUtils from "../utils/DateUtils";

const ResultTestDetailsComponent = (props) => {
  return (
    <table className="table-recap">
      <tr className="recap-row">
        <td>
          <p className="recap-label">Date et heure</p>
        </td>
        <td>
          <p className="label">
            {DateUtils.dateToFrenchFormat(props.data?.createdAt)}
          </p>
        </td>
      </tr>
      <tr className="recap-row">
        <td>
          <p className="recap-label">Result ID</p>
        </td>
        <td>
          <p className="label">{props.data?.id} </p>
        </td>
      </tr>

      <tr className="recap-row">
        <td>
          <p className="recap-label">Temps d'incubation</p>
        </td>
        <td>
          <p className="label">
            {DateUtils.minutesBetweenTwoDates(
              props.data?.createdAt,
              props.data?.cassette?.createdAt
            ) + " minutes"}
          </p>
        </td>
      </tr>
      <tr className="recap-row">
        <td colSpan="2">
          <p className="recap-label">Interpretation de l'opérateur :</p>
        </td>
      </tr>
      {props.data?.lines &&
        Object.entries(props.data?.lines).map(([key, value]) => {
          return (
            <tr className="recap-row">
              <td>
                <p className="recap-label">{key}</p>
              </td>
              <td>
                <p className="label">{value}</p>
              </td>
            </tr>
          );
        })}
    </table>
  );
};

export default ResultTestDetailsComponent;
