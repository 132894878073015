import React from "react";
import * as resultApi from "../../api/Result";
import ResultListTable from "../../components/ResultListTableComponent";
import "../../style/style.scss";
import LayoutPageComponent from "../../components/LayoutPageComponent";

class ListResult extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      msg: "",
      isFetching: false,
      date: "",
      data: [],
    };

    this.getResults = this.getResults.bind(this);
  }

  async getResults(e) {
    const userEtablishment = JSON.parse(
      localStorage.getItem("user")
    ).etablishment;
    this.setState({ isFetching: true, msg: "Patientez svp..." });
    const response = await resultApi.getResults(
      userEtablishment,
      e.target.value
    );

    if (response.error) {
      this.setState({ isFetching: false, msg: "Aucun résultat trouvé." });
    } else {
      this.setState({ data: response.data, isFetching: false, msg: "" });
    }
  }

  render() {
    return (
      <LayoutPageComponent>
        <p className="title">Liste des résultats</p>
        <p>
          Selectionnez une date :{" "}
          <input type="date" onChange={this.getResults} />
        </p>
        <p>{this.state.msg}</p>
        {!this.state.isFetching && <ResultListTable data={this.state.data} />}
      </LayoutPageComponent>
    );
  }
}

export default ListResult;
