import React from 'react';
import Datatable from './DatatableComponent';
import ListManufacturerTestHeader from '../pages_config/list_manufacturer_test_header.json';
import * as DateUtils from '../utils/DateUtils'
import "../style/style.scss";


const ManufacturerTestListTable = (props) => {
    //Get column from ListManufacturerTestHeader in pages configuration
    const columns = ListManufacturerTestHeader;

    var manufacturerData = [];


    props.data.forEach(element => {
        manufacturerData.push({
            id: element.id,
            name: {toShow: element.name, linkTo: element.id, pathName: "/manufacturer-test-details"},
            pathology: element.disease,
            manufacturer: element.manufacturer,
            available: element.available ? "Actif" : "Inactif",
            createdAt: DateUtils.dateToFrenchFormat(element.createdAt),
            updatedAt: DateUtils.dateToFrenchFormat(element.updatedAt),
            actions: {toShow: "Modifier", linkTo: element.id, pathName: "/edit-manufacturer-test"},
        });
    });

    return (
        <Datatable columns={columns} data={manufacturerData} />
    );
}



export default ManufacturerTestListTable;