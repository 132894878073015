import React from "react";
import Datatable from "./DatatableComponent";
import ListPackingHeader from "../pages_config/list_packing_header.json";
import "../style/style.scss";
import moment from "moment";

const PackingListTable = (props) => {
  //Get column from ListPackingHeader in pages configuration
  const columns = ListPackingHeader;

  let packingListData = [];

  props.data.forEach((element) => {
    packingListData.push({
      ref: element.packingRef,
      date: moment(element.createdAt).format("DD/MM/YYYY HH:mm"),
      status: element.packingStatus,
      pouches: element.totalpouch,
      actions: {
        toShow: "Voir",
        linkTo: element.id,
        pathName: "/packing-details",
      },
    });
  });

  return (
    <Datatable
      columns={columns}
      data={packingListData}
      // showFilterBar={true}
      // setFilterValue={props.setFilterValue}
    />
  );
};

export default PackingListTable;
