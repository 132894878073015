import React, { useEffect, useState } from "react";
import * as companyApi from "../../api/Company";

import { useHistory } from "react-router-dom";
import Select from "react-select";

import LayoutPageComponent from "../../components/LayoutPageComponent";

import { AiFillPlusCircle } from "react-icons/ai";
import { BsFillTrashFill } from "react-icons/bs";

const ModifyPrice = (props) => {
  const history = useHistory();

  const [message, setMessage] = useState("");
  const [selectedTestOption, setSelectedTestOption] = useState(null);
  // eslint-disable-next-line
  const [ranged, setRanged] = useState(false);
  const [range1, setRange1] = useState("");
  const [range2, setRange2] = useState("");
  const [price, setPrice] = useState("");
  const [tax, setTax] = useState("");
  // eslint-disable-next-line
  const [priceData, setPriceData] = useState([]);

  const [rangePriceNew, setRangePriceNew] = useState([]);
  // eslint-disable-next-line
  useEffect(async () => {
    const response = await companyApi.getPrice(props.location.state.companyId);

    setRangePriceNew(response.data);
    // eslint-disable-next-line
  }, []);

  const clearPriceRange = () => {
    setRange1("");
    setRange2("");
    setPrice("");
  };

  const handleAdd = (e) => {
    e.preventDefault();

    let maxValue = -1;
    // eslint-disable-next-line
    rangePriceNew.map((item) => {
      if (
        Number(item.range.split("to ")[1]) > maxValue &&
        // eslint-disable-next-line
        item.testTypeId == Number(selectedTestOption.value)
      ) {
        maxValue = Number(item.range.split("to ")[1]);
      }
    });

    if (maxValue >= Number(range1)) {
      alert("invalid range");
    } else if (Number(range1) < 0 || Number(range2) < 0 || Number(price) < 0) {
      alert("The range or price can't be negative");
    } else if (Number(range1) > Number(range2)) {
      alert("invalid range");
    } else if (Number(range1) < Number(range2)) {
      setRangePriceNew((data) => {
        return [
          ...data,
          {
            rangePrice: price,
            range: `${range1} to ${range2}`,
            tax: Number(tax),
            testTypeId: Number(selectedTestOption.value),
            isRanged: 1,
          },
        ];
      });

      clearPriceRange();
    }
  };

  const handleChangeTestType = (selectedTestOption) => {
    setSelectedTestOption(selectedTestOption);
  };

  const deleteRow = (id) => {
    const newRows = rangePriceNew.filter((row, index) => index !== id);
    setRangePriceNew(newRows);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const postData2 = {
      isRanged: 1,

      priceRanges: rangePriceNew,
    };

    const id = props.location.state.companyId;

    await companyApi
      .updatePrice(id, postData2)
      .then((response) => {
        setMessage("price updated");
        history.goBack();
      })
      .catch((err) => console.error("error:" + err));
  };

  const testTypesData = [
    { value: "1", label: "COVID-19 Antigenic Test" },
    { value: "2", label: "COVID-19 PCR Test" },
    { value: "3", label: "COVID-19 PCR Premium" },
  ];

  return (
    <LayoutPageComponent>
      <p className="title">
        <b>Modifier</b>
      </p>
      <p style={{ paddingLeft: "30px" }}>
        <i>{message}</i>
      </p>
      <form className="form" onSubmit={handleSubmit}>
        <div>
          <br />
          <br />
          <table style={{ width: "50vw", textAlign: "left" }}>
            <tr>
              <th>Range</th>
              <th>Price</th>
              <th>Tax</th>
              <th>test type</th>
              <th style={{ paddingLeft: "5rem" }}>action</th>
            </tr>

            <td>
              <input
                type="number"
                value={range1}
                style={{ width: "25%", textAlign: "center" }}
                onChange={(e) => setRange1(e.target.value)}
              ></input>{" "}
              to{" "}
              <input
                type="number"
                value={range2}
                style={{ width: "25%", textAlign: "center" }}
                onChange={(e) => setRange2(e.target.value)}
              ></input>
            </td>
            <td>
              <input
                value={price}
                type="number"
                style={{ width: "40%", textAlign: "center" }}
                onChange={(e) => setPrice(e.target.value)}
              ></input>{" "}
            </td>
            <td>
              <input
                value={tax}
                type="number"
                style={{ width: "40%", textAlign: "center" }}
                onChange={(e) => setTax(e.target.value)}
              ></input>{" "}
            </td>
            <td style={{ width: "10rem" }}>
              <Select
                value={selectedTestOption}
                onChange={handleChangeTestType}
                options={testTypesData}
              />
            </td>
            <td>
              <span
                style={{
                  cursor: "pointer",
                  float: "right",
                  fontSize: "1.75rem",
                }}
                onClick={handleAdd}
              >
                <AiFillPlusCircle />
              </span>
            </td>

            {rangePriceNew.map((data, index) => {
              return (
                <tr key={index}>
                  <td>{data.range}</td>
                  <td>{data.rangePrice} </td>
                  <td>{data.tax} </td>
                  <td>
                    {data.testTypeId === 1
                      ? "Antigenic"
                      : data.testTypeId === 2
                      ? "RT-PCR"
                      : "PCR Premium"}
                  </td>
                  <td>
                    {" "}
                    <span
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "1.75rem",
                      }}
                      onClick={() => deleteRow(index)}
                    >
                      <BsFillTrashFill />
                    </span>
                  </td>
                </tr>
              );
            })}
          </table>
        </div>

        <div>
          <button
            type="submit"
            className="btnMain"
            style={{ cursor: "pointer" }}
          >
            Mettre à jour
          </button>
        </div>
      </form>
    </LayoutPageComponent>
  );
};

export default ModifyPrice;
