import * as requests from "../utils/HttpRequests";
import config from "../config/load_config";

const apiUrl =
  process.env.REACT_APP_API_BASE_URL + config.BASE_PRIVATE + config.API.AUTH;
// const apiUrl = process.env.REACT_APP_API_BASE_URL + config.BASE_ADMIN + config.API.AUTH;

//User Sign In
export async function signIn(credentials) {
  const response = await requests.doPost(apiUrl, credentials, {
    "content-type": "application/json",
  });
  return response;
}
