import React from "react";
import * as patientApi from "../../api/Patient";
import PatientDetailsTable from "../../components/PatientDetailsTableComponent";

import LayoutPageComponent from "../../components/LayoutPageComponent";

class PatientDetails extends React.Component {
  constructor(props) {
    super(props);

    this.patientID = this.props.history.location["state"]
      ? this.props.history.location["state"]
      : null;

    this.state = {
      isFetching: false,
      msgPatient: this.patientData === null ? "Aucun patient sélectionné" : "",
      patientID: this.patientID !== null ? this.patientID : "",
      patientData: {},
    };
  }

  async componentWillMount() {
    this.setState({ isFetching: true, msgPatient: "Patientez svp..." });
    const response = await patientApi.getPatient(this.state.patientID);

    if (response.error) {
      this.setState({ isFetching: false, msgPatient: "Aucun patient trouvée" });
    } else {
      var data = response.data;
      this.setState({ patientData: data, isFetching: false, msgPatient: "" });
    }
  }

  render() {
    //Get user logged
    return (
      <LayoutPageComponent>
        <p className="title">ID Patient : {this.state.patientData.id}</p>
        <p>{this.state.msgPatient}</p>
        {!this.state.isFetching && (
          <PatientDetailsTable data={this.state.patientData} />
        )}
      </LayoutPageComponent>
    );
  }
}

export default PatientDetails;
