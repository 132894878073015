import React, { useState, useEffect } from "react";

const IncubationComponent = (props) => {
  const { incubation, incubationData } = props;

  var incubationValues = {};

  const [min, setMin] = useState(incubationData ? incubationData.min : "");
  const [max, setMax] = useState(incubationData ? incubationData.max : "");
  const [nominal, setNominal] = useState(
    incubationData ? incubationData.nominal : ""
  );

  //Get all changes of incubation
  incubationValues.min = min;
  incubationValues.max = max;
  incubationValues.nominal = nominal;

  //Handle fields change
  useEffect(() => {
    incubation(incubationValues);
    // eslint-disable-next-line
  }, [min, max, nominal]);

  return (
    <div>
      <div>
        <p>Min *</p>
        <input
          type="number"
          name="min"
          className="input-large"
          value={min}
          onChange={(e) => setMin(e.target.value)}
        />
      </div>
      <div>
        <p>Nominal *</p>
        <input
          type="number"
          name="nominal"
          className="input-large"
          value={nominal}
          onChange={(e) => setNominal(e.target.value)}
        />
      </div>
      <div>
        <p>Max *</p>
        <input
          type="number"
          name="max"
          className="input-large"
          value={max}
          onChange={(e) => setMax(e.target.value)}
        />
      </div>
    </div>
  );
};

export default IncubationComponent;
