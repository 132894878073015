import React, { useState } from "react";
import "../style/style.scss";
import * as entityApi from "../api/Etablishment";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

const OnlinePaymentStatus = (props) => {
  const [showOne, setShowOne] = useState(false);
  const [showTwo, setShowTwo] = useState(false);

  const [isPaymentEnabled, setIsPaymentEnabled] = useState(
    props.data.isPaymentEnabled
  );
  const [enableFTPDourotPcr, setEnableFTPDourotPcr] = useState(
    props.data.enableFTPDourotPcr
  );
  const [enableFTPDourotAntigenic, setEnableFTPDourotAntigenic] = useState(
    props.data.enableFTPDourotAntigenic
  );
  const [enableBiolamAntigenic, setEnableBiolamAntigenic] = useState(
    props.data.enableBiolamAntigenic
  );
  const [enableOtherPayment, setEnableOtherPayment] = useState(
    props.data.enableOtherPayment
  );

  const handleSubmit = (e) => {
    // e.preventDefault();
    const postData = {
      establishmentId: props.data.id,
      isPaymentEnabled,
      enableFTPDourotPcr,
      enableFTPDourotAntigenic,
      enableBiolamAntigenic,
      enableOtherPayment,
    };

    entityApi
      .updateEstablishment(postData)
      .then((res) => {
        window.location.reload();
      })

      .catch((err) => console.error("error:" + err));
  };

  return (
    <>
      {/* first */}
      <p>
        <b>Interop</b>
      </p>
      <Table
        aria-label="caption table"
        style={{ width: "70%", marginTop: "2rem" }}
      >
        <caption></caption>
        <TableHead>
          <TableRow>
            <TableCell>Drouot PCR</TableCell>
            <TableCell>Drouot Antigénique</TableCell>
            <TableCell>Biolam Antigénique</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          <TableRow>
            <TableCell>{props.data.enableFTPDourotPcr}</TableCell>
            <TableCell>{props.data.enableFTPDourotAntigenic}</TableCell>
            <TableCell>{props.data.enableBiolamAntigenic}</TableCell>
            <TableCell
              style={{ cursor: "pointer" }}
              onClick={() => setShowOne(!showOne)}
            >
              <button
                style={{
                  display: "inline-block",
                  padding: "0.25rem 0.75rem",
                  backgroundColor: "#4867d8",
                  boxShadow: "0 0 20px -10px rgb(0 0 0 / 50%)",
                  color: "#ffffff",
                  fontWeight: "bold",
                  border: "0",
                  width: "200px",
                  height: "40px",
                  borderRadius: "10px",
                  cursor: "pointer",
                }}
              >
                Modifier
              </button>
            </TableCell>
          </TableRow>
          {showOne && (
            <TableRow>
              <TableCell>
                <Select
                  value={enableFTPDourotPcr}
                  onChange={(event) =>
                    setEnableFTPDourotPcr(event.target.value)
                  }
                >
                  <MenuItem value={"yes"}>yes</MenuItem>
                  <MenuItem value={"no"}>no</MenuItem>
                </Select>
              </TableCell>
              <TableCell>
                <Select
                  value={enableFTPDourotAntigenic}
                  onChange={(event) =>
                    setEnableFTPDourotAntigenic(event.target.value)
                  }
                >
                  <MenuItem value={"yes"}>yes</MenuItem>
                  <MenuItem value={"no"}>no</MenuItem>
                </Select>
              </TableCell>
              <TableCell>
                <Select
                  value={enableBiolamAntigenic}
                  onChange={(event) =>
                    setEnableBiolamAntigenic(event.target.value)
                  }
                >
                  <MenuItem value={"yes"}>yes</MenuItem>
                  <MenuItem value={"no"}>no</MenuItem>
                </Select>
              </TableCell>

              <TableCell
                type="submit"
                onClick={handleSubmit}
                style={{ cursor: "pointer" }}
              >
                <button
                  style={{
                    display: "inline-block",
                    padding: "0.25rem 0.75rem",
                    backgroundColor: "#4867d8",
                    boxShadow: "0 0 20px -10px rgb(0 0 0 / 50%)",
                    color: "#ffffff",
                    fontWeight: "bold",
                    border: "0",
                    width: "200px",
                    height: "40px",
                    borderRadius: "10px",
                    cursor: "pointer",
                  }}
                >
                  Update
                </button>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      {/* second */}
      <p>
        <b>Paiement</b>
      </p>
      <Table
        aria-label="caption table"
        style={{ width: "70%", marginTop: "2rem" }}
      >
        <caption></caption>
        <TableHead>
          <TableRow>
            <TableCell>Other Payment</TableCell>
            <TableCell>Online Payment</TableCell>

            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          <TableRow>
            <TableCell>{props.data.enableOtherPayment}</TableCell>
            <TableCell>{props.data.isPaymentEnabled}</TableCell>

            <TableCell
              style={{ cursor: "pointer" }}
              onClick={() => setShowTwo(!showTwo)}
            >
              <button
                style={{
                  display: "inline-block",
                  padding: "0.25rem 0.75rem",
                  backgroundColor: "#4867d8",
                  boxShadow: "0 0 20px -10px rgb(0 0 0 / 50%)",
                  color: "#ffffff",
                  fontWeight: "bold",
                  border: "0",
                  width: "200px",
                  height: "40px",
                  borderRadius: "10px",
                  cursor: "pointer",
                }}
              >
                Modifier
              </button>
            </TableCell>
          </TableRow>
          {showTwo && (
            <TableRow>
              <TableCell>
                <Select
                  value={enableOtherPayment}
                  onChange={(event) =>
                    setEnableOtherPayment(event.target.value)
                  }
                >
                  <MenuItem value={"yes"}>yes</MenuItem>
                  <MenuItem value={"no"}>no</MenuItem>
                </Select>
              </TableCell>
              <TableCell>
                <Select
                  value={isPaymentEnabled}
                  onChange={(event) => {
                    event.preventDefault();
                    setIsPaymentEnabled(event.target.value);
                  }}
                >
                  <MenuItem value={"yes"}>yes</MenuItem>
                  <MenuItem value={"no"}>no</MenuItem>
                </Select>
              </TableCell>

              <TableCell
                type="submit"
                onClick={handleSubmit}
                style={{ cursor: "pointer" }}
              >
                <button
                  style={{
                    display: "inline-block",
                    padding: "0.25rem 0.75rem",
                    backgroundColor: "#4867d8",
                    boxShadow: "0 0 20px -10px rgb(0 0 0 / 50%)",
                    color: "#ffffff",
                    fontWeight: "bold",
                    border: "0",
                    width: "200px",
                    height: "40px",
                    borderRadius: "10px",
                    cursor: "pointer",
                  }}
                >
                  Update
                </button>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </>
  );
};

export default OnlinePaymentStatus;
