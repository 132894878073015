const ReturnResultComponent = (props) => {

    const { name, setReturnResult, className, label } = props;

    const handleValueChange = (e) => {
        setReturnResult(e.target.value);
    }

    return(
        <div>
            <p>{label}</p>
            <select name={name} className={className} onChange={handleValueChange} >
                <option value="">Choisir</option>
                <option value="hors_cofrac">Hors Cofrac</option>
            </select>
        </div>
    );
}

export default ReturnResultComponent;