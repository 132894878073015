import React from "react";
import * as patientApi from "../../api/Patient";
import PatientListTable from "../../components/PatientListTableComponent";
import "../../style/style.scss";
import * as establishmentApi from "../../api/Etablishment";

import Select from "react-select";
import "date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns"; // import
import LayoutPageComponent from "../../components/LayoutPageComponent";

class ListPatient extends React.Component {
  constructor(props) {
    super(props);
    let today = new Date();
    // eslint-disable-next-line
    let interval;
    this.state = {
      msg: "",
      startDate: today,
      isFetching: true,
      establishmentId: null,
      data: [],
      establishmentsData: [],
      selectedEstablishment: null,
      filterValue: "",
    };
  }

  persistState = (key, state) => {
    localStorage.setItem(key, JSON.stringify(state));
  };

  recoverState = (key) => {
    return JSON.parse(localStorage.getItem(key));
  };

  async setStartDate(dateValue) {
    await this.setState({
      startDate: dateValue,
    });
  }

  refreshState = (state) => {
    if (state.filterValue === "") {
      if (state.selectedEstablishment && state.startDate) {
        let establishmentId = state.selectedEstablishment.value;

        var startdateStateValue = new Date(state.startDate);
        var startMonth = (startdateStateValue.getMonth() + 1)
          .toString()
          .padStart(2, "0");
        var startDay = startdateStateValue
          .getDate()
          .toString()
          .padStart(2, "0");
        var startDateValue =
          startdateStateValue.getFullYear() + "-" + startMonth + "-" + startDay;
        patientApi
          .getPatients(establishmentId, startDateValue)
          .then((response) =>
            this.setState({ data: response.data, isFetching: false })
          )
          .catch((error) => console.log("Something went wrong..."));
      }
    }
  };

  getEstablishmentFromUser = async () => {
    var establishmentArray = [];
    var isAdmincheck = localStorage.getItem("isAdmin");
    if (isAdmincheck.toString() === "false") {
      var establishmentNameValue = localStorage.getItem("establishmentName");
      var establishmentIdValue = localStorage.getItem("establishment");
      var objEstablishmentStatic = {
        value: establishmentIdValue,
        label: establishmentNameValue,
      };
      establishmentArray.push(objEstablishmentStatic);
      this.setState({
        establishmentsData: establishmentArray,
        isFetching: false,
        msg: "",
      });
    } else {
      await this.getEstablishment();
    }
  };

  async componentDidMount() {
    if (this.props.history.action === "POP") {
      await this.getEstablishmentFromUser();
      await this.setState({
        selectedEstablishment: this.recoverState("selectedEstablishment"),
        startDate: this.recoverState("startDate"),
      });
      await this.submitFilters(this.state);
    } else {
      try {
        this.setState({ isFetching: true, msg: "Patientez svp..." });
        await this.getEstablishmentFromUser();
      } catch (e) {
        console.log("error", JSON.stringify(e));
      }
    }

    // clearInterval(this.interval);
    // this.interval = setInterval(() => this.refreshState(this.state), 10000);
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    if (nextState.filterValue !== "") {
      return false;
    }

    return true;
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  async getEstablishment() {
    let establishmentArray = [];
    const responseEstablishment = await establishmentApi.getEtablishments();

    if (responseEstablishment.error) {
      this.setState({ isFetching: false, msg: "Aucun patient trouvé." });
    } else {
      // eslint-disable-next-line
      responseEstablishment.data.map((responseObj, i) => {
        var objEstablishment = {
          value: responseObj.id,
          label: responseObj.name,
        };
        establishmentArray.push(objEstablishment);
      });

      this.setState({
        establishmentsData: establishmentArray,
        isFetching: false,
        msg: "",
      });
    }
  }

  async submitFilters(stateData) {
    try {
      if (stateData.selectedEstablishment == null) {
        alert("Sélectionnez l'établissement");
        return;
      }

      this.persistState("startDate", stateData.startDate);
      this.persistState(
        "selectedEstablishment",
        stateData.selectedEstablishment
      );

      let establishmentId = stateData.selectedEstablishment.value;

      var startdateStateValue = new Date(stateData.startDate);
      var startMonth = (startdateStateValue.getMonth() + 1)
        .toString()
        .padStart(2, "0");
      var startDay = startdateStateValue.getDate().toString().padStart(2, "0");
      var startDateValue =
        startdateStateValue.getFullYear() + "-" + startMonth + "-" + startDay;
      const response = await patientApi.getPatients(
        establishmentId,
        startDateValue
      );
      if (response.error) {
        this.setState({ isFetching: false, msg: "Aucun patient trouvé." });
      } else {
        if (response.data != null) {
          this.setState({ data: response.data, isFetching: false, msg: "" });
        }
      }
    } catch (e) {
      console.log("error", JSON.stringify(e));
    }
  }

  handleChangeEstablishment = async (selectedEstablishment) => {
    try {
      await this.setState({ selectedEstablishment });
      await this.setState({ filterValue: "" });
      clearInterval(this.interval);
    } catch (e) {
      console.log("error", JSON.stringify(e));
    }
  };

  handleChangeDate = async (startDate) => {
    try {
      await this.setState({ startDate });
      await this.setState({ filterValue: "" });
      clearInterval(this.interval);
    } catch (e) {
      console.log("error", JSON.stringify(e));
    }
  };

  handleFilterValueChange = (value) => {
    this.setState({ filterValue: value });
  };

  render() {
    const { selectedEstablishment } = this.state;
    const { establishmentsData } = this.state;

    return (
      <LayoutPageComponent>
        <p className="title">Liste des patients</p>

        <div className="filters">
          <div className="filterControl">
            <p> Sélectionnez la date de départ : </p>

            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                value={this.state.startDate}
                onChange={(date) => this.handleChangeDate(date)}
              />
            </MuiPickersUtilsProvider>
          </div>

          <div className="filterControl">
            <p> Sélectionnez l'établissement : </p>
            <Select
              value={selectedEstablishment}
              onChange={this.handleChangeEstablishment}
              options={establishmentsData}
            />
          </div>

          <div className="filterControlButtons">
            <button
              onClick={() => this.submitFilters(this.state)}
              class="filterbutton"
            >
              Rechercher
            </button>
            <div></div>
          </div>
        </div>

        <p>{this.state.msg}</p>
        {this.state.data.length > 0 && !this.state.isFetching && (
          <PatientListTable
            data={this.state.data}
            setFilterValue={this.handleFilterValueChange}
          />
        )}
      </LayoutPageComponent>
    );
  }
}

export default ListPatient;
