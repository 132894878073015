import * as requests from "../utils/HttpRequests";
import config from "../config/load_config";
import { fetchThenUrl } from "./shared";

const apiUrl = process.env.REACT_APP_API_BASE_URL + config.BASE_ADMIN;
const paymentUrl =
  process.env.REACT_APP_API_BASE_URL + config.API.PAYMENT_ETABLISHMENT;

//Create Etablishment
export async function createEtablishment(etablishment) {
  //TO DO: Passer le paramètre token au header
  const response = await requests.doPost(
    apiUrl + config.API.ETABLISHMENT,
    etablishment,
    {
      "content-type": "application/json",
      auth: localStorage.getItem("token"),
    }
  );
  return response;
}

//Get list of etablishment
export async function getEtablishments() {
  const response = await requests.doGet(apiUrl + config.API.ETABLISHMENT, {
    "content-type": "application/json",
    auth: localStorage.getItem("token"),
  });
  return response;
}

//Get an etablishment
export async function getEtablishment(etablishmentID) {
  const response = await requests.doGet(
    apiUrl +
      config.API.ETABLISHMENT +
      config.API.ETABLISHMENT_DETAILS.replace(":id", etablishmentID),
    {
      "content-type": "application/json",
      auth: localStorage.getItem("token"),
    }
  );
  return response;
}

// update an establishment
export async function updateEstablishment(data) {
  const response = await requests.doPatch(paymentUrl, data, {
    "content-type": "application/json",
    // auth: localStorage.getItem("token"),
  });
  return response;
}

export async function addSignatory(formData) {
  let url = apiUrl + "/signatory/upload"; // TODO

  let options = {
    method: "POST",
    headers: {
      auth: localStorage.getItem("token"),
    },
  };

  options.body = formData;

  return fetch(url, options);
}

export async function updateSignatory(signatoryId, formData) {
  let url = apiUrl + "/signatory/update/" + signatoryId; // TODO

  let options = {
    method: "POST",
    headers: {
      auth: localStorage.getItem("token"),
    },
  };

  options.body = formData;

  return fetch(url, options);
}

export async function changeLogo(formData, establishmentId) {
  let url = apiUrl + `/establishment/${establishmentId}/logo`;
  let options = {
    method: "POST",
    headers: {
      auth: localStorage.getItem("token"),
    },
  };

  options.body = formData;

  return fetch(url, options);
}

// TODO move in stats
export async function getStatsCSV() {
  let url =
    process.env.REACT_APP_API_BASE_URL +
    config.BASE_STATS +
    `/sidep_by_establishment`;

  return fetchThenUrl(url, {
    method: "GET",
    headers: {
      auth: localStorage.getItem("token"),
    },
  });
}

export async function importXslxData(establishment, testType, xslxData) {
  let url = apiUrl + "/patient/hpr";

  const toSend = {
    labName: establishment,
    testType: testType,
    data: xslxData,
  };

  await fetch(url, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      auth: localStorage.getItem("token"),
    },
    body: JSON.stringify(toSend),
  })
    .then((response) => response.json())
    .then((result) => {
      window.location.href = url + "/" + result.filename;
    });
}
