import React from "react";

import LayoutPageComponent from "../../components/LayoutPageComponent";
import FacturationDetailsTableComponentNoRange from "../../components/FacturationDetailsTableComponentNoRanged";

const AddPriceNoRange = (props) => {
  return (
    <LayoutPageComponent>
      <FacturationDetailsTableComponentNoRange
        companyId={props.location.state.companyId}
        establishment={props.location.state.establishment}
      />
    </LayoutPageComponent>
  );
};

export default AddPriceNoRange;
