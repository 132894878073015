export function dateToFrenchFormat(dateString) {
  var date = new Date(dateString);

  // 01, 02, 03, ... 29, 30, 31
  var dd = (date.getDate() < 10 ? "0" : "") + date.getDate();
  // 01, 02, 03, ... 10, 11, 12
  var MM = (date.getMonth() + 1 < 10 ? "0" : "") + (date.getMonth() + 1);
  // 1970, 1971, ... 2015, 2016, ...
  var yyyy = date.getFullYear();

  var hh = (date.getHours() < 10 ? "0" : "") + date.getHours();
  var mm = (date.getMinutes() < 10 ? "0" : "") + date.getMinutes();

  // create the format you want
  return dd + "/" + MM + "/" + yyyy + " " + hh + ":" + mm;
}

export function minutesBetweenTwoDates(firstDate, secondDate) {
  const recentDate = new Date(firstDate);
  const oldDate = new Date(secondDate);

  return ((recentDate - oldDate) / 60000).toFixed(2);
}
export function getAge(birth) {
  // console.log("input age", birth)
  var ageMS = Date.parse(Date()) - Date.parse(birth);
  var age = new Date();
  age.setTime(ageMS);
  var ageYear = age.getFullYear() - 1970;

  return ageYear;

  // ageMonth = age.getMonth(); // Accurate calculation of the month part of the age
  // ageDay = age.getDate();    // Approximate calculation of the day part of the age
}
