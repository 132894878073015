
// I make a wrapper snippet which will resolve to a objectURL
export const fetchThenUrl = (url, headers) => {
    return new Promise((resolve, reject) => {
        fetch(url, headers)
            .then(response => response.blob()) // sending the blob response to the next then
            .then(blob => {
                const objectUrl = URL.createObjectURL(blob);
                resolve(objectUrl);
            }) // resolved the promise with the objectUrl 
            .catch(err => reject(err)); // if there are any errors reject them
    });
}