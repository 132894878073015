export const getLastLine = (ref) => {
  const refSplit = ref.split(/[^0-9]+/gi);
  return refSplit.pop();
};

export const getHeader = (sheet) => {
  let headers = [];
  const headerColumn = Object.keys(sheet).filter((key) => key.includes("1"));

  // eslint-disable-next-line
  Object.entries(sheet).map(([key, value], index) => {
    if (headerColumn.includes(key)) {
      const headerConcat = value.h.split(" ").join("_") || value.h;
      headers.push(headerConcat);
    }
  });

  return headers;
};

export const matchExcelHeaderWithKeys = (key) => {
  let matchedKey = "";
  switch (key) {
    case "ID":
      matchedKey = "patientId";
      break;
    case "CIVILITE":
      matchedKey = "civilite";
      break;
    case "NOM":
      matchedKey = "lastName";
      break;
    case "NOM_ELEVE":
      matchedKey = "lastName";
      break;
    case "PRENOM":
      matchedKey = "firstName";
      break;
    case "PRENOM_ELEVE":
      matchedKey = "firstName";
      break;
    case "DATE_NAISSANCE":
      matchedKey = "dob";
      break;
    case "SEXE":
      matchedKey = "gender";
      break;
    case "ADRESSE":
      matchedKey = "address";
      break;
    case "CP":
      matchedKey = "cp";
      break;
    case "VILLE":
      matchedKey = "city";
      break;
    case "PAYS":
      matchedKey = "country";
      break;
    case "TELEPHONE":
      matchedKey = "phoneNumber";
      break;
    case "SS":
      matchedKey = "ss";
      break;
    case "CENTRE_SECU":
      matchedKey = "secu";
      break;
    case "MAIL":
      matchedKey = "email";
      break;
    case "ANALYSES":
      matchedKey = "analysis";
      break;
    case "DEPARTEMENT":
      matchedKey = "department";
      break;
    default:
      matchedKey = "";
  }

  return matchedKey;
};

export const getAge = (birthdate) => {
  return new Date() - new Date(birthdate);
};

function buildObject(key, dataListObject, matchedKey, value) {
  if (key === "DATE_NAISSANCE") {
    dataListObject[matchedKey] = value.h.split("/").join("");
    dataListObject["age"] = getAge(value.h);
  } else {
    dataListObject[matchedKey] = value.h;
  }
}

export const buildDataList = (sheet) => {
  const headers = getHeader(sheet);
  const totalLine = getLastLine(sheet["!ref"]);
  const dataLine = totalLine - 1;
  let dataList = [];

  for (let i = 1; i <= dataLine; i++) {
    let line = i;
    let index = 0;
    let dataListObject = {};
    let currentLineData = Object.keys(sheet).filter((key) =>
      key.includes((line + 1).toString())
    );

    // eslint-disable-next-line
    Object.entries(sheet).map(([key, value], sheetArrayIndex) => {
      if (currentLineData.includes(key)) {
        let matchedKey = matchExcelHeaderWithKeys(headers[index]);
        if (matchedKey !== "") {
          buildObject(key, dataListObject, matchedKey, value);
        }
        ++index;
      }
    });

    dataList.push(dataListObject);
  }

  return dataList;
};
