import React from "react";
import Datatable from "./DatatableComponent";
import ListUserHeader from "../pages_config/list_user_header.json";
import * as DateUtils from "../utils/DateUtils";
import "../style/style.scss";

const UserListTable = (props) => {
  //Get column from ListUserHeader in pages configuration
  const columns = ListUserHeader;

  var userData = [];

  props.data.forEach((element) => {
    userData.push({
      title: element.title,
      firstName: {
        toShow: element.firstName,
        linkTo: element.id,
        pathName: "/user-details",
      },
      lastName: {
        toShow: element.lastName,
        linkTo: element.id,
        pathName: "/user-details",
      },
      email: element.email,
      //phone: element.phone,
      role: element.role,
      status: element.active === true ? "Active" : "Inactive",
      //company: props.company ? {toShow: props.company.name, linkTo: props.company.id, pathName: "/company-details"} : {toShow: element.company.name, linkTo: element.company.id, pathName: "/company-details"},
      entity: props.establishment
        ? {
            toShow: props.establishment.name,
            linkTo: props.establishment.id,
            pathName: "/etablishment-details",
          }
        : element.establishment
        ? {
            toShow: element.establishment.name,
            linkTo: element.establishment.id,
            pathName: "/etablishment-details",
          }
        : { toShow: "-", linkTo: "", pathName: "" },
      reader: element.reader,
      access: element.isOperator === true ? "Operator" : "Health proffessionel",
      createdAt: DateUtils.dateToFrenchFormat(element.createdAt),
    });
  });

  return <Datatable columns={columns} data={userData} />;
};

export default UserListTable;
