import React, { Fragment } from "react";
import * as paymentAPI from "../../api/Payment";

import "../../style/style.scss";
import * as establishmentApi from "../../api/Etablishment";
import moment from "moment";
import { Link } from "react-router-dom";

import Select from "react-select";
import "date-fns";

import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns"; // import
import LayoutPageComponent from "../../components/LayoutPageComponent";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import "./payment.scss";

import Button from "@material-ui/core/Button";
class Payment extends React.Component {
  constructor(props) {
    super(props);
    let today = new Date();
    this.state = {
      msg: "",
      startDate: today.toISOString(),
      endDate: today.toISOString(),
      testType: null,
      testTypeData: null,
      isFetching: true,
      establishmentId: null,
      data: [],
      establishmentsData: [],
      selectedEstablishment: null,
      filterValue: "",
      activePage: 1,
      totalSum: null,
      patientData: {},
      patientID: "",
      totalPatients: null,
    };
  }

  handlePageChange(pageNumber) {
    this.setState({ activePage: pageNumber });
  }

  persistState = (key, state) => {
    localStorage.setItem(key, JSON.stringify(state));
  };

  recoverState = (key) => {
    return JSON.parse(localStorage.getItem(key));
  };

  async setStartDate(dateValue) {
    await this.setState({
      startDate: dateValue,
    });
  }
  async setEndDate(dateValue) {
    await this.setState({
      endDate: dateValue,
    });
  }

  testSubmit = (state) => {
    if (state.filterValue === "") {
      if (
        state.selectedEstablishment &&
        state.startDate &&
        state.endDate &&
        state.testType
      ) {
        this.persistState("startDate", state.startDate);
        this.persistState("endDate", state.endDate);
        this.persistState("selectedEstablishment", state.selectedEstablishment);
        this.persistState("selectedTestType", state.testType);
        let establishmentId = state.selectedEstablishment.value;

        var startdateStateValue = new Date(state.startDate);
        var startMonth = (startdateStateValue.getMonth() + 1)
          .toString()
          .padStart(2, "0");
        var startDay = startdateStateValue
          .getDate()
          .toString()
          .padStart(2, "0");
        var startDate =
          startdateStateValue.getFullYear() + "-" + startMonth + "-" + startDay;

        var enddateStateValue = new Date(state.endDate);
        var endMonth = (enddateStateValue.getMonth() + 1)
          .toString()
          .padStart(2, "0");
        var endDay = enddateStateValue.getDate().toString().padStart(2, "0");
        var endDate =
          enddateStateValue.getFullYear() + "-" + endMonth + "-" + endDay;

        // const data1 = {
        //   establishmentId: 242,
        //   startDate: "2021-09-08T17:06:00.000Z",
        //   endDate: "2021-09-10T17:06:00.000Z",
        //   testType: 2,
        // };
        // console.log(`demo data`, data1);
        const postdata = {
          establishmentId,
          startDate,
          endDate,
          testType: this.state.testType,
        };

        paymentAPI
          .getPayment(postdata)
          .then((response) => {
            // eslint-disable-next-line
            {
              this.state.testType === 1
                ? this.setState({ testTypeData: "Antigenic" })
                : this.state.testType === 2
                ? this.setState({ testTypeData: "RT-PCR" })
                : this.setState({ testTypeData: "PCR Premium" });
            }
            this.setState({
              data: response.data,

              isFetching: false,
            });
            let sumAmount = this.state.data.map((newData) => newData.amount);
            let sumPatient = this.state.data.length;
            const reducer = (accumulator, curr) => accumulator + curr;
            this.setState({
              totalSum: sumAmount.reduce(reducer).toFixed(2),
              totalPatients: sumPatient,
            });
          })
          .catch((error) => console.log(error));
      } else {
        alert("please select Establishment and test type");
      }
    }
  };

  patientDetails = async (id, tubeID) => {
    this.props.history.push({
      pathname: "/patient-details-new",
      state: { patientID: id, tubeID: tubeID },
    });
  };

  //////////////////////////////////////////
  getEstablishmentFromUser = async () => {
    var establishmentArray = [];
    var isAdmincheck = localStorage.getItem("isAdmin");
    if (isAdmincheck.toString() === "false") {
      var establishmentNameValue = localStorage.getItem("establishmentName");
      var establishmentIdValue = localStorage.getItem("establishment");
      var objEstablishmentStatic = {
        value: establishmentIdValue,
        label: establishmentNameValue,
      };
      establishmentArray.push(objEstablishmentStatic);
      this.setState({
        establishmentsData: establishmentArray,
        isFetching: false,
        msg: "",
      });
    } else {
      await this.getEstablishment();
    }
  };

  async componentDidMount() {
    if (this.props.history.action === "POP") {
      await this.getEstablishmentFromUser();
      await this.setState({
        selectedEstablishment: this.recoverState("selectedEstablishment"),
        startDate: this.recoverState("startDate"),
        endDate: this.recoverState("endDate"),
        testType: this.recoverState("selectedTestType"),
      });
      await this.submitFilters(this.state);
    } else {
      try {
        this.setState({ isFetching: true, msg: "payment..." });
        await this.getEstablishmentFromUser();
      } catch (e) {
        console.log("error", JSON.stringify(e));
      }
    }

    // clearInterval(this.interval);
    // this.interval = setInterval(() => this.refreshState(this.state), 10000);
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    if (nextState.filterValue !== "") {
      return false;
    }

    return true;
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  async getEstablishment() {
    let establishmentArray = [];
    const responseEstablishment = await establishmentApi.getEtablishments();

    // console.log("responseEstablishment", responseEstablishment);

    if (responseEstablishment.error) {
      this.setState({ isFetching: false, msg: "Aucun patient trouvé." });
    } else {
      // eslint-disable-next-line
      responseEstablishment.data.map((responseObj, i) => {
        var objEstablishment = {
          value: responseObj.id,
          label: responseObj.name,
        };
        establishmentArray.push(objEstablishment);
      });

      this.setState({
        establishmentsData: establishmentArray,
        isFetching: false,
        msg: "",
      });
    }
  }

  async submitFilters(stateData) {
    try {
      if (stateData.selectedEstablishment == null) {
        alert("Sélectionnez l'établissement");
        return;
      }

      this.persistState("startDate", stateData.startDate);
      this.persistState("endDate", stateData.endDate);
      this.persistState(
        "selectedEstablishment",
        stateData.selectedEstablishment
      );

      // eslint-disable-next-line
      let establishmentId = stateData.selectedEstablishment.value;

      var startdateStateValue = new Date(stateData.startDate);
      var startMonth = (startdateStateValue.getMonth() + 1)
        .toString()
        .padStart(2, "0");
      var startDay = startdateStateValue.getDate().toString().padStart(2, "0");
      // eslint-disable-next-line
      var startDateValue =
        startdateStateValue.getFullYear() + "-" + startMonth + "-" + startDay;

      var enddateStateValue = new Date(stateData.endDate);

      var endMonth = (enddateStateValue.getMonth() + 1)
        .toString()
        .padStart(2, "0");
      var endDay = enddateStateValue.getDate().toString().padStart(2, "0");
      // eslint-disable-next-line
      var endDateValue =
        enddateStateValue.getFullYear() + "-" + endMonth + "-" + endDay;
    } catch (e) {
      console.log("error", JSON.stringify(e));
    }
  }

  handleChangeEstablishment = async (selectedEstablishment) => {
    try {
      await this.setState({ selectedEstablishment });
      await this.setState({ filterValue: "" });
      clearInterval(this.interval);
    } catch (e) {
      console.log("error", JSON.stringify(e));
    }
  };

  handleStartDate = async (startDate) => {
    try {
      await this.setState({ startDate });
      await this.setState({ filterValue: "" });
      clearInterval(this.interval);
    } catch (e) {
      console.log("error", JSON.stringify(e));
    }
  };
  handleEndDate = async (endDate) => {
    try {
      await this.setState({ endDate });
      await this.setState({ filterValue: "" });
      clearInterval(this.interval);
    } catch (e) {
      console.log("error", JSON.stringify(e));
    }
  };

  handleFilterValueChange = (value) => {
    this.setState({ filterValue: value });
  };

  handleChangeTestType = (e) => {
    this.setState({ testType: e.target.value });
    // console.log(e.target.value);
  };

  totalSum = () => {
    // eslint-disable-next-line
    let arr = this.state.data;
    // console.log("arr", arr);
    // eslint-disable-next-line
    const reducer = (accumulator, curr) => accumulator + curr;
  };
  downloadFile = async (refId) => {
    await paymentAPI.downloadReceipt(refId);
  };

  render() {
    const { selectedEstablishment } = this.state;
    const { establishmentsData } = this.state;

    return (
      <LayoutPageComponent>
        <p className="title">Liste des Paiements</p>

        <div className="filters">
          <div className="filterControl">
            <p> Sélectionnez la date de départ : </p>

            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                value={this.state.startDate}
                onChange={(date) => this.handleStartDate(date)}
              />
            </MuiPickersUtilsProvider>
            <p> Séletionnez la date de fin : </p>

            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                value={this.state.endDate}
                onChange={(date) => this.handleEndDate(date)}
              />
            </MuiPickersUtilsProvider>
          </div>

          <div className="filterControl">
            <p> Sélectionnez l'établissement : </p>
            <Select
              value={selectedEstablishment}
              onChange={this.handleChangeEstablishment}
              options={establishmentsData}
            />
          </div>
          <br />
          <div
            className="filterControl "
            style={{
              display: "block",
            }}
          >
            <input
              type="radio"
              value={2}
              name="testType"
              onChange={this.handleChangeTestType}
              style={{ marginRight: "1rem" }}
            />
            RT-PCR
            <input
              type="radio"
              value={1}
              name="testType"
              onChange={this.handleChangeTestType}
              style={{ marginRight: "1rem", marginLeft: "4rem" }}
            />
            Antigenic
            <input
              type="radio"
              value={3}
              name="testType"
              onChange={this.handleChangeTestType}
              style={{ marginRight: "1rem", marginLeft: "4rem" }}
            />
            PCR Premium
          </div>

          <div className="filterControlButtons">
            <button
              onClick={() => this.testSubmit(this.state)}
              class="filterbutton"
            >
              Rechercher
            </button>

            <div></div>
          </div>
        </div>

        <p>{this.state.msg}</p>
        <div className="tableBox">
          {this.state.data.length > 0 && (
            <Fragment>
              <p className="title">{this.state.tableheading}</p>
              <h4>Total Amount: {this.state.totalSum} €</h4>
              <h4>Total Patients: {this.state.totalPatients}</h4>

              <Table aria-label="caption table">
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Patient ID</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Surname</TableCell>
                    <TableCell>Axepta reference</TableCell>
                    <TableCell>Montant</TableCell>
                    <TableCell>Date du paiement</TableCell>
                    <TableCell>Receipt</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.data.map((row) => {
                    row.createdAt = moment(row.createdAt).format(
                      "YYYY-MM-DD HH:mm:ss"
                    );
                    return (
                      <TableRow key={row.companyId}>
                        <TableCell component="th" scope="row">
                          {row.id}
                        </TableCell>

                        <TableCell>
                          <Link
                            to={{
                              pathname: "/patient-details-new",
                              state: {
                                patientID: row.patientId,
                                tubeID: row.tubeId,
                              },
                            }}
                          >
                            <p
                              style={{
                                color: "#4867d8",
                                textDecoration: "none",
                              }}
                              // disabled={props.disable}
                            >
                              {row.patientId}
                            </p>
                          </Link>
                        </TableCell>
                        <TableCell>{row.patient?.firstName}</TableCell>
                        <TableCell>{row.patient?.lastName}</TableCell>
                        <TableCell>{row.paymentRefId}</TableCell>
                        <TableCell>{row.amount}</TableCell>
                        <TableCell>{row.createdAt}</TableCell>
                        <TableCell>
                          <Button
                            color="primary"
                            variant="contained"
                            onClick={() => this.downloadFile(row.paymentRefId)}
                          >
                            download
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>

              <br />
            </Fragment>
          )}
        </div>
      </LayoutPageComponent>
    );
  }
}

export default Payment;
