import React from "react";
import { Page, Text, Document, StyleSheet, Image } from "@react-pdf/renderer";
import moment from "moment";
import logo from "../../assets/logo-blue/logo-blue@3x.png";

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  title: {
    fontSize: 18,
    textAlign: "center",
    fontFamily: "Times-Roman",
    marginBottom: 20,
    fontWeight: "bold",
  },
  identityContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginRight: 20,
  },
  author: {
    fontSize: 14,
    textAlign: "center",
    marginBottom: 20,
    fontFamily: "Times-Roman",
  },
  subHeadTitle: {
    fontSize: 14,
    textAlign: "center",
    marginBottom: 20,
    fontFamily: "Times-Roman",
    fontWeight: "bold",
  },
  subtitle: {
    fontSize: 14,
    marginTop: 18,
    marginBottom: 18,
    fontFamily: "Times-Roman",
    fontWeight: "bold",
    textDecoration: "underline",
  },
  text: {
    marginTop: 6,
    marginBottom: 6,
    fontSize: 14,
    textAlign: "justify",
    fontFamily: "Times-Roman",
  },
  image: {
    width: 120,
    height: 30,
  },
  qrImage: {
    width: 80,
    height: 80,
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: "center",
    color: "grey",
  },
  headerContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 10,
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
});

const PatientPDF = ({ patient, qr }) => {
  const displaySymptomDescription = (sympCode) => {
    let sympDescription = "";
    switch (sympCode) {
      case "ASY":
        sympDescription = "Asymptomatique";
        break;
      case "S01":
        sympDescription =
          "Symptômes apparus le jour ou la veille du prélèvement";
        break;
      case "S24":
        sympDescription =
          "Symptômes apparus 2, 3 ou 4 jours avant le prélèvement";
        break;
      case "S57":
        sympDescription =
          "Symptômes apparus 5, 6 ou 7 jours avant le prélèvement";
        break;
      case "S814":
        sympDescription =
          "Symptômes apparus entre 8 et 14 jours avant le prélèvement";
        break;
      case "SS34":
        sympDescription =
          "Symptômes apparus entre 15 et 28 jours avant le prélèvement";
        break;
      case "SP4S":
        sympDescription =
          "Symptômes apparus plus de quatre semaines avant le prélèvement";
        break;
      default:
        sympDescription = "Ne sait pas";
    }

    return sympDescription;
  };

  const displayFullAccommodationType = (accommodationType) => {
    let fullTextAccommodationType = "";
    switch (accommodationType) {
      case "I":
        fullTextAccommodationType =
          "Hébergement individuel privé (maison, appartement)";
        break;
      case "H":
        fullTextAccommodationType = "Hospitalisé au moment du prélèvement";
        break;
      case "E":
        fullTextAccommodationType = "Résident en EHPAD";
        break;
      case "C":
        fullTextAccommodationType = "En milieu carcéral";
        break;
      case "A":
        fullTextAccommodationType = "Autre structure d’hébergement collectif";
        break;
      case "U":
        fullTextAccommodationType = "Ne sait pas";
        break;
      default:
        fullTextAccommodationType = "Ne sait pas";
    }

    return fullTextAccommodationType;
  };

  return (
    <Document>
      <Page style={styles.body}>
        <div style={styles.headerContainer}>
          <Image style={styles.image} src={logo} />

          <Image style={styles.qrImage} src={qr} />
        </div>
        <Text style={styles.title}>Patient Recap</Text>

        <Text style={styles.subHeadTitle}>Patient ID : {patient.id}</Text>
        <div style={styles.identityContainer}>
          <Text style={styles.author}>
            First Name : {patient.firstName?.toUpperCase()}
          </Text>
          <Text style={styles.author}>
            Last Name : {patient.lastName?.toUpperCase()}
          </Text>
          <Text style={styles.author}>
            Birth Name : {patient.birthName?.toUpperCase()}
          </Text>
        </div>
        <div style={styles.identityContainer}>
          <Text style={styles.text}>Gender : {patient.gender}</Text>
          <Text style={styles.text}>
            Birthdate :{" "}
            {moment(patient.dateOfBirth.split("T")[0]).format("DD/MM/YYYY")}
          </Text>
          <Text style={styles.text}>
            Place of birth : {patient.birthPlace?.toUpperCase()}
          </Text>
        </div>

        <Text style={styles.text}>
          Nationality : {patient.nationality?.toUpperCase()}
        </Text>
        <Text style={styles.text}>
          Email address : {patient.email?.toLowerCase()}
        </Text>

        <Text style={styles.text}>Phone Number : {patient.phoneNumber}</Text>
        <Text style={styles.text}>
          Social security number : {patient.nir?.toUpperCase()}
        </Text>
        <Text style={styles.text}>
          Address :{" "}
          {
            // patient.address.streetNumber +
            //   " " +
            patient.address?.street?.toUpperCase()
          }
        </Text>
        <div style={styles.identityContainer}>
          <Text style={styles.text}>
            Postal code : {patient.address?.zip?.toUpperCase()}
          </Text>
          <Text style={styles.text}>
            City : {patient.address?.city?.toUpperCase()}
          </Text>
          <Text style={styles.text}>
            Country : {patient.address?.country?.toUpperCase()}
          </Text>
        </div>

        <Text style={styles.text}>
          Date of creation :{" "}
          {moment(patient.createdAt.split("T")[0]).format("DD/MM/YYYY")}
        </Text>

        <Text style={styles.subtitle}>Covid-19 test information</Text>

        <Text style={styles.text}>
          Accommodation :{" "}
          {displayFullAccommodationType(patient.accommodationType)}
        </Text>
        <Text style={styles.text}>
          Symptoms : {displaySymptomDescription(patient.patientEnhanced?.APSYM)}
        </Text>
        <Text style={styles.text}>
          Risk factors : {patient.patientEnhanced?.riskFactor ? "OUI" : "NON"}
        </Text>
        <Text style={styles.text}>
          Health professional :{" "}
          {patient.user.firstName + " " + patient.user?.lastName?.toUpperCase()}
        </Text>
        <Text style={styles.text}>
          Date of collection :{" "}
          {moment(patient.tube.createdAt.split("T")[0]).format("DD/MM/YYYY")}
        </Text>
        <Text style={styles.text}>
          Tube barcode : {patient.tube.barcodeTube}
        </Text>

        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
};
export default PatientPDF;
