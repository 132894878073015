import React, { useState } from "react";
import * as manufacturerApi from "../../api/Manufacturer";
import IncubationComponent from "../../components/IncubationComponent";
import "../../style/style.scss";
import Lines from "../../components/LinesComponent";
import LayoutPageComponent from "../../components/LayoutPageComponent";

const CreateManufacturerTest = (props) => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [brand, setBrand] = useState("");
  const [RDT_type, setRDT_type] = useState("");
  const [manufacturer, setManufacturer] = useState("");
  const [disease, setDisease] = useState("");
  const [incubation, setIncubation] = useState("");
  const [lines, setLines] = useState({});
  const [reference, setReference] = useState("");
  // eslint-disable-next-line
  const [available, setAvailable] = useState(true);
  const [msg, setMsg] = useState("");

  const validIncubationFields = (incubation) => {
    const min = parseInt(incubation.min);
    const max = parseInt(incubation.max);
    const nominal = parseInt(incubation.nominal);

    if (min > max || nominal < min || nominal > max) {
      return false;
    }

    return true;
  };

  //Create manufacturer test when form is submitted
  const handleSubmit = async (e) => {
    setMsg("");
    e.preventDefault();
    if (
      name.trim() !== "" &&
      description.trim() !== "" &&
      brand.trim() !== "" &&
      RDT_type.trim() !== "" &&
      manufacturer.trim() !== "" &&
      disease.trim() !== "" &&
      incubation.min.trim() !== "" &&
      incubation.max.trim() !== "" &&
      incubation.nominal.trim() !== "" &&
      Object.keys(lines).length > 0 &&
      reference.trim() !== ""
    ) {
      if (!validIncubationFields(incubation)) {
        setMsg(
          "Attention, veuillez vérifier les valeurs du temps d’incubation"
        );
        return;
      }

      const manufacturerTest = {
        name: name,
        display_name: name,
        description: description,
        brand: brand,
        deki_device: "",
        RDT_type: RDT_type,
        manufacturer: manufacturer,
        disease: disease,
        incubation: {
          min: parseInt(incubation.min.trim()),
          max: parseInt(incubation.max.trim()),
          nominal: parseInt(incubation.nominal.trim()),
        },
        lines: lines,
        nb_line: Object.keys(lines).length,
        reference: reference,
        available: available,
      };
      const response = await manufacturerApi.createManufacturerTest(
        manufacturerTest
      );
      setMsg(
        response.error
          ? "La création de ce test a échoué."
          : "La création de ce test s'est effectué avec succès."
      );
    } else {
      setMsg("Remplissez correctement les champs svp.");
    }
  };

  return (
    <LayoutPageComponent>
      <p className="title">Création d’un test</p>
      <p>Ce formulaire ne permet que les tests antigéniques</p>
      <form className="form" onSubmit={handleSubmit}>
        <div>
          <p>Nom du test *</p>
          <input
            type="text"
            name="name"
            className="input-large"
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div>
          <p>Description *</p>
          <input
            type="text"
            name="description"
            className="input-large"
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>
        <div>
          <p>Marque *</p>
          <input
            type="text"
            name="brand"
            className="input-large"
            onChange={(e) => setBrand(e.target.value)}
          />
        </div>
        <div>
          <p>Type de test légal *</p>
          <input
            type="text"
            name="RDT_type"
            className="input-large"
            onChange={(e) => setRDT_type(e.target.value)}
          />
        </div>
        <div>
          <p>Fabriquant *</p>
          <input
            type="text"
            name="manufacturer"
            className="input-large"
            onChange={(e) => setManufacturer(e.target.value)}
          />
        </div>
        <div>
          <p>Pathologie *</p>
          <input
            type="text"
            name="disease"
            className="input-large"
            onChange={(e) => setDisease(e.target.value)}
          />
        </div>
        <p>Incubation (en secondes) *</p>
        <IncubationComponent incubation={setIncubation} />
        <div>
          <p>Lignes *</p>
          <Lines selectedLines={lines} setLines={setLines} />
        </div>
        <div>
          <p>Reference *</p>
          <input
            type="text"
            name="reference"
            className="input-large"
            onChange={(e) => setReference(e.target.value)}
          />
        </div>
        <div>
          <button type="submit" className="btnMain">
            Valider
          </button>
        </div>
        <p>{msg}</p>
      </form>
    </LayoutPageComponent>
  );
};

export default CreateManufacturerTest;
