import React, { Fragment } from "react";
import * as companyApi from "../../api/Company";
import LayoutPageComponent from "../../components/LayoutPageComponent";
import Select from "react-select";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { Button } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

class Sepa extends React.Component {
  constructor(props) {
    super(props);

    this.companyID = this.props.history.location["state"]
      ? this.props.history.location["state"]
      : null;
    let currentYear = new Date();

    this.state = {
      isFetching: false,
      msgCompany: "",
      msgSepaGenerated: "",
      companyIds: [],
      companyData: [],
      selectedMonth: null,
      months: [
        { value: "01", label: "Janvier" },
        { value: "02", label: "Février" },
        { value: "03", label: "Mars" },
        { value: "04", label: "Avril" },
        { value: "05", label: "Mai" },
        { value: "06", label: "Juin" },
        { value: "07", label: "Juillet" },
        { value: "08", label: "Août" },
        { value: "09", label: "Septembre" },
        { value: "10", label: "Octobre" },
        { value: "11", label: "Novembre" },
        { value: "12", label: "Décembre" },
      ],
      companyTableHeader: [
        {
          name: "ID",
          selector: "companyId",
          sortable: true,
        },
        {
          name: "Société",
          selector: "companyName",
          sortable: true,
        },
        {
          name: "Facture",
          selector: "invoiceName",
          sortable: true,
        },
        {
          name: "Total HT",
          selector: "totalAmount",
          sortable: true,
        },
        {
          name: "Total TTC",
          selector: "finalAmount",
          sortable: true,
        },
        {
          name: "Status",
          selector: "status",
          sortable: true,
        },
      ],
      selectedYear: currentYear,
    };
  }

  handleMonthChange = (month) => {
    this.setState({
      selectedMonth: month,
    });
  };

  handleYearChange = (year) => {
    this.setState({
      selectedYear: year,
    });
  };

  handleGetCompanies = async ({ selectedMonth, selectedYear }) => {
    if (!selectedMonth) {
      alert("Please select a month");
      return;
    }

    if (selectedYear === "") {
      alert("Please select year");
      return;
    }

    this.setState({
      isFetching: true,
      msgCompany: "Patientez svp...",
      companyData: [],
      msgSepaGenerated: "",
    });
    const response = await companyApi.getInvoiceGeneratedCompany(
      selectedMonth.value,
      selectedYear.getFullYear()
    );

    if (response.error) {
      this.setState({
        isFetching: false,
        msgCompany: "Aucune société trouvée pour cette période.",
      });
    } else {
      let data = response.data;
      if (data && data.length !== 0) {
        data.map((company) => {
          company.totalAmount = parseFloat(company.totalAmount).toFixed(2);
          company.finalAmount = parseFloat(company.finalAmount).toFixed(2);
          return company;
        });

        this.setState({ isFetching: false, msgCompany: "", companyData: data });
      } else {
        this.setState({
          isFetching: false,
          msgCompany: "Aucune société trouvée pour cette période.",
        });
      }
    }
  };

  handleSelectedRow = (selection) => {
    let companyIds = [];
    // eslint-disable-next-line
    selection.selectedRows.map((row) => {
      companyIds.push(row.companyId);
    });
    this.setState({ companyIds: companyIds });
  };

  handleGenerateSepaXML = async () => {
    const { companyIds, selectedMonth, selectedYear } = this.state;

    if (companyIds.length === 0) {
      alert("Please select one or more companies");
      return;
    }

    const data = {
      month: selectedMonth.value,
      year: selectedYear.getFullYear(),
      companyIds: companyIds,
    };

    const response = await companyApi.generateSepaXML(data);

    if (response.error) {
      this.setState({
        msgSepaGenerated: "Unable to generate Sepa XML.",
      });
    } else {
      let data = response.data;
      this.setState({ isFetching: false, msgSepaGenerated: data.message });
    }
  };

  convertStatus = (value) => {
    if (value.toLowerCase() === "pending") {
      return "en cours";
    } else if (value.toLowerCase() === "confirmed") {
      return "envoyé";
    }
  };

  handleSelect = (id) => {
    let ids = this.state.companyIds;
    let selectedid = this.state.companyIds.indexOf(id);
    if (selectedid === -1) {
      ids.push(id);
    } else {
      ids.splice(selectedid, 1);
    }

    this.setState({ companyIds: ids });
  };

  handleSelectAll = async () => {
    if (this.state.companyIds.length === this.state.companyData.length) {
      await this.setState({ companyIds: [] });
    } else {
      let allCompanyIds = this.state.companyData.map(
        (newdata) => newdata.companyId
      );
      await this.setState({ companyIds: allCompanyIds });
    }
  };

  handleChecked = (id) => {
    if (this.state.companyIds.indexOf(id) === -1) {
      return false;
    } else {
      return true;
    }
  };

  render() {
    const {
      companyData,

      months,
      selectedMonth,
      selectedYear,
      msgSepaGenerated,
    } = this.state;
    return (
      <LayoutPageComponent>
        <div className="filters-container">
          <div className="filterControlContainer">
            <p> Select month : </p>
            <Select
              value={selectedMonth}
              onChange={this.handleMonthChange}
              options={months}
            />
          </div>
          <div className="filterControlContainer">
            <p> Select year : </p>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                views={["year"]}
                value={selectedYear}
                onChange={this.handleYearChange}
              />
            </MuiPickersUtilsProvider>
          </div>
          <div className="filterControlContainer">
            <Button
              variant="contained"
              color="primary"
              onClick={() => this.handleGetCompanies(this.state)}
            >
              Generate
            </Button>
          </div>
        </div>

        <p>{this.state.msgCompany}</p>
        {companyData && companyData.length !== 0 && (
          <Fragment>
            <p className="title">
              Sociétés ayant des factures générées au {selectedMonth.value}/
              {selectedYear.getFullYear()}{" "}
            </p>

            <Table aria-label="caption table">
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox onChange={() => this.handleSelectAll()} />
                  </TableCell>
                  <TableCell>ID</TableCell>
                  <TableCell>Société</TableCell>
                  <TableCell>Facture</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Total HT</TableCell>
                  <TableCell>Total TTC</TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {companyData.map((row) => {
                  let check1 = this.handleChecked(row.companyId);
                  return (
                    <TableRow key={row.companyId}>
                      <TableCell padding="checkbox">
                        <Checkbox
                          onChange={() => this.handleSelect(row.companyId)}
                          checked={check1}
                        />
                      </TableCell>

                      <TableCell component="th" scope="row">
                        {row.companyId}
                      </TableCell>
                      <TableCell>{row.companyName}</TableCell>
                      <TableCell>{row.invoiceName}</TableCell>
                      <TableCell>{row.email}</TableCell>
                      <TableCell>{row.totalAmount}</TableCell>
                      <TableCell>{row.finalAmount}</TableCell>
                      <TableCell>{this.convertStatus(row.status)}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>

            <br />
            <Button
              variant="contained"
              color="primary"
              onClick={this.handleGenerateSepaXML}
            >
              Generate SEPA XML
            </Button>
            <p>{msgSepaGenerated}</p>
          </Fragment>
        )}
      </LayoutPageComponent>
    );
  }
}

export default Sepa;
