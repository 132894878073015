import React, { Fragment } from 'react'
import * as packingApi from '../../api/Packing'
import LayoutPageComponent from '../../components/LayoutPageComponent'
import moment from 'moment'
import PouchesListTable from '../../components/PouchesListTableComponent'

class PackingDetails extends React.Component {
  constructor(props) {
    super(props)

    this.packingId = this.props.history.location['state']
      ? this.props.history.location['state']
      : null

    this.state = {
      isFetching: false,
      msgPacking: this.packingId === null ? 'Aucun emballage sélectionné' : '',
      packingId: this.packingId !== null ? this.packingId : '',
      packingData: {},
      pouchesData: [],
    }
  }

  async componentDidMount() {
    this.setState({ isFetching: true, msgPatient: 'Patientez svp...' })
    const response = await packingApi.getPacking(this.state.packingId)

    if (response.error) {
      this.setState({
        isFetching: false,
        msgPatient: 'Aucun emballage trouvé',
      })
    } else {
      let data = response.data
      this.setState({
        packingData: data?.packing,
        pouchesData: data?.pouches.sort(function (a, b) {
          return new Date(b.createdAt) - new Date(a.createdAt)
        }),
        isFetching: false,
        msgPatient: '',
      })
    }
  }

  render() {
    const { msgPacking, packingData, pouchesData } = this.state

    return (
      <LayoutPageComponent>
        {msgPacking === '' && (
          <Fragment>
            <p className="title">{`${packingData.packingRef}, ${moment(
              packingData.createdAt
            ).format('DD/MM/YYYY')}`}</p>

            <p>{msgPacking}</p>

            <PouchesListTable
              data={pouchesData}
              setFilterValue={this.handleFilterValueChange}
            />

            <br />
            <p className="title">statut : {packingData.packingStatus}</p>
          </Fragment>
        )}

        {msgPacking !== '' && <p>{msgPacking}</p>}
      </LayoutPageComponent>
    )
  }
}

export default PackingDetails
