//This Hook represent the user creation page
import React from 'react';
import { withRouter } from 'react-router-dom';
import LoginComponent from "../../components/LoginComponent";
import logo from '../../assets/logo/logo@3x.png';
import doctors from '../../assets/doctors/group-5@3x.png';

const Login = (props) => {

    return (
        <div className="row-login-page">
            <div className="header-login">
                <div className="home-logo-container">
                    <img src={logo} alt="Logo" className="menu-logo" />
                </div>
            </div>
            <div className="login-image-name-container">
                <img src={doctors} alt="Doctors" className="login-image" />
                <p className="login-title">Espace admin Test-N-Track et MonTestRapide</p>
            </div>
            <div>
                <LoginComponent />
            </div>
            <div>
                <p className="powered-text">Powered par Magentine Healthcare SAS</p>
            </div>
        </div>
    );
}

export default withRouter(Login);