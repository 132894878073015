import logo from '../assets/logo/logo@3x.png'
import CustomLink from './CustomLink'
import '../style/menu_style.scss'
import { Fragment } from 'react'
import closeMenu from '../assets/menu/close_menu.svg'

const MenuComponent = (props) => {
  const token = localStorage.getItem('token')

  //Get user logged
  const userFirstName = JSON.parse(localStorage.getItem('user')).name
  const isPaymentEnable = JSON.parse(localStorage.getItem('user')).payment
  // const userInfo = JSON.parse(localStorage.getItem("isPaymentEnable"));
  // const isAdmin = JSON.parse(localStorage.getItem("user")).key;
  var isAdmincheck = localStorage.getItem('isAdmin')
  let isOperator = localStorage.getItem('isOperator')
  var canImport = localStorage.getItem('canImport')
  const { hideMenu, setHideMenu, smallSize } = props

  return (
    <Fragment>
      {!hideMenu && (
        <div className="left-zone">
          <div className="left-card">
            {smallSize && (
              <div
                onClick={() => setHideMenu(true)}
                className={'close-menu-btn'}
              >
                <img src={closeMenu} alt={'Close menu'} />
              </div>
            )}
            <div className="step-card">
              <div>
                <div className="menu-logo-container">
                  <img src={logo} alt="Logo" className="menu-logo" />
                </div>
                <p className="user-logged">Bonjour, {userFirstName}</p>
              </div>
              {token != null && (
                <div>
                  <CustomLink className="menu-row-input" tag="div" to="/home">
                    <div className="menu-text-zone">
                      <p className="menu-other-text">Accueil</p>
                    </div>
                  </CustomLink>
                  {isAdmincheck === 'true' && (
                    <CustomLink
                      className="menu-row-input"
                      tag="div"
                      to="/create-user"
                    >
                      <div className="menu-text-zone">
                        <p className="menu-other-text">Utilisateurs</p>
                      </div>
                    </CustomLink>
                  )}
                  {isAdmincheck === 'true' && (
                    <CustomLink
                      className="menu-row-input"
                      tag="div"
                      to="/list-company"
                    >
                      <div className="menu-text-zone">
                        <p className="menu-other-text">Sociétés</p>
                      </div>
                    </CustomLink>
                  )}

                  {isAdmincheck === 'true' && (
                    <CustomLink
                      className="menu-row-input"
                      tag="div"
                      to="/list-etablishment"
                    >
                      <div className="menu-text-zone">
                        <p className="menu-other-text">Établissements</p>
                      </div>
                    </CustomLink>
                  )}
                  {(isAdmincheck === 'true' || isPaymentEnable === 'yes') &&
                    isOperator === 'false' && (
                      <CustomLink
                        className="menu-row-input"
                        tag="div"
                        to="/payment"
                      >
                        <div className="menu-text-zone">
                          <p className="menu-other-text">Paiement</p>
                        </div>
                      </CustomLink>
                    )}

                  {isAdmincheck === 'true' && (
                    <CustomLink
                      className="menu-row-input"
                      tag="div"
                      to="/list-manufacturer-test"
                    >
                      <div className="menu-text-zone">
                        <p className="menu-other-text">Test (catalogue)</p>
                      </div>
                    </CustomLink>
                  )}

                  <CustomLink
                    className="menu-row-input"
                    tag="div"
                    to="/list-patient"
                  >
                    <div className="menu-text-zone">
                      <p className="menu-other-text">Patients</p>
                    </div>
                  </CustomLink>

                  {isOperator === 'false' && (
                    <CustomLink
                      className="menu-row-input"
                      tag="div"
                      to="/export-patient"
                    >
                      <div className="menu-text-zone">
                        <p className="menu-other-text">Outil d'export</p>
                      </div>
                    </CustomLink>
                  )}

                  {canImport === 'true' && isOperator === 'false' && (
                    <CustomLink
                      className="menu-row-input"
                      tag="div"
                      to="/import-patient"
                    >
                      <div className="menu-text-zone">
                        <p className="menu-other-text">Import Utility</p>
                      </div>
                    </CustomLink>
                  )}
                  {isAdmincheck === 'true' && (
                    <CustomLink
                      className="menu-row-input"
                      tag="div"
                      to="/list-result"
                    >
                      <div className="menu-text-zone">
                        <p className="menu-other-text">Résultats</p>
                      </div>
                    </CustomLink>
                  )}

                  {(isOperator === 'false' || isOperator === 'true') && (
                    <CustomLink
                      className="menu-row-input"
                      tag="div"
                      to="/packing-list"
                    >
                      <div className="menu-text-zone">
                        <p className="menu-other-text">Packing list</p>
                      </div>
                    </CustomLink>
                  )}

                  {isAdmincheck === 'true' && (
                    <CustomLink
                      className="menu-row-input"
                      tag="div"
                      to="/list-patient-old"
                    >
                      <div className="menu-text-zone">
                        <p className="menu-other-text">Patients Legacy</p>
                      </div>
                    </CustomLink>
                  )}
                  <br />
                  <a
                    className="menu-row-input"
                    href="https://admin.testrapi.de/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="menu-text-zone">
                      <p className="menu-other-text">Gestion utilisateurs</p>
                    </div>
                  </a>
                  <a
                    className="menu-row-input"
                    href="https://montestrapide.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="menu-text-zone">
                      <p className="menu-other-text">MonTestRapide.com</p>
                    </div>
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </Fragment>
  )
}

export default MenuComponent
