//This Hook represent layout of all pages in app
import React, {useEffect, useState} from 'react';
import MenuComponent from './MenuComponent';
import HeaderComponent from './HeaderComponent';
import AuthWrapper from "./AuthWrapper";

const LayoutPageComponent = (props) => {

    const [hideMenu, setHideMenu] = useState(false);
    const [smallSize, setSmallSize] = useState(false);

    const handleResize = () => {
        if (window.innerWidth < 1000) {
            setHideMenu(true);
            setSmallSize(true);
        } else {
            setHideMenu(false);
            setSmallSize(false);
        }
    }

    useEffect(() => {
        handleResize();
    }, []);

    useEffect(() => {
        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <AuthWrapper>
            <div className="row">
                <MenuComponent hideMenu={hideMenu} setHideMenu={setHideMenu} smallSize={smallSize} />
                <div className="zone scrollable-zone">
                    <HeaderComponent hideMenu={hideMenu} setHideMenu={setHideMenu} smallSize={smallSize} />
                    {props.children}
                </div>
            </div>
        </AuthWrapper>
    );
}

export default LayoutPageComponent;