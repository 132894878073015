export function redirectToCompanyDetails(history){
    //Redirect user if he doesn't have requested role
    const user = JSON.parse(localStorage.getItem("user"));
    const userCompany = user.company; 
    const isAdmin = user.key;

    if(!isAdmin){
        history.push({
            pathname: "/company-details",
            state: userCompany
        });
    }
}

export function redirectToEtablishmentList(history){
    //Redirect user if he doesn't have requested role
    const user = JSON.parse(localStorage.getItem("user"));
    const userCompany = user.company; 
    const isAdmin = user.key;

    if(!isAdmin){
        history.push({
            pathname: "/list-etablishment",
            state: userCompany
        });
    }
}