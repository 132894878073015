import React from "react";
import * as DateUtils from "../utils/DateUtils";
import CustomLink from "./CustomLink";

const UserDetailsTableComponent = (props) => {
  const displayFullRole = (role) => {
    let fullRoleName = "";
    switch (role) {
      case "p":
        fullRoleName = "Préleveur";
        break;
      case "t":
        fullRoleName = "Testeur";
        break;
      case "p_t":
        fullRoleName = "Préleveur-Testeur";
        break;
      default:
        fullRoleName = "N/D";
    }

    return fullRoleName;
  };

  const displayAccess = (isOperator) => {
    if (isOperator === true) {
      return "Operator";
    } else {
      return "Health proffessionel";
    }
  };

  return (
    <table className="table-recap">
      <tr className="recap-row">
        <td>
          <p className="recap-label">Titre</p>
        </td>
        <td>
          <p className="label">{props.data.title}</p>
        </td>
      </tr>
      <tr className="recap-row">
        <td>
          <p className="recap-label">Prénom</p>
        </td>
        <td>
          <p className="label">{props.data.firstName}</p>
        </td>
      </tr>
      <tr className="recap-row">
        <td>
          <p className="recap-label">Nom</p>
        </td>
        <td>
          <p className="label">{props.data.lastName}</p>
        </td>
      </tr>
      <tr className="recap-row">
        <td>
          <p className="recap-label">Active</p>
        </td>
        <td>
          <p className="label">{props.data.active ? "Oui" : "Non"}</p>
        </td>
      </tr>
      <tr className="recap-row">
        <td>
          <p className="recap-label">Email</p>
        </td>
        <td>
          <p className="label">{props.data.email}</p>
        </td>
      </tr>
      <tr className="recap-row">
        <td>
          <p className="recap-label">Rôle</p>
        </td>
        <td>
          <p className="label">{displayFullRole(props.data.role)}</p>
        </td>
      </tr>
      <tr className="recap-row">
        <td>
          <p className="recap-label">Access</p>
        </td>
        <td>
          <p className="label">{displayAccess(props.data.isOperator)}</p>
        </td>
        <td>
          <button
            style={{
              display: "inline-block",
              padding: "0.25rem 0.50rem",
              backgroundColor: "#4867d8",
              boxShadow: "0 0 20px -10px rgb(0 0 0 / 50%)",
              color: "#ffffff",
              fontWeight: "bold",
              border: "0",
              width: "140px",
              height: "40px",
              borderRadius: "10px",
              cursor: "pointer",
            }}
            onClick={props.handleChange}
          >
            Switch
          </button>
        </td>
      </tr>
      <tr className="recap-row">
        <td>
          <p className="recap-label">Société</p>
        </td>
        <td>
          {props.data.company && (
            <p className="label">
              <CustomLink
                className="see-more-link"
                tag="a"
                to={{
                  pathname: "/company-details",
                  state: props.data.company.id,
                }}
              >
                {props.data.company.name}
              </CustomLink>
            </p>
          )}
        </td>
      </tr>
      <tr className="recap-row">
        <td>
          <p className="recap-label">Établissement</p>
        </td>
        <td>
          {props.data.establishment && (
            <p className="label">
              <CustomLink
                className="see-more-link"
                tag="a"
                to={{
                  pathname: "/etablishment-details",
                  state: props.data.establishment.id,
                }}
              >
                {props.data.establishment.name}
              </CustomLink>
            </p>
          )}
        </td>
      </tr>
      <tr className="recap-row">
        <td>
          <p className="recap-label">Date de création</p>
        </td>
        <td>
          <p className="label">
            {DateUtils.dateToFrenchFormat(props.data.createdAt)}
          </p>
        </td>
      </tr>
      <tr className="recap-row">
        <td>
          <p className="recap-label">Date de modification</p>
        </td>
        <td>
          <p className="label">
            {DateUtils.dateToFrenchFormat(props.data.updatedAt)}
          </p>
        </td>
      </tr>
    </table>
  );
};

export default UserDetailsTableComponent;
