import React from 'react';
import Datatable from './DatatableComponent';
import * as patientApi from '../api/Patient';
import * as DateUtils from '../utils/DateUtils'
import "../style/style.scss";


const LegacyPatientListTable = (props) => {
    const columns = [
        {
            "Header": "ID Patient",
            "accessor": "id",
            "isLink": false
        },
        {
            "Header": "Nom d'usage",
            "accessor": "lastName.toShow",
            "isLink": true,
            "searchable": true
        },
        {
            "Header": "Prénom",
            "accessor": "firstName.toShow",
            "isLink": true,
            "searchable": true
        },
        {
            "Header": "Email",
            "accessor": "email",
            "isLink": false
        },
        {
            "Header": "Phone",
            "accessor": "phoneNumber",
            "isLink": false
        },
        {
            "Header": "Date",
            "accessor": "date",
            "isLink": false
        },
        {
            "Header": "Résultat",
            "accessor": "rapport.toShow",
            "isLink": true
        },
    ];

    var patientData = [];

    const handleDownload = (patientID) => {
        patientApi.downloadPatientReport(patientID).then((value) => {
            if (value["error"]) {
                alert("Rapport indisponible.");
            } else {
                alert("Rapport téléchargé avec succès.");
            }
        });
    }

    props.data.forEach(element => {
        patientData.push({
            id: element.id,
            lastName: { toShow: element.lastName, linkTo: element.id, pathName: "/patient-details" },
            firstName: { toShow: element.firstName, linkTo: element.id, pathName: "/patient-details" },
            email: element.email,
            phoneNumber: element.phoneNumber,
            date: DateUtils.dateToFrenchFormat(element.createdAt),
            rapport: { toShow: "Rapport", linkTo: element.id, download: true },
        });
    });

    return (
        <Datatable columns={columns} data={patientData} action={handleDownload} showFilterBar={true} />
    );
}

export default LegacyPatientListTable;