//This Hook represent home page
import React from "react";
import LayoutPageComponent from "../components/LayoutPageComponent";
import HomeActionsComponent from "../components/HomeActionsComponent";

const Home = (props) => {
  return (
    <LayoutPageComponent>
      <div className="home-container">
        <p className="home-title">Page d’accueil</p>
        <p className="home-title-action">Sélectionner une action</p>
        <HomeActionsComponent />
      </div>
    </LayoutPageComponent>
  );
};

export default Home;
