import React from "react";
import Datatable from "./DatatableComponent";
import ListEntityHeader from "../pages_config/list_entity_header.json";
import * as DateUtils from "../utils/DateUtils";
import "../style/style.scss";

const EntityListTable = (props) => {
  //Get column from ListEntityHeader in pages configuration
  const columns = ListEntityHeader;

  var entityData = [];

  props.data.forEach((element) => {
    entityData.push({
      id: element?.id,
      name: {
        toShow: element.name,
        linkTo: element.id,
        pathName: "/etablishment-details",
      },
      finess: element?.finess,
      type: element?.type,
      zip: element?.address?.zip,
      createdAt: DateUtils.dateToFrenchFormat(element.createdAt),
    });
  });

  return <Datatable columns={columns} data={entityData} />;
};

export default EntityListTable;
