import React, { useState } from 'react'
import '../style/style.scss'
import moment from 'moment'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import * as patientApi from '../api/Patient'
import { Link } from 'react-router-dom'
import { useEffect } from 'react'

const PouchesListTable = (props) => {
  const [tube, setTube] = useState([])
  const [loading, setLoading] = useState(true)

  const isOperator = localStorage.getItem('isOperator')

  useEffect(() => {
    const getTubeData = async () => {
      if (props.data.length > 0) {
        let dataMap = new Map()
        props.data.forEach((item) => {
          dataMap.set(item.tubeId, item)
        })
        for (let i = 0; i < props.data.length; i++) {
          try {
            const responseTube = await patientApi.getTube(props.data[i]?.tubeId)
            dataMap.set(props.data[i].tubeId, {
              ...dataMap.get(props.data[i].tubeId),
              tubeDetails: responseTube.data[0],
            })
          } catch (e) {
            console.log(e)
          }
        }

        let datas = Array.from(dataMap, ([k, v]) => ({ ...v }))
        setTube(datas)
        setLoading(false)
      }
    }

    getTubeData()
  }, [props.data.length])

  return (
    <>
      {loading && 'Loading'}
      {!loading && (
        <>
          <Table aria-label="caption table" style={{ width: '50%' }}>
            <TableHead>
              <TableRow>
                <TableCell>établissement</TableCell>
                <TableCell>N° pochette</TableCell>
                <TableCell>heure</TableCell>
                <TableCell>tube associé</TableCell>
                <TableCell>patient associé</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!loading &&
                tube.length > 0 &&
                tube.map((value) => {
                  return (
                    <TableRow>
                      <TableCell>{value.establishmentId}</TableCell>
                      <TableCell component="th" scope="row">
                        {value.pouchBarcode}
                      </TableCell>
                      <TableCell>
                        {moment(value.createdAt).format('DD/MM/YYYY  HH:mm')}
                      </TableCell>

                      <TableCell>
                        {value.tubeDetails.tube.barcodeTube}
                      </TableCell>
                      {isOperator === 'false' ? (
                        <TableCell>
                          <Link
                            style={{
                              textDecoration: 'none',
                            }}
                            to={{
                              pathname: '/patient-details-new',
                              state: {
                                patientID: value.patientId,
                                tubeID: value.tubeId,
                              },
                            }}
                          >
                            <p
                              style={{
                                color: '#4867d8',
                              }}
                              // disabled={props.disable}
                            >
                              {value.patientId}
                            </p>
                          </Link>
                        </TableCell>
                      ) : (
                        <TableCell>{value.patientId}</TableCell>
                      )}
                    </TableRow>
                  )
                })}
            </TableBody>
          </Table>
        </>
      )}
    </>
  )
}

export default PouchesListTable
