import React, { useState } from "react";
import * as establishmentApi from "../../api/Etablishment";
import AuthWrapper from "../../components/AuthWrapper";

const LogoUpload = (props) => {
  const [selectedFile, setSelectedFile] = useState();
  const [isSelected, setIsSelected] = useState(false);
  const [message, setMessage] = useState("");

  const changeHandler = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size > 1024000) alert("File exeed limit : 1MB");
      else {
        setSelectedFile(event.target.files[0]);
        setIsSelected(true);
      }
    }
  };

  const reset = () => {
    setSelectedFile(null);
    setIsSelected(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!selectedFile) return;

    const formData = new FormData();
    formData.append("logo", selectedFile);

    establishmentApi
      .changeLogo(formData, props.establishmentId)
      .then((res) => res.json())
      .then((json) => {
        setMessage(`Logo added`);
        reset(); // reset the fields // TODO make them work with the formData reset
      })
      .catch((err) => console.error("error:" + err));
  };

  return (
    <AuthWrapper>
      <div>
        <p style={{ paddingLeft: "30px" }}>
          <i>{message}</i>
        </p>
        <form className="form" onSubmit={handleSubmit}>
          <div>
            <p>File</p>
            <input type="file" name="file" onChange={changeHandler} />
            {isSelected ? (
              <div>
                <p>
                  Filename: {selectedFile?.name}, Size : {selectedFile?.size}{" "}
                  bytes
                </p>
              </div>
            ) : (
              <p>Select a file to show details</p>
            )}
          </div>
          <div>
            <button type="submit" className="btnMain">
              Ajouter
            </button>
          </div>
        </form>
      </div>
    </AuthWrapper>
  );
};

export default LogoUpload;
