const EmailComponent = (props) => {
  const { value, email, className, name, isValid, label, placeholder } = props;

  //This function is used to check if email is valid or not
  const verifyEmail = (e) => {
    value(e.target.value);
    if (e.target.value.trim() === "") {
      isValid(false);
    } else if (
      // eslint-disable-next-line
      !/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        e.target.value
      )
    ) {
      isValid(false);
    } else {
      isValid(true);
    }
  };

  return (
    <div>
      {label && <p>{label}</p>}
      <input
        type="email"
        name={name}
        className={className}
        placeholder={placeholder}
        value={email}
        onChange={verifyEmail}
      />
    </div>
  );
};

export default EmailComponent;
