import React from "react";
import Select from "react-select";
import * as establishmentApi from "../../api/Etablishment";
import * as XLSX from "xlsx";
import { buildDataList } from "../../utils/ExcelUtils";
import { Button, Input } from "@material-ui/core";
import LayoutPageComponent from "../../components/LayoutPageComponent";

class UploadPatientList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isFetching: false,
      selectedFile: null,
      selectedEstablishment: null,
      selectedTestType: null,
      establishmentsData: [],
      extractedData: [],
      msg: "",
    };
  }

  componentDidMount() {
    this.getEstablishment().catch((e) => {
      console.log("error =====>", e);
    });
  }

  getEstablishment = async () => {
    const establishmentList = await establishmentApi.getEtablishments();

    if (establishmentList.error) {
      this.setState({ isFetching: false, msg: "Aucun établissement trouvé." });
    } else {
      this.setState({ isFetching: true, msg: "Patientez svp..." });
      var establishmentArray = [];
      var isAdmincheck = localStorage.getItem("isAdmin");
      if (isAdmincheck.toString() === "false") {
        var establishmentNameValue = localStorage.getItem("establishmentName");
        var establishmentIdValue = localStorage.getItem("establishment");
        var objEstablishmentStatic = {
          value: establishmentIdValue,
          label: establishmentNameValue,
        };
        establishmentArray.push(objEstablishmentStatic);
        this.setState({
          establishmentsData: establishmentArray,
          isFetching: false,
          msg: "",
        });
      } else {
        const responseEstablishment = await establishmentApi.getEtablishments();

        if (responseEstablishment.error) {
          this.setState({ isFetching: false, msg: "Aucun patient trouvé." });
        } else {
          // eslint-disable-next-line
          responseEstablishment.data.map((responseObj, i) => {
            var objEstablishment = {
              value: responseObj.id,
              label: responseObj.name,
            };
            establishmentArray.push(objEstablishment);
          });

          this.setState({
            establishmentsData: establishmentArray,
            isFetching: false,
            msg: "",
          });
        }
      }

      this.setState({
        establishmentsData: establishmentArray,
        isFetching: false,
      });
    }
  };

  handleChangeFile = (event) => {
    let that = this;
    const file = event.target.files[0];

    var reader = new FileReader();
    reader.onload = function (e) {
      var data = e.target.result;
      let readedData = XLSX.read(data, { type: "binary" });
      const sheetName = readedData.SheetNames[0];
      const sheet = readedData.Sheets[sheetName];
      // Update state
      try {
        const payloadData = buildDataList(sheet);

        that.setState({ extractedData: payloadData });
      } catch (e) {
        alert("File not supported!");
        console.log("error", JSON.stringify(e));
      }
    };
    reader.readAsBinaryString(file);

    this.setState({ selectedFile: file });
  };

  handleChangeEstablishment = (selectedEstablishment) => {
    this.setState({ selectedEstablishment });
  };

  handleChangeTestType = (selectedTestType) => {
    this.setState({ selectedTestType });
  };

  upload = async (data) => {
    const { selectedEstablishment, selectedTestType, extractedData } = data;
    if (selectedEstablishment === "") {
      this.setState({ msg: "Please select an establishment" });
      return;
    }

    if (selectedTestType === "") {
      this.setState({ msg: "Please select a test type" });
      return;
    }

    await establishmentApi.importXslxData(
      selectedEstablishment.value,
      selectedTestType.value,
      extractedData
    );
    this.setState({ msg: "Upload successful" });
  };

  render() {
    const { establishmentsData, selectedEstablishment, selectedTestType, msg } =
      this.state;
    const testTypesData = [
      { value: "1", label: "COVID-19 Antigenic Test" },
      { value: "2", label: "COVID-19 PCR Test" },
      { value: "3", label: "COVID-19 PCR Premium" },
    ];
    return (
      <LayoutPageComponent>
        <p className="title">Outil d'import</p>
        <div className="filterControl">
          <p> Sélectionnez le type de test : </p>
          <Select
            value={selectedTestType}
            onChange={this.handleChangeTestType}
            options={testTypesData}
          />

          <p> Sélectionnez l'établissement : </p>
          <Select
            value={selectedEstablishment}
            onChange={this.handleChangeEstablishment}
            options={establishmentsData}
          />

          <div>
            <p>Choisissez un fichier (.xslx)</p>
            <Input
              id="outlined-basic"
              type={"file"}
              onChange={this.handleChangeFile}
              accept={".xlsx, .xls"}
              variant="outlined"
            />
          </div>
          <br />
          <div>
            <Button
              variant="contained"
              color="primary"
              onClick={() => this.upload(this.state)}
            >
              Mettre à jour
            </Button>
          </div>
          <p>{msg}</p>
        </div>
      </LayoutPageComponent>
    );
  }
}

export default UploadPatientList;
